import faker from 'faker';
import moment from 'moment';
import staticData from './staticData';

let fakes = {
  name() {
    return `${faker.name.firstName()} ${faker.name.lastName()}`;
  },

  email(first, last) {
    return `${first}.${last}@optify.nu`;
  },

  agreement() {
    let nr = Math.floor(
      Math.random() * Object.keys(staticData.agreements).length,
    );
    return staticData.agreements[nr];
  },

  agreementUsers() {
    let nr = Math.floor(
      Math.random() * Object.keys(staticData.agreementUsers).length,
    );

    return staticData.agreementUsers[nr];
  },

  agreementPeriods() {
    let nr = Math.floor(
      Math.random() * Object.keys(staticData.agreementPeriods).length,
    );

    return staticData.agreementPeriods[nr];
  },

  type() {
    let nr = Math.floor(Math.random() * Object.keys(staticData.types).length);

    return staticData.types[nr].name;
  },

  subscription() {
    let nr = Math.floor(
      Math.random() * Object.keys(staticData.subscriptions).length,
    );

    return staticData.subscriptions[nr].name;
  },

  discount() {
    let nr = Math.floor(
      Math.random() * Object.keys(staticData.discounts).length,
    );

    return staticData.discounts[nr].name;
  },

  operator() {
    let nr = Math.floor(
      Math.random() * Object.keys(staticData.operators).length,
    );

    return staticData.operators[nr].name;
  },

  contractPeriod() {
    let nr = Math.floor(
      Math.random() * Object.keys(staticData.contractPeriods).length,
    );

    return staticData.contractPeriods[nr].name;
  },

  surcharge() {
    let surcharges = staticData.surcharges();

    let nr = Math.floor(Math.random() * Object.keys(surcharges).length);

    return surcharges[nr].name;
  },

  date() {
    let nr = Math.floor(Math.random() * 60);

    return moment()
      .add(nr, 'days')
      .format('YYYY-MM-DD');
  },

  products() {
    let nr = Math.floor(Math.random() * 5) + 1;
    let arr = [];

    for (let i = 0; i < nr; i++) {
      arr.push({
        name: this.product(),
        price: this.price(),
        articleNr: this.articleNumber(),
      });
    }
    return arr;
  },

  product() {
    return faker.commerce.product();
  },

  price() {
    return Math.floor(Math.random() * 7000) + 100;
  },

  kick() {
    return Math.floor(Math.random() * 500) + 1;
  },

  articleNumber() {
    return this.generateUID();
  },

  generateUID() {
    // I generate the UID from two parts here
    // to ensure the random number provide enough bits.
    var firstPart = (Math.random() * 46656) | 0;
    var secondPart = (Math.random() * 46656) | 0;
    firstPart = ('000' + firstPart.toString(36)).slice(-3);
    secondPart = ('000' + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  },

  transfer() {
    var transfer = Math.random() < 0.5;
    if (transfer) {
      return { name: this.name(), orgNumber: '5544556677' };
    }
    return { name: "", orgNumber: "" };
  },

  lorem() {
    return faker.lorem.sentence();
  },

  tb() {
    return Math.floor(Math.random() * 18000) + 1000;
  },

  phoneNumber() {
    // 07? ?? ?? ??
    let phone = '07';
    for (let i = 0; i < 8; i++) {
      let nr = Math.floor(Math.random() * 9) + 1;
      phone = phone += nr;
    }
    return phone;
  },

  sale() {
    let type = this.type();
    let sale = {
      status: this.status(),
      type: type,
      subscription: this.subscription(),
      phoneNumber: this.phoneNumber(),
      contractPeriod: this.contractPeriod(),
      surcharge: this.surcharge(),
      operator: type.includes('Portering') ? this.operator() : '',
      discount: this.discount(),
      kick: this.kick(),
      time: this.date(),
      transfer: this.transfer(),
      products: this.products(),
    };
    return sale;
  },

  status() {
    return staticData.statuses[
      Math.floor(Math.random() * staticData.statuses.length)
    ];
  },

  comments(sellers, orderId) {
    let comments = [];
    let random = Math.floor(Math.random() * 10) + 1;
    for (let i = 0; i < random; i++) {
      let nrUser = Math.floor(Math.random() * sellers.length);
      let user = sellers[nrUser];
      delete user.active;
      delete user.email;
      delete user.role;
      delete user.office;
      delete user.created_by;

      var now = moment();
      let nr = Math.floor(Math.random() * 100) + 1;
      now.subtract(nr, 'days');
      comments.push({
        comment: { text: this.lorem() },
        orderId: orderId,
        user: user,
        created: now,
      });
    }

    return comments;
  },

  orgNr() {
    let nr = '';

    for (let i = 0; i < 6; i++) {
      const rndInt = Math.floor(Math.random() * 10) + 1;
      nr += rndInt.toString();
    }
    nr += '-';
    for (let j = 0; j < 4; j++) {
      const rndInt = Math.floor(Math.random() * 10) + 1;
      nr += rndInt.toString();
    }
    return nr;
  },

  customer() {
    let first = faker.name.firstName();
    let last = faker.name.lastName();

    return {
      companyName: faker.company.companyName(),
      orgNr: this.orgNr(),
      zip: faker.address.zipCode(),
      street: faker.address.streetName(),
      city: faker.address.city(),
      signatory: {
        name: `${first} ${last}`,
        email: this.email(first, last),
        personalNumber: this.orgNr(),
        phoneNumber: this.phoneNumber(),
      },
    };
  },

  order() {
    let user = JSON.parse(localStorage.getItem("user"));

    let order = {
      external: '',
      invoices: {
        invoice: { amount: 0, paid: false },
        finalInvoice: { amount: 0, paid: false },
      },
      info: '',
      tb: '',
      agreement: {
        type: "",
        period: "",
        users: "",
      },
      sales: [],
      customer: this.customer(),
      seller: user.uid,
      comments: [],
      status: this.status(),
    };

    let agreement = this.agreement();
    order.agreement.type = agreement.name;

    if (agreement.name !== 'Ingen förändring') {
      order.agreement.period = this.agreementPeriods().name;
      order.agreement.users = this.agreementUsers().name;
    }

    order.invoices.finalInvoice.amount = Math.floor(Math.random() * 2000);
    order.invoices.invoice.amount = Math.floor(Math.random() * 2000);
    order.info = this.lorem();
    order.tb = this.tb();

    let nr = Math.floor(Math.random() * 50) + 1;

    for (let i = 0; i < nr; i++) {
      let sale = this.sale();
      sale.id = i + 1;
      order.sales.push(JSON.parse(JSON.stringify(sale)));
    }
    return order;
  },
};

export default fakes;
