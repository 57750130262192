import { storage, db, firebase, functions } from '../../firebase/init.js';
import moment from 'moment';
import staticData from '@/clasess/staticData';
//import sizeof from 'firestore-size';

const storageRef = storage.ref();

async function orderNumber() {
  let date = moment().format('YYYYMMDD');
  var starCountRef = firebase.database().ref('orders');

  let value = await starCountRef
    .once('value')
    .then((snapshot) => {
      return snapshot.val();
    })
    .then((val) => {
      return val;
    });

  return `${date}-${value}`;
}

const state = {
  files: [],
  folders: [],
  percent: 0,
  tree: [],
  uploaded: false,
  loaded: {
    files: false,
    folders: false,
  },
  progress: {},
};

// const dataToFetch = [
//   'types',
//   'subscriptions',
//   'surcharges',
//   'discounts',
//   //'packages',
//   'operators',
//   'agreements',
//   'contractPeriods',
//   'agreementUsers',
//   'agreementPeriods',
//   'offices',
//   //'users',
//   'roles',
// ];

const getters = {
  percent: (state) => {
    return state.percent;
  },
};

const mutations = {
  setPercent(state, progress) {
    state.percent = progress;
  },
};

const actions = {
  async insertStaticData({}) {
    Object.keys(staticData).forEach((key) => {
      staticData[key].forEach(async (element, i) => {
        element.order = i;
        await db
          .collection(key)
          .doc()
          .set(element);
      });
    });

    for (let i = 10; i < 500; i += 10) {
      await db
        .collection('surcharges')
        .doc()
        .set({
          name: `${i} kr`,
          value: i,
          active: true,
        });
    }
  },

  getLlinkedData({}, agreement) {
    return db
      .collection('linked')
      .where('name', '==', agreement.name)
      .get();
  },

  insertNoID({}, data) {
    return db
      .collection(data.collection)
      .doc()
      .set(data.document);
  },

  saveData({}, data) {
    if (Array.isArray(data.record)) {
      let batch = db.batch();
      data.record.forEach((arr) => {
        var docRef = db.collection(data.collection).doc(arr.id);
        batch.set(docRef, { records: arr });
      });
      return batch.commit();
    } else {
      return db
        .collection(data.collection)
        .doc(data.id ? data.id : '')
        .set(data.record);
    }
  },

  updateOrder({}, order) {
    return db
      .collection('orders')
      .doc(order.id)
      .set(order);
  },

  async saveComment({}, data) {
    console.log(data);
    let user = JSON.parse(localStorage.getItem('user'));
    data.comment.user = { name: user.name, img: user.img, uid: user.uid };
    data.comments.push(data.comment);
    await db
      .collection('comments')
      .doc(data.orderId)
      .update({ comments: data.comments });

    return data.comments;
    // return db
    //   .collection('comments')
    //   .doc(data.orderId)
    //   .get();
  },

  async saveOrder({}, order) {
    const db = firebase.firestore();
    const ref = db.collection('orders').doc();
    const id = ref.id;

    order.id = id;
    order.customer.orgNr = order.customer.orgNr.replace('-', '');

    // delete sales and comments to put in separate collections to
    // make documents smaller that can otherwise be too large
    // -------------------------------->
    //let sales = order.sales;
    let comments = order.comments;

    //delete order.sales;
    delete order.comments;
    // --------------------------------<

    let user = JSON.parse(localStorage.getItem('user'));

    order.seller = { uid: user.uid, name: user.name, office: user.office };

    let ordersRef = db.collection('orders').doc(order.id); //automatically generate unique id
    //let salesRef = db.collection('sales').doc(order.id); //automatically generate unique id
    let commentsRef = db.collection('comments').doc(order.id); //automatically generate unique id
    let customersRef = db.collection('customers').doc(order.customer.orgNr); //automatically generate unique id

    let batch = db.batch();

    batch.set(ordersRef, order);
    //batch.set(salesRef, { sales: sales });
    batch.set(commentsRef, { comments: comments });
    batch.set(customersRef, order.customer);

    await batch.commit();

    return order.id;
  },

  getData({}, data) {
    return db
      .collection(data.collection)
      .doc(data.id)
      .get();
  },

  async addAdminRole({}, email) {
    const addAdmin = functions.httpsCallable('addAdminRole');

    addAdmin({ email: email }).then((res) => {
      console.log(res);
    });
  },

  changePasswordForCurrentUser() {
    const changePassword = functions.httpsCallable('changePassword');

    changePassword().then((res) => {
      console.log(res);
    });
  },

  getAllUsers({}) {
    return db.collection('users').get();
  },

  createUser({}, user) {
    return db
      .collection('users')
      .doc(user.uid)
      .set(user);
  },

  updateUser({}, user) {
    db.collection('users')
      .doc(user.uid)
      .update(user);
  },

  async updateProfilePictureOnComments({}) {
    let user = JSON.parse(localStorage.getItem('user'));
    const citiesRef = db.collection('comments');
    const snapshot = await citiesRef
      .where('comments', 'array-contains', { user: { uid: user.uid } })
      .get();

    if (snapshot.empty) {
      console.log('No matching documents.');
      return;
    }

    snapshot.forEach((doc) => {
      console.log(doc.id, '=>', doc.data());
    });
  },

  updatePercent({ commit }, file) {
    commit('setPercent', file);
  },

  getNewsImages({}) {
    return db
      .collection('files')
      .where('office', '==', 'Kalmar')
      .get();
  },

  getJson({}, name) {
    return db
      .collection('json')
      .where('name', '==', name)
      .get();
  },

  getOrders({}) {
    return db
      .collection('orders')
      .limit(25)
      .get();
  },

  getSales({}, orderId) {
    return db
      .collection('sales')
      .doc(orderId)
      .get();
  },

  getComments({}, orderId) {
    return db
      .collection('comments')
      .doc(orderId)
      .get();
  },

  findCustomer({}, orgNr) {
    return db
      .collection('customers')
      .doc(orgNr)
      .get();
  },

  saveJson({}, data) {
    db.collection('json')
      .doc(data.name)
      .set({
        name: data.name,
        json: JSON.stringify(data.json),
      });
  },

  insertOrder({}) {
    let histo = [];
    //let batch = db.batch();
    //let now = moment().format('YYYYMMDD');
    //('20210308-1');
    console.time();
    for (let i = 0; i < 20000; i++) {
      let id = orderNumber();

      if (histo.includes(id)) {
        const found = histo.find((element) => element === id);
        console.log('Dublett: ', id, found);
      } else histo.push(id);
      // firebase
      //   .database()
      //   .ref('orders')
      //   .set(i + 1);

      // //let orderId = `${now}-${i + 1}`;
      // let orderId = uuidv4();
      // data.order.id = orderId;

      // let ordersRef = db.collection('orders').doc(orderId); //automatically generate unique id
      // let salesRef = db.collection('sales').doc(orderId); //automatically generate unique id
      // let commentsRef = db.collection('comments').doc(orderId); //automatically generate unique id
      // let customersRef = db.collection('customers').doc('5555555555'); //automatically generate unique id
      // batch.set(ordersRef, data.order);
      // batch.set(salesRef, data.sales);
      // batch.set(commentsRef, data.comments);
      // batch.set(customersRef, data.customer);
    }
    console.log('Done!', histo[0]);
    console.timeEnd();
    //return batch.commit();

    // db.collection('orders')
    //   .doc()
    //   .set(order);
  },

  getAll({ state }, {}) {
    return db
      .collection('files')
      .where('office', '==', 'Kalmar')
      .get()
      .then((querySnapshot) => {
        state.files = [];
        querySnapshot.forEach((doc) => {
          state.files.push(doc.data());
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
        });
      })
      .catch(function(error) {
        console.log('Error getting documents: ', error);
      });
  },

  uploadProfilePicture({ state }, data) {
    state.uploaded = false;
    let rootPath = 'uploads/profile/';

    rootPath += data.name;
    let listRef = storageRef.child(rootPath);
    var uploadTask = listRef.put(data.file);

    uploadTask.on(
      'state_changed',
      function(snapshot) {
        //var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //state.percent = progress;
        // console.log(snapshot);
        // commit('setPercent', progress);
        //state.progress[data.name].percent = progress;
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
        }
      },
      function(error) {
        console.log(error);
        // Handle unsuccessful uploads
      },
      function() {
        return uploadTask.snapshot.ref
          .getDownloadURL()
          .then(function(downloadURL) {
            state.uploaded = true;
            return db
              .collection('files')
              .doc(data.name)
              .set({
                name: data.name,
                type: data.type,
                url: downloadURL,
                uploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
                size: data.size,
              });
          });
      },
    );
  },

  deleteFile({}, file) {
    let listRef = storageRef.child(file.path);
    return listRef.delete().then(() => {
      return db
        .collection('files')
        .doc(file.name)
        .delete();
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
