<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <dss-link-data :agreement="currentAgreement"></dss-link-data>
    </v-dialog>
    <v-row>
      <v-col xs="12" md="2">
        <v-navigation-drawer
          absolute
          color="back"
          width="15%"
          permanent
          floating
          height="70vh"
        >
          <!-- <v-list color="transparent">
              <v-list-item>
                <v-list-item-title></v-list-item-title>
              </v-list-item>
              <v-list-item-group v-model="tab" color="primary" mandatory>
                <v-list-item
                  v-for="(item, i) in tabs"
                  :key="i"
                  @click="setCurrentMode(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list> -->

          <v-list color="transparent" shaped>
            <v-list-item>
              <v-list-item-title></v-list-item-title>
            </v-list-item>
            <v-list-item-group color="primary" mandatory>
              <div v-for="(item, i) in tabs" :key="i">
                <v-list-item @click="setCurrentMode(item, 'root')">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col xs="12" md="10">
        <v-card>
          <v-toolbar color="transparent" flat>
            <v-btn @click="addNew = !addNew" v-if="!addNew" color="primary"
              ><v-icon left>mdi-plus</v-icon>Lägg till ny</v-btn
            >
            <v-btn icon v-else @click="addNew = !addNew"
              ><v-icon>mdi-cancel</v-icon></v-btn
            >
            <transition name="fade" mode="out-in">
              <v-text-field
                v-if="addNew"
                hide-details
                single-line
                v-model="newItem"
                autofocus
              ></v-text-field>
            </transition>
            <v-btn icon v-if="addNew" @click="addNewItem">
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
            <v-divider
              v-if="!addNew && tab.id === 5 && activeArchived"
              vertical
              inset
              class="mr-4"
            ></v-divider>
            <v-select
              v-if="tab.id === 5 && activeArchived !== 'archived'"
              v-model="selectedTypes"
              :items="types"
              item-text="name"
              item-value="name"
              chips
              label="Synlighet"
              multiple
              hide-details
              single-line
              dense
              class="mt-3"
            >
              <template v-slot:append-outer>
                <v-btn icon @click="saveWhitelist"
                  ><v-icon>mdi-content-save-outline</v-icon></v-btn
                >
              </template>
            </v-select>
            <!-- <v-divider v-if="!addNew" vertical inset class="mr-4"></v-divider>
            <v-text-field
              v-if="!addNew"
              hide-details
              prepend-icon="mdi-magnify"
              single-line
            ></v-text-field> -->
            <v-spacer></v-spacer>
            <v-col xs="12" md="2">
              <v-select
                v-model="activeFilter"
                :items="filterData"
                dense
                single-line
                hide-details
                outlined
              ></v-select>
            </v-col>
            <!-- <v-toolbar-items>
            <v-switch inset hide-details></v-switch>
            <v-btn-toggle v-model="activeArchived" tile color="primary" group>
              <v-btn value="active" @click="resetSelected">
                Aktiva
              </v-btn>
              <v-btn value="archived" @click="resetSelected">
                Inaktiva
              </v-btn>
            </v-btn-toggle>
          </v-toolbar-items> -->
          </v-toolbar>
          <v-data-table
            v-model="selected"
            :headers="tableHeaders"
            :items="tableRecords"
            :items-per-page="10"
            show-select
            item-key="id"
          >
            <template v-slot:top>
              <v-toolbar color="transparent" flat>
                <v-col cols="4">
                  <v-text-field
                    hide-details
                    prepend-icon="mdi-magnify"
                    single-line
                    clearable
                    v-model="search"
                  ></v-text-field>
                </v-col>
                <!-- <v-btn color="primary" @click="archiveItems"
                  ><v-icon left small>mdi-package-down</v-icon>Arkivera</v-btn
                > -->
                <!-- <v-select
                  v-if="tab.id === 5 && activeArchived != 'archived'"
                  v-model="selectedTypes"
                  :items="types"
                  item-text="name"
                  item-value="name"
                  chips
                  label="Synlighet"
                  multiple
                  hide-details
                  single-line
                  dense
                >
                  <template v-slot:append-outer>
                    <v-btn icon @click="saveWhitelist"
                      ><v-icon>mdi-content-save-outline</v-icon></v-btn
                    >
                  </template>
                </v-select> -->
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  v-if="selected.length && activeFilter === 'Aktiva'"
                  @click="updateItems"
                  >Arkivera markerade</v-btn
                >
                <v-btn
                  color="success"
                  v-else-if="selected.length && activeFilter === 'Inaktiva'"
                  @click="updateItems"
                  >Aktivera markerade</v-btn
                >
              </v-toolbar>
            </template>
            <template v-slot:item.name="{ item }">
              <v-edit-dialog
                :return-value.sync="item.name"
                @save="updateItem(item)"
              >
                {{ item.name }}
                <template v-slot:input>
                  <v-text-field
                    v-model="item.name"
                    label="Redigera"
                    single-line
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <!-- <template v-slot:item.created_at="props">
              {{ formatDate(props.item.created_at, 'YYYY-MM-DD hh:mm:ss') }}
            </template>
            <template
              v-slot:item.deleted_at="props"
              v-if="activeArchived === 'archived'"
            >
              {{ formatDate(props.item.deleted_at, 'YYYY-MM-DD HH:mm:ss') }}
            </template> -->
            <template #[`item.active`]="{item}">
              <!-- <v-switch
                inset
                v-model="item.active"
                small
                @change="toggleActive(item)"
              ></v-switch> -->
              <v-chip small :color="item.active ? 'success' : 'error'">{{
                item.active ? "Aktiv" : "Inaktiv"
              }}</v-chip>
            </template>

            <!-- <template v-slot:item.linc="{ item }" v-if="tab.id === 0">
              <v-btn text icon @click="setCurrentAgreement(item)"
                ><v-icon>mdi-link-variant</v-icon></v-btn
              >
            </template> -->
            <!-- <template v-slot:item.archive="{ item }">
              <v-checkbox @click="addItemToArchive(item)"></v-checkbox>
            </template> -->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import { db } from "../firebase/init";
//import moment from 'moment';
//import api from '../clasess/api';

import LinkData from "@/components/Linkdata/Agreements";
//import StaticAgreements from '@/components/staticData/Agreements';
// import Subscriptions from "@/components/items/Subscriptions"
// import Discounts from "@/components/items/Discounts"
// import Surcharges from "@/components/items/Surcharges"
// import Operators from "@/components/items/Operators"

export default {
  components: {
    "dss-link-data": LinkData
    //'dss-agreement-data': StaticAgreements,
    // "dss-subscriptions": Subscriptions,
    // "dss-discounts": Discounts,
    // "dss-surcharges": Surcharges,
    // "dss-operators": Operators,
  },

  watch: {
    editItem() {
      this.show = true;
      if (this.editItem.edit) {
        this.editing = true;
      }
    },

    activeArchived() {
      this.setCurrentMode();
    }
  },

  computed: {
    ...mapGetters("app", [
      "dark",
      "editItem",
      "subscriptions",
      "surcharges",
      "discounts",
      "operators",
      "agreements",
      "agreementUsers",
      "agreementPeriods",
      "types",
      "contractPeriods"
    ]),

    tableHeaders() {
      return this.headers.filter(item => {
        if (item.value === "linc" && this.tab.id !== 0) return false;

        if (item.value === "deleted_at" && this.activeArchived === "active") {
          return false;
        }

        return true;
      });
    },

    haveArchived() {
      return this.archive.length;
      // let found = false;
      // this.archive.forEach((element) => {
      //   if (element) found = true;
      // });

      // return found;
    },

    tableRecords() {
      let records = this[this.tab.category];

      if (this.search !== "" && this.search !== null)
        records = records.filter(record =>
          record.name.toLowerCase().includes(this.search.toLowerCase())
        );

      if (this.activeFilter === "Aktiva")
        records = records.filter(record => record.active);
      if (this.activeFilter === "Inaktiva")
        records = records.filter(record => !record.active);

      // if (this.role !== "") {
      //   records = records.filter(
      //     record => record.role.toLowerCase() === this.role.toLowerCase()
      //   );
      // }

      return records;

      // let arr;
      // if (this.search !== null && this.search !== "") {
      //   if (this.activeFilter === "Aktiva") {
      //     arr = this[this.tab.category].filter(item => item.active);
      //   } else arr = this[this.tab.category].filter(item => !item.active);

      //   if (this.search !== "") {
      //     return arr.filter(item =>
      //       item.name.toLowerCase().includes(this.search.toLowerCase())
      //     );
      //   }

      //   return arr;
      // } else {
      //   return this[this.tab.category];
      // }
    }
  },

  data() {
    return {
      activeFilter: "Aktiva",
      filterData: ["Aktiva", "Inaktiva"],
      itemsToArchive: [],
      addNew: false,
      newItem: null,
      whitelist_operators: [],
      nav: false,
      jsonData: {},
      add: {
        type: false
      },
      discount: null,
      sub: null,
      type: null,
      agreement: null,
      surcharge: null,
      search: "",
      selected: [],
      selectedSurcharges: [],
      selectedTypes: [],
      selectedSubs: [],
      selectedDiscounts: [],
      tabItem: 0,
      currentMode: "active",
      dialog: false,
      dialog2: false,
      expanded: [],
      editing: false,
      category: "types",
      loading: false,
      show: false,
      tab: {
        name: "Avtal",
        id: 0,
        text: "nytt avtal",
        category: "agreements"
      },
      text: "ny typ",
      item: 1,
      archive: [],
      activeArchived: "active",
      active: true,
      currentAgreement: "",
      headers: [
        // { text: 'Arkivera', value: 'archive' },
        { text: "Namn", value: "name" },
        // { text: 'Inlagd', value: 'created_at' },
        // { text: 'Arkiverad', value: 'deleted_at' },
        { text: "Aktiv", value: "active" }
        //{ text: 'Länka', value: 'linc' },
      ],
      tableData: [],
      tabs: [
        {
          name: "Avtal",
          id: 0,
          text: "nytt avtal",
          category: "agreements"
        },
        {
          name: "Avtalstider",
          id: 0.1,
          text: "ny avtalstid",
          category: "agreementPeriods"
        },
        {
          name: "Användare",
          id: 0.2,
          text: "ny avtalsanvändare",
          category: "agreementUsers"
        },
        { name: "Typer", id: 1, text: "ny typ", category: "types" },
        {
          name: "Abonnemangsformer",
          id: 2,
          text: "ny abonnemangsform",
          category: "subscriptions"
        },
        { name: "Mandat", id: 3, text: "nytt mandat", category: "discounts" },
        {
          name: "Pristillägg",
          id: 4,
          text: "nytt pristillägg",
          category: "surcharges"
        },
        {
          name: "Operatörer",
          id: 5,
          text: "ny operatör",
          category: "operators"
        },
        {
          name: "Bindningstider",
          id: 6,
          text: "ny bindningstid",
          category: "contractPeriods"
        }
      ]
    };
  },

  methods: {
    resetSelected() {
      this.selected = [];
    },

    showSuccessMessage() {
      store.dispatch("user/addMessage", {
        body: "Uppdatering lyckades",
        type: "success",
        messageType: "message"
      });
    },

    async updateItems() {
      // activeArchived
      let batch = db.batch();
      let value = this.activeFilter === "Aktiva" ? false : true;

      this.selected.forEach(item => {
        let ref = db.collection(this.tab.category).doc(item.id);
        batch.update(ref, { active: value });
        item.active = value;

        // db.collection(this.tab.category)
        //   .doc(item.id)
        //   .update({ active: value })
        //   .then(() => {
        //     this.selected = [];

        //   });
      });
      let category = "";
      this.tabs.forEach(tab => {
        if (tab.category === this.tab.category) category = tab.name;
      });
      await batch.commit();
      this.selected = [];
      this.activeArchived = "active";
      store.dispatch("user/addMessage", {
        body: `Alla markerade ${category.toLowerCase()} är nu uppdaterade!`,
        type: "success",
        messageType: "message"
      });
    },

    archiveItems() {
      this.selected.forEach(item => {
        item.active = false;
        db.collection(this.tab.category)
          .doc(item.id)
          .update({ active: false })
          .then(() => {
            this.selected = [];
            store.dispatch("user/addMessage", {
              body: `${item.name} - arkiverad`,
              type: "success",
              messageType: "message"
            });
          });
      });
    },

    activateItems() {
      this.selected.forEach(item => {
        item.active = true;
        db.collection(this.tab.category)
          .doc(item.id)
          .update({ active: true })
          .then(() => {
            this.selected = [];
            store.dispatch("user/addMessage", {
              body: `${item.name} - aktiverad`,
              type: "success",
              messageType: "message"
            });
          });
      });
    },

    addItemToArchive(item) {
      this.archive.push(item.name);
      this.itemsToArchive.push(item);
    },

    toggleActive(item) {
      this.updateItem(item, { active: item.active });
    },

    updateItem(item, data) {
      db.collection(this.tab.category)
        .doc(item.id)
        .update(data)
        .then(() => {
          let activeText = item.active ? "aktiverad" : "avaktiverad";
          store.dispatch("user/addMessage", {
            body: `${item.name} - ${activeText}`,
            type: "success",
            messageType: "message"
          });
        });
    },

    correctCategory(cat) {
      for (let i = 0; i < this.tab.category.length; i++) {
        if (
          this.tab.category.charAt(i) ===
          this.tab.category.charAt(i).toUpperCase()
        ) {
          cat = cat.slice(0, i) + "_" + cat.slice(i).toLowerCase();
        }
      }

      return cat;
    },

    addNewItem() {
      let newItem = {
        name: this.newItem,
        active: true,
        id: this.uuidv4()
      };

      db.collection(this.tab.category)
        .doc(newItem.id)
        .set(newItem)
        .then(() => {
          this[this.tab.category].push(newItem);

          store.dispatch("user/addMessage", {
            body: `Lagt till ${this.tab.text}, ${this.newItem}`,
            type: "success",
            messageType: "message"
          });
          this.addNew = false;
          this.newItem = null;
        });
      // api.put(cat, { item: this.newItem }).then((items) => {
      //   this.tableData = items.data.res;
      //   this.addNew = false;

      //   store.dispatch('user/addMessage', {
      //     body: `Lagt till ${this.tab.text}, ${this.newItem}`,
      //     type: 'success',
      //     messageType: 'message',
      //   });
      //   store.commit('app/setCategory', {
      //     category: cat,
      //     items: items.data.res,
      //   });
      //   this.newItem = null;
      // });
    },

    linc() {
      this.dialog = true;
    },

    toggle() {
      this.$nextTick(() => {
        if (this.selectedSurcharges.length === this.surcharges.length) {
          this.selectedSurcharges = [];
        } else {
          this.selectedSurcharges = this.surcharges.slice();
        }
      });
    },

    setCurrentMode(item = null) {
      if (item !== null) {
        this.tab = item;
      } else {
        item = this.tab;
      }

      if (this.activeArchived === "active") {
        this.tableData = this[item.category];
        this.archive = [];
      }
      // else {
      //   this.loadArchivedRecords(item.category);
      // }
    },

    // loadArchivedRecords(category) {
    //   let cat = this.correctCategory(category);
    //   // api.get(cat + '/archived').then((result) => {
    //   //   this.tableData = result;
    //   // });
    // },

    saveWhitelist() {
      store
        .dispatch("firestore/saveData", {
          collection: "whitelist",
          id: "whitelist_operators",
          record: { records: this.selectedTypes }
        })
        .then(() => {
          this.addMessage("Typerna sparadde", "success");
        });
      // api
      //   .put('json', {
      //     name: 'whitelist_operators',
      //     data: this.selectedTypes,
      //   })
      //   .then(() => {
      //     store.dispatch('user/addMessage', {
      //       body: 'Ändringarna är nu uppdaterade!',
      //       type: 'success',
      //       messageType: 'message',
      //     });
      //   });
    },

    setCurrentAgreement(agreement) {
      this.currentAgreement = agreement;
      store.dispatch("app/updateAgreement", agreement);
      this.dialog = true;
    },

    setTab(tab) {
      this.tab = "tab-" + tab.id;
      this.text = tab.text;
      this.category = tab.category;
    }
  },

  mounted() {
    store.dispatch("app/refreshStaticData");
    this.tableData = this.agreements;

    db.collection("whitelist")
      .doc("whitelist_operators")
      .get()
      .then(doc => {
        this.selectedTypes = doc.data().records;
      });
  }
};
</script>

<style scoped>
.activeLink {
  border-right: 2px solid #173d52;
}
</style>
