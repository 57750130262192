import { db } from '../../firebase/init';

const state = {
  messages: [],
  user: {},
  offices: [],
  users: [],
  subscriptions: {
    users: { unsubscribe: null },
    offices: { unsubscribe: null },
  },
};

const getters = {
  messages: (state) => {
    return state.messages;
  },

  user: () => {
    return state.user;
    //return JSON.parse(localStorage.getItem('user'));
  },

  offices: (state) => {
    return state.offices;
  },

  users: (state) => {
    return state.users;
  },
};

const mutations = {
  setMessages(state, messages) {
    state.messages = messages;
  },

  setOneMessage(state, message) {
    let newMessage = Object.assign({}, message);
    newMessage.id = state.messages.length;
    state.messages.push(newMessage);
  },

  setUser(state, user) {
    state.user = user;
  },

  deleteMessage(state, message) {
    const index = state.messages.indexOf(message);
    state.messages.splice(index, 1);
  },
};

const actions = {
  updateMessages({ commit }, messages) {
    commit('setMessages', messages);
  },

  clearUser({ state }) {
    state.user = undefined;
  },

  updateUser({ commit }, user) {
    commit('setUser', user);
  },

  addMessage({ commit }, message) {
    commit('setOneMessage', message);
  },

  removeMessage({ commit }, message) {
    commit('deleteMessage', message);
  },

  unsubscribe({ state }, collection) {
    state.subscriptions[collection].unsubscribe();
  },

  getUsers({ state }) {
    state.users = [];
    state.offices = [];

    state.subscriptions.users.unsubscribe = db
      .collection('users')
      .onSnapshot((querySnapshot) => {
        state.users = [];
        querySnapshot.forEach((doc) => {
          state.users.push(doc.data());
        });
      });

    // db.collection('users')
    //   .get()
    //   .then((res) => {
    //     res.forEach((doc) => {
    //       state.users.push(doc.data());
    //     });
    //   });

    // db.collection('offices')
    //   .get()
    //   .then((res) => {
    //     res.forEach((doc) => {
    //       state.offices.push(doc.data());
    //     });
    //   });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
