import { storage, db } from '../../firebase/init.js';
//import * as firebase from "firebase";
import moment from 'moment';
const storageRef = storage.ref();

// Create a reference under which you want to list
//const db = firebase.firestore();
//let user = JSON.parse(localStorage.getItem('user'));

const state = {
  files: [],
  folders: [],
  percent: 0,
  tree: [],
  uploaded: false,
  loaded: {
    files: false,
    folders: false,
  },
  progress: {},
  unsubscribe: null,
};

const getters = {
  files: (state) => {
    return state.files;
  },

  folders: (state) => {
    return state.folders;
  },

  tree: (state) => {
    return state.tree;
  },

  loaded: (state) => {
    return state.loaded;
  },

  percent: (state) => {
    return state.percent;
  },

  uploaded: (state) => {
    return state.uploaded;
  },

  progress: (state) => {
    return state.progress;
  },
};

const mutations = {
  setPercent(state, file) {
    state.progress[file.name] = {};
    state.progress[file.name].percent = file.percent;
  },
};

const actions = {
  updatePercent({ commit }, file) {
    commit('setPercent', file);
  },

  getNewsImages({}) {
    return db
      .collection('files')
      .where('office', '==', 'Kalmar')
      .where('path', 'array-contains-any', ['uploads/news'])
      .get();
  },

  getJson({}, name) {
    return db
      .collection('json')
      .where('name', '==', name)
      .get();
  },

  saveJson({}, data) {
    db.collection('json')
      .doc(data.name)
      .set({
        name: data.name,
        json: JSON.stringify(data.json),
      });
  },

  async getFilesFromSpecificFolder({ state }, folder) {
    state.files = [];
    var listRef = storageRef.child(`uploads/${folder}`);
    // Find all the prefixes and items.
    let res = await listRef.listAll();

    res.items.forEach(async (itemRef) => {
      let link = await itemRef.getDownloadURL();
      state.files.push(link);
      // All the items under listRef.
    });
  },

  getAllFiles({ state }) {
    state.unsubscribe = db.collection('files').onSnapshot((querySnapshot) => {
      state.files = [];
      querySnapshot.forEach((doc) => {
        state.files.push(doc.data());
      });
    });
  },

  uploadFile({ state, commit }, data) {
    state.uploaded = false;
    let rootPath = 'uploads/';

    rootPath += data.name;
    let listRef = storageRef.child(rootPath);
    var uploadTask = listRef.put(data.file);

    uploadTask.on(
      'state_changed',
      function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        commit('setPercent', { name: data.name, percent: progress });
        //state.progress[data.name].percent = progress;
        switch (snapshot.state) {
          case storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      },
      function(error) {
        console.log(error);
        // Handle unsuccessful uploads
      },
      function() {
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          state.uploaded = true;
          db.collection('files')
            .doc(data.name)
            .set({
              name: data.name,
              type: data.type,
              url: downloadURL,
              uploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
              size: data.size,
            });
        });
      },
    );
  },

  deleteFile({}, file) {
    let listRef = storageRef.child(file.path);
    return listRef.delete().then(() => {
      return db
        .collection('files')
        .doc(file.name)
        .delete();
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
