<template>
  <div>
    <v-row>
      <v-col sm="3">
        <v-col sm="12">
          <v-text-field
            label="Faktura till kund"
            outlined
            suffix="SEK"
            type="number"
            v-model="order.invoice.amount"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col sm="12">
          <v-text-field
            label="Faktura till Dital"
            outlined
            suffix="SEK"
            type="number"
            v-model="order.finalInvoice.amount"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col sm="12">
          <v-textarea
            outlined
            auto-grow
            name="input-7-4"
            label="Övrig info"
            v-model="order.info"
            hide-details
          ></v-textarea>
        </v-col>
        <v-col sm="12">
          <v-file-input
            outlined
            multiple
            prepend-icon=""
            label="Avtal"
            v-model="order.files"
            hide-details
            :disabled="order.noFile"
          >
            <template v-slot:selection="{ index }">
              <span
                v-if="index === 0"
                class="overline grey--text text--darken-3 mx-2"
              >
                {{
                  order.files.length > 1
                    ? order.files.length + " Filer"
                    : order.files.length + " Fil"
                }}
              </span>
            </template>
          </v-file-input>
          <v-list-item v-if="!order.files || order.files.length < 1">
            <template v-slot:default="{}">
              <v-list-item-action>
                <v-checkbox v-model="order.noFile" color="primary"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Inget avtal</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-col>
        <v-col sm="12" v-show="order.files && order.files.length > 0">
          <v-card>
            <v-list subheader two-line>
              <v-list-item v-for="file in order.files" :key="file.name">
                <v-list-item-content>
                  <v-list-item-title v-text="file.name"></v-list-item-title>

                  <v-list-item-subtitle
                    v-text="formatBytes(file.size)"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-col>
      <v-col sm="9">
        <div style="overflow: scroll; height: 70vh">
          <v-data-table
            :headers="headers"
            :items="order.sales"
            class="elevation-1 text-center"
            hide-default-footer
            dense
            :items-per-page="1000"
          >
            <template #[`item.time`]="{item}">
              <v-edit-dialog
                :return-value.sync="item.time"
                persistent
                large
                cancel-text="Avbryt"
                save-text="Spara"
              >
                <v-icon v-if="item.time === undefined" right
                  >mdi-calendar</v-icon
                >

                <div v-else>{{ item.time }}</div>

                <template v-slot:input>
                  <v-date-picker
                    v-model="item.time"
                    no-title
                    scrollable
                  ></v-date-picker>
                </template>
              </v-edit-dialog>
            </template>
            <template #[`item.transfer`]="{item}">
              <v-edit-dialog :return-value.sync="item.name">
                <div
                  v-if="
                    item.transfer.name !== null &&
                      item.transfer.orgNumber !== null
                  "
                >
                  {{ item.transfer.name }} /
                  {{ item.transfer.orgNumber }}
                </div>
                <div v-else class="caption grey--text">
                  Ingen överlåtelse
                </div>

                <template v-slot:input>
                  <v-container>
                    <v-text-field
                      v-model="item.transfer.name"
                      label="Namn"
                      single-line
                      outlined
                      dense
                      hide-details
                      clearable
                    ></v-text-field>
                    <v-text-field
                      maxlength="11"
                      v-model="item.transfer.orgNumber"
                      label="Organisationsnummer"
                      single-line
                      dense
                      hide-details
                      outlined
                      clearable
                      v-mask="masks.persNr"
                    ></v-text-field>
                  </v-container>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("order", ["order", "customer"]),
    ...mapGetters("user", ["users"])
  },

  data() {
    return {
      masks: {
        persNr: "######-####"
      },
      dateSelected: null,
      headers: [
        { text: "Nr", value: "id" },
        { text: "Telefonnummer", value: "phoneNumber" },
        { text: "Typ", value: "type" },
        { text: "Abonnemang", value: "subscription" },
        { text: "Överlåtelse", value: "transfer" },
        { text: "Bindningstid upphör", value: "time" }
      ]
    };
  }
};
</script>
