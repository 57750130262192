var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"sm":"3"}},[_c('v-col',{attrs:{"sm":"12"}},[_c('v-text-field',{attrs:{"label":"Faktura till kund","outlined":"","suffix":"SEK","type":"number","hide-details":""},model:{value:(_vm.order.invoice.amount),callback:function ($$v) {_vm.$set(_vm.order.invoice, "amount", $$v)},expression:"order.invoice.amount"}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('v-text-field',{attrs:{"label":"Faktura till Dital","outlined":"","suffix":"SEK","type":"number","hide-details":""},model:{value:(_vm.order.finalInvoice.amount),callback:function ($$v) {_vm.$set(_vm.order.finalInvoice, "amount", $$v)},expression:"order.finalInvoice.amount"}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('v-textarea',{attrs:{"outlined":"","auto-grow":"","name":"input-7-4","label":"Övrig info","hide-details":""},model:{value:(_vm.order.info),callback:function ($$v) {_vm.$set(_vm.order, "info", $$v)},expression:"order.info"}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('v-file-input',{attrs:{"outlined":"","multiple":"","prepend-icon":"","label":"Avtal","hide-details":"","disabled":_vm.order.noFile},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v(" "+_vm._s(_vm.order.files.length > 1 ? _vm.order.files.length + " Filer" : _vm.order.files.length + " Fil")+" ")]):_vm._e()]}}]),model:{value:(_vm.order.files),callback:function ($$v) {_vm.$set(_vm.order, "files", $$v)},expression:"order.files"}}),(!_vm.order.files || _vm.order.files.length < 1)?_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"primary"},model:{value:(_vm.order.noFile),callback:function ($$v) {_vm.$set(_vm.order, "noFile", $$v)},expression:"order.noFile"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Inget avtal")])],1)]}}],null,false,2065886420)}):_vm._e()],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.order.files && _vm.order.files.length > 0),expression:"order.files && order.files.length > 0"}],attrs:{"sm":"12"}},[_c('v-card',[_c('v-list',{attrs:{"subheader":"","two-line":""}},_vm._l((_vm.order.files),function(file){return _c('v-list-item',{key:file.name},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(file.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.formatBytes(file.size))}})],1)],1)}),1)],1)],1)],1),_c('v-col',{attrs:{"sm":"9"}},[_c('div',{staticStyle:{"overflow":"scroll","height":"70vh"}},[_c('v-data-table',{staticClass:"elevation-1 text-center",attrs:{"headers":_vm.headers,"items":_vm.order.sales,"hide-default-footer":"","dense":"","items-per-page":1000},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.time,"persistent":"","large":"","cancel-text":"Avbryt","save-text":"Spara"},on:{"update:returnValue":function($event){return _vm.$set(item, "time", $event)},"update:return-value":function($event){return _vm.$set(item, "time", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(item.time),callback:function ($$v) {_vm.$set(item, "time", $$v)},expression:"item.time"}})]},proxy:true}],null,true)},[(item.time === undefined)?_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-calendar")]):_c('div',[_vm._v(_vm._s(item.time))])],1)]}},{key:"item.transfer",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.name},on:{"update:returnValue":function($event){return _vm.$set(item, "name", $event)},"update:return-value":function($event){return _vm.$set(item, "name", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-container',[_c('v-text-field',{attrs:{"label":"Namn","single-line":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(item.transfer.name),callback:function ($$v) {_vm.$set(item.transfer, "name", $$v)},expression:"item.transfer.name"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.masks.persNr),expression:"masks.persNr"}],attrs:{"maxlength":"11","label":"Organisationsnummer","single-line":"","dense":"","hide-details":"","outlined":"","clearable":""},model:{value:(item.transfer.orgNumber),callback:function ($$v) {_vm.$set(item.transfer, "orgNumber", $$v)},expression:"item.transfer.orgNumber"}})],1)]},proxy:true}],null,true)},[(
                  item.transfer.name !== null &&
                    item.transfer.orgNumber !== null
                )?_c('div',[_vm._v(" "+_vm._s(item.transfer.name)+" / "+_vm._s(item.transfer.orgNumber)+" ")]):_c('div',{staticClass:"caption grey--text"},[_vm._v(" Ingen överlåtelse ")])])]}}],null,true)})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }