//Mixins
import Formats from '@/mixins/formats.js';
import Helpers from '@/mixins/helpers.js';
import Vue from 'vue';
import VueQuill from 'vue-quill';
import VueTheMask from 'vue-the-mask';
import { firestorePlugin } from 'vuefire';
import App from './App.vue';
import Navbar from './components/Navbar';
// Vue.directive('permission', {
//   bind: function(el, binding) {
//     if (binding.value == 1) {
//       return true;
//     }
//   },
// });
// GLOBAL COMPONENTS
import NotificationList from './components/Notifications/NotificationList';
import Table from './components/Table';
import SalesTable from './components/tables/Sales';
import { firebase } from './firebase/init';
//import axios from "axios";
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(VueQuill)
Vue.use(firestorePlugin);
Vue.use(VueTheMask);
Vue.mixin(Formats);
Vue.mixin(Helpers);

String.prototype.truncate = function() {
  if (this.length > 6) return this.slice(0, 7) + '...';

  return this;
};

//import api from './clasess/api';

Vue.component('dss-navbar', Navbar);
Vue.component('dss-notification-list', NotificationList);
Vue.component('dss-table', Table);
Vue.component('dss-table-sales', SalesTable);

Vue.component('dss-dialog-products', () =>
  import('./components/dialogs/AddProducts'),
);

Vue.config.productionTip = false;

// router.beforeEach(async (to, from, next) => {
//   const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
//   let res = await api.checkJwt('auth/checkJwt');
//   if (to.path !== '/login') {
//     if (requiresAuth) {
//       if (res && res.verified) {
//         store.dispatch('xhr/authenticated', true);
//         next();
//       } else {
//         store.dispatch('xhr/authenticated', false);
//         router.push('/login');
//       }
//     } else {
//       next();
//     }
//   } else {
//     if (res && res.verified) {
//       router.push('/');
//     } else {
//       next();
//     }
//   }
// });

let app;

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    //store.dispatch('app/updateAuthenticated', true);
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});

// new Vue({
//   store,
//   router,
//   vuetify,
//   render: (h) => h(App),
// }).$mount('#app');
