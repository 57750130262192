import firebase from '@firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';

let firebaseConfig = {
  apiKey: 'AIzaSyAZF_vqAuzsnFl83x-smZKbdfKOmGMjRHM',
  authDomain: 'dital-production.firebaseapp.com',
  databaseURL: 'https://dital-production.firebaseio.com',
  projectId: 'dital-production',
  storageBucket: 'dital-production.appspot.com',
  messagingSenderId: '374496449650',
  appId: '1:374496449650:web:da32233015bf787ed89eeb',
  measurementId: 'G-K56N9PM0VM',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const database = firebase.database();
const storage = firebase.storage();
const auth = firebase.auth();
const functions = firebase.app().functions('europe-west3');

if (process.env.NODE_ENV === 'development') {
  // firebase.functions().useEmulator('localhost', 5001);
  // db.useEmulator('localhost', 8081);
  // database.useEmulator('localhost', 9000);
  // auth.useEmulator('http://localhost:9099');
  // storage.useEmulator('localhost', 9199);
}

export { firebase, storage, db, database, auth, functions };
