<template>
  <div class="snackbarlist" v-if="messages.length > 0">
    <div v-for="message in messages" :key="message.id">
      <notification
        :message="message"
        :messageType="message.messageType"
      ></notification>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Notification from "./Notification";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      count: 0,
      working: false,
    };
  },

  components: {
    notification: Notification,
  },

  computed: {
    ...mapGetters("user", ["messages"]),
    ...mapGetters("app", ["dark"]),
  },

  methods: {
    remove(message) {
      if (!this.working) {
        this.working = true;
        this.messages.forEach((record) => {
          if (message.id == record.id) {
            store.commit("user/deleteMessage", message);
            this.working = false;
          }
        });
      }
    },
  },
};
</script>

<style>
@media only screen and (max-width: 768px) {
  .snackbarlist {
    width: 250px;
  }
}
@media screen and (min-width: 768px) {
  .snackbarlist {
    width: 400px;
  }
}
.snackbarlist {
  padding: 10px 4px;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 9999;
}
</style>
