var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.tab < 1)?_c('div',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',[_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-1",attrs:{"xs":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"outlined":"","single-line":"","dense":""}})],1),_c('v-col',{staticClass:"mr-1",attrs:{"xs":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"outlined":"","single-line":"","dense":""}})],1),_c('v-col',{staticClass:"mr-1",attrs:{"xs":"12","md":"1"}},[_c('v-autocomplete',{attrs:{"outlined":"","single-line":"","dense":"","label":"Status","items":_vm.getStatuses()}})],1),_c('v-col',{staticClass:"mr-1",attrs:{"xs":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","outlined":"","single-line":"","dense":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}],null,false,3235568501),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Avbryt ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"mr-1",attrs:{"xs":"12","md":"1"}},[_c('v-autocomplete',{attrs:{"outlined":"","single-line":"","dense":"","label":"Kontor"}})],1),_c('v-col',{staticClass:"mr-1",attrs:{"xs":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"outlined":"","single-line":"","dense":""}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"dense":"","loading":_vm.loading,"no-data-text":"Hittade inga ordar med det sök kriteriet","loading-text":"Laddar ordrar ...","options":_vm.options,"headers":_vm.headers,"items":_vm.orders,"hide-default-footer":_vm.rowSelected,"page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.setCurrentOrder},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status)}},[_vm._v(_vm._s(item.status))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created)+" ")]}},(_vm.rowSelected)?{key:"footer",fn:function(ref){return [_c('v-container',[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]}}:null],null,true)})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }