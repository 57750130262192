<template>
  <div>
    <!-- <v-btn @click="fillCustomer">Fill customer</v-btn> -->
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container grid-list-md fluid>
        <v-row>
          <v-col xs="12" sm="4">
            <v-form ref="orgForm">
              <v-text-field
                v-model="order.customer.orgNr"
                label="Organisationsnummer"
                required
                :rules="orgNrRule"
                @keyup.stop="searchOrg()"
                outlined
                v-mask="masks.org"
                class="mb-2"
                @change="updateOrderOnLocalStorage(order)"
                :hint="
                  notFound
                    ? 'Organisationsnumret hittades inte i databasen'
                    : ''
                "
                :persistent-hint="notFound"
              >
                <template v-slot:append>
                  <v-fade-transition leave-absolute>
                    <v-icon v-if="found" color="success">check</v-icon>
                  </v-fade-transition>
                </template>
              </v-text-field>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" sm="4">
            <v-text-field
              v-model="order.customer.companyName"
              label="Företagets namn"
              required
              outlined
              :rules="[rules.required]"
              class="mb-2"
              @change="updateOrderOnLocalStorage"
            ></v-text-field>
            <v-text-field
              v-model="order.customer.street"
              label="Gata"
              required
              outlined
              :rules="[rules.required]"
              class="mb-2"
              @change="updateOrderOnLocalStorage"
            ></v-text-field>
            <v-text-field
              v-model="order.customer.zip"
              label="Postnummer"
              required
              v-mask="masks.zip"
              outlined
              :rules="[rules.required, zip]"
              class="mb-2"
              @change="updateOrderOnLocalStorage"
            ></v-text-field>
            <v-text-field
              v-model="order.customer.city"
              label="Stad"
              required
              outlined
              :rules="[rules.required]"
              class="mb-2"
              @change="updateOrderOnLocalStorage"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="4">
            <v-text-field
              v-model="order.customer.signatory.name"
              label="Firmatecknare"
              required
              outlined
              :rules="[rules.required]"
              class="mb-2"
              @change="updateOrderOnLocalStorage"
            ></v-text-field>
            <v-text-field
              v-model="order.customer.signatory.personalNumber"
              label="Personnummer"
              required
              v-mask="masks.pers_nr"
              outlined
              :rules="[rules.required, persNr]"
              class="mb-2"
              @change="updateOrderOnLocalStorage"
            ></v-text-field>
            <v-text-field
              v-model="order.customer.signatory.phoneNumber"
              label="Kontaktnummer"
              required
              v-mask="masks.phone"
              outlined
              :rules="[rules.required]"
              class="mb-2"
              @change="updateOrderOnLocalStorage"
            ></v-text-field>
            <v-text-field
              v-model="order.customer.signatory.email"
              label="E-post"
              required
              outlined
              :rules="[rules.required, rules.email]"
              class="mb-2"
              @change="updateOrderOnLocalStorage"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
//import store from '@/store';
import { mapGetters } from "vuex";
import api from "../../clasess/api";
//import Order from '../../clasess/order';

export default {
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("order", ["order", "customer"])
  },

  mounted() {
    //store.dispatch('order/resetOrder');
  },

  data() {
    return {
      //order: Order,
      valid: true,
      found: false,
      notFound: false,
      loading: false,
      masks: {
        org: "######-####",
        zip: "#####",
        phone: "####-######",
        pers_nr: "######-####"
      },
      currentCustomer: {
        companyName: "",
        orgNr: "",
        zip: "",
        street: "",
        city: "",
        signatory: {
          name: "",
          personal_number: "",
          number: "",
          email: ""
        },
        set: false
      },
      orgNrRule: [
        v => !!v || "Organisationsnummer måste finnas",
        v => (v && v.length === 11) || "Orgnummer måste vara 10 siffror"
      ],
      persNr: v =>
        (v && v.length === 11) || "Personnummer måste vara 10 siffror",
      zip: v => (v && v.length === 5) || "Postnumret måste vara 5 siffror",
      rules: {
        required: value => !!value || "Obligatoriskt fält.",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Ingen giltig epost.";
        }
      }
    };
  },

  methods: {
    validate() {
      this.$refs.orgForm.validate();
    },

    fillCustomer() {
      this.order.customer.orgNr = "5555555555";
      this.order.customer.city = "Ankeborg";
      this.order.customer.zip = "12345";
      this.order.customer.companyName = "Test AB";
      this.order.customer.street = "Enkeborgsvägen 1";
      this.order.customer.signatory = {
        name: "Test Testsson",
        personal_number: "1122334455",
        phone_number: "0709398000",
        email: "test@test.se"
      };
    },

    async searchOrg() {
      if (
        this.order.customer.orgNr.length &&
        this.order.customer.orgNr.length < 11
      ) {
        if (this.order.customer.orgNr.length > 2) {
          this.loading = true;
          this.found = false;
          this.notFound = false;
          this.order.customer.set = false;
        }
      }
      if (
        this.order.customer.orgNr.length == 11 &&
        !this.found &&
        !this.order.customer.set
      ) {
        console.log(this.order.customer.orgNr);
        let { customer } = await api.get("customers", {
          orgNr: this.order.customer.orgNr.replace("-", "")
        });
        if (customer !== null) {
          console.log(customer);
          this.order.customer = customer;

          this.loading = false;
          this.found = true;
          this.currentCustomer.set = true;
        } else {
          this.validate();
          this.found = false;
          this.notFound = true;
        }

        // let doc = await store.dispatch(
        //   'firestore/findCustomer',
        //   this.order.customer.orgNr.replace('-', ''),
        // );

        // if (doc.exists) {
        //   this.order.customer = doc.data();
        //   //this.saveCustomer();
        //   this.loading = false;
        //   this.found = true;
        //   this.currentCustomer.set = true;
        // } else {
        //   this.found = false;
        //   this.order.customer.city = '';
        //   this.order.customer.zip = '';
        //   this.order.customer.companyName = '';
        //   this.order.customer.street = '';

        //   this.order.customer.signatory = {
        //     name: '',
        //     personal_number: '',
        //     number: '',
        //     email: '',
        //   };
        // }
      }
    }
  }
};
</script>

<style></style>
