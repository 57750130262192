//import store from '@/store';
//import api from '../../clasess/api.js';
import { db } from '../../firebase/init.js';

const state = {
  authenticated: false,
  editItem: {
    edit: false,
    item: {},
  },
  latestRefreshed: new Date(),
  refreshed: false,
  dark: false,
  roles: [],
  offices: [],
  types: [],
  subscriptions: [],
  products: [],
  discounts: [],
  surcharges: [],
  operators: [],
  packages: [],
  agreement: '',
  agreements: [],
  agreementUsers: [],
  agreementPeriods: [],
  contractPeriods: [],
  whitelist_operators: [],
  users: [],
  ips: [],
  orders: [],
  currentStep: 0,
  dataToFetch: [
    'types',
    'subscriptions',
    'surcharges',
    'discounts',
    'packages',
    'users',
    'operators',
    'agreements',
    'contractPeriods',
    'agreementUsers',
    'agreementPeriods',
    'roles',
    // { url: 'contract_periods', variable: 'contractPeriods' },
    // { url: 'agreements/users', variable: 'agreementUsers' },
    // { url: 'agreements/periods', variable: 'agreementPeriods' },
    // { url: 'ip', variable: 'ips' },
  ],
  snackbar: {
    timeout: 6000,
    message: '',
    color: 'accent',
    show: false,
  },
};

const getters = {
  authenticated: (state) => {
    return state.authenticated;
  },

  orders: (state) => {
    return state.orders;
  },

  editItem: (state) => {
    return state.editItem;
  },

  snackbar: (state) => {
    return state.snackbar;
  },

  currentStep: (state) => {
    return state.currentStep;
  },

  dark: (state) => {
    return state.dark;
  },

  roles: (state) => {
    return state.roles;
  },

  offices: (state) => {
    return state.offices;
  },

  types: (state) => {
    return state.types;
  },

  subscriptions: (state) => {
    return state.subscriptions;
  },

  packages: (state) => {
    return state.packages;
  },

  surcharges: (state) => {
    return state.surcharges;
  },

  discounts: (state) => {
    return state.discounts;
  },

  operators: (state) => {
    return state.operators;
  },

  users: (state) => {
    return state.users;
  },

  agreement: (state) => {
    return state.agreement;
  },

  agreements: (state) => {
    return state.agreements;
  },

  agreementUsers: (state) => {
    return state.agreementUsers;
  },

  agreementPeriods: (state) => {
    return state.agreementPeriods;
  },

  contractPeriods: (state) => {
    return state.contractPeriods;
  },

  ips: (state) => {
    return state.ips;
  },
};

const mutations = {
  setAuthenticated(state, value) {
    state.authenticated = value;
  },

  setSnackbar(state, data) {
    state.snackbar.message = data.message;
    state.snackbar.color = data.color;
    state.snackbar.show = data.show;
  },

  setCurrentStep(state, step) {
    state.currentStep = step;
  },

  toggleDark(state, value) {
    state.dark = value;
  },

  setRoles(state, roles) {
    state.roles = roles;
  },

  setOffices(state, offices) {
    state.offices = offices;
  },

  setTypes(state, types) {
    state.types = types;
  },

  setSubscriptions(state, subscriptions) {
    state.subscriptions = subscriptions;
  },

  setDiscounts(state, discounts) {
    state.discounts = discounts;
  },

  setSurcharges(state, surcharges) {
    state.surcharges = surcharges;
  },

  setPackages(state, packages) {
    state.packages = packages;
  },

  setOperators(state, operators) {
    state.operators = operators;
  },

  setEditItem(state, data) {
    state.editItem = data;
  },

  setAgreement(state, agreement) {
    state.agreement = agreement;
  },

  setCategory(state, data) {
    state[data.category] = data.items;
  },

  setIps(state, ips) {
    state.ips = ips;
  },

  setOrders(state, orders) {
    state.orders = orders;
  },
};

const actions = {
  updateAuthenticated({ commit }, value) {
    commit('setAuthenticated', value);
  },

  updateSnackbar({ commit }, data) {
    commit('setSnackbar', data);
  },

  theme({ commit }, value) {
    commit('toggleDark', value);
  },

  updateRoles({ commit }, roles) {
    commit('setRoles', roles);
  },

  updateOffices({ commit }, offices) {
    commit('setOffices', offices);
  },

  updateCurrentStep({ commit }, step) {
    commit('setCurrentStep', step);
  },

  updateSubscriptions({ commit }, data) {
    commit('setSubscriptions', data);
  },

  updateDiscounts({ commit }, discounts) {
    commit('setDiscounts', discounts);
  },

  updateAgreement({ commit }, agreement) {
    commit('setAgreement', agreement);
  },

  updateTypes({ commit }, types) {
    commit('setTypes', types);
  },

  updateSurcharges({ commit }, surcharges) {
    commit('setSurcharges', surcharges);
  },

  updateOperators({ commit }, operators) {
    commit('setOperators', operators);
  },

  updatePackages({ commit }, packages) {
    commit('setPackages', packages);
  },

  updateEditItem({ commit }, data) {
    commit('setEditItem', data);
  },

  updateDataByName({ state }, data) {
    state[data.name] = data.items;
  },

  async refreshStaticDataByName({ state }, name) {
    let records = await db
      .collection(name)
      .orderBy('name', 'asc')
      .get();
    records.forEach((doc) => {
      state[name].push(doc.data());
    });
  },

  updateOrders({ commit }, orders) {
    commit('setOrders', orders);
  },

  async refreshStaticData({ state }) {
    state.dataToFetch.forEach(async (collection) => {
      if (!state[collection].length) {
        let records = await db
          .collection(collection)
          .orderBy(collection === 'surcharges' ? 'value' : 'name', 'asc')
          .get();
        records.forEach((doc) => {
          state[collection].push(doc.data());
        });
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
