import Vue from 'vue';
import Router from 'vue-router';
import { auth, firebase } from './firebase/init';
import store from './store';
import CorrectData from './views/admin/CorrectData';
import Login from './views/auth/Login';
import Documentation from './views/Documentation.vue';
import Filemanager from './views/Filemanager.vue';
import Home from './views/Home.vue';
import LinkData from './views/LinkData';
import News from './views/News.vue';
import DeletedOrders from './views/orders/Deleted.vue';
import Orders from './views/orders/Orders.vue';
import SingleOrder from './views/orders/SingleOrder.vue';
import Packages from './views/Packages.vue';
import RegisterOrder from './views/sale/Register';
import StaticData from './views/StaticData.vue';
import Statistics from './views/Statistics.vue';
import Users from './views/Users.vue';
import UserView from './views/UserView.vue';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    meta: {
      title: 'ORDRAR',
      requiresAuth: true,
    },
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/orders/:id',
    name: 'orderById',
    component: SingleOrder,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/orders/deleted',
    name: 'ordersDeleted',
    component: DeletedOrders,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
  },
  {
    path: '/sale/register',
    name: 'registerOrder',
    component: RegisterOrder,
    meta: {
      title: 'REGISTRERA ORDER',
      requiresAuth: true,
    },
  },
  {
    path: '/users/:id',
    name: 'userView',
    component: UserView,
    meta: {
      title: 'ANVÄNDARE',
      requiresAuth: true,
      requiresAdmin: true
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      title: 'ANVÄNDARE',
      requiresAuth: true,
      requiresAdmin: true
    },
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: Statistics,
    meta: {
      title: 'STATISTIK',
      requiresAuth: true,
      requiresAdmin: true
    },
  },
  {
    path: '/news',
    name: 'news',
    component: News,
    meta: {
      title: 'NYHETER',
      requiresAuth: true,
    },
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: Documentation,
    meta: {
      title: 'DOKUMENTATION',
      requiresAuth: true,
    },
  },
  {
    path: '/files',
    name: 'filemanager',
    component: Filemanager,
    meta: {
      title: 'FILHANTERARE',
      requiresAuth: true,
      requiresAdmin: true
    },
  },
  // {
  //   path: '/types',
  //   name: 'types',
  //   component: Types,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/charges',
  //   name: 'charges',
  //   component: Charges,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/packages',
    name: 'packages',
    component: Packages,
    meta: {
      title: 'PAKETHANTERARE',
      requiresAuth: true,
    },
  },
  {
    path: '/static',
    name: 'static',
    component: StaticData,
    meta: {
      title: 'STATISK DATA',
      requiresAuth: true,
    },
  },
  {
    path: '/linked',
    name: 'linedData',
    component: LinkData,
    meta: {
      title: 'LÄNKA DATA',
      requiresAuth: true,
    },
  },

  {
    path: '/admin',
    name: 'adminData',
    component: CorrectData,
    meta: {
      title: 'RÄTTA DATA',
      requiresAuth: true,
    },
  },

  { path: '*', redirect: '/' },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// navigation guard to check for logged in users
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const currentUser = auth.currentUser;
  if (currentUser) {
    let user = JSON.parse(localStorage.getItem("user"));
    //let {user} = await api.get(`users/user/${auth.currentUser.uid}`);

    // if(!user) {

    // }
    if (!user.active) {
      store.dispatch('app/updateAuthenticated', false);
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          router.push('/login');
          store.dispatch('app/updateAuthenticated', false);
        })
        .catch((error) => {
          console.log(error);
          // An error happened.
        });
    } else {
      store.dispatch('user/updateUser', user);
      store.dispatch('app/updateAuthenticated', true);
      const requiresAdmin = to.matched.some((x) => x.meta.requiresAdmin);
      if(requiresAdmin) {
        if(user.role.toLowerCase() === 'superadmin' || user.role.toLowerCase() === 'admin') {
          next()
        }
        else next('/')
      }
      else next(); 
    }
    next();
  }

  if (requiresAuth && !currentUser)
    next({ path: '/login', query: { redirect: to.fullPath } });
  else if (!requiresAuth && currentUser) next('/');
  else if (!requiresAuth && !currentUser) next();
  else next();
});

export default router;
