<template>
  <div>
    <v-toolbar flat dense fixed>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        :disabled="!selected.length"
        @click="deleteAllSelectedRows"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <div style="overflow: scroll; height: 50vh">
      <v-card>
        <dss-dialog-products
          :show="productsDrawer"
          :sale="currentSale"
        ></dss-dialog-products>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="sales"
          hide-default-footer
          :items-per-page="-1"
          no-data-text="Inga sälj inlagda"
          class="elevation-1"
          dense
          show-select
          item-key="id"
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <!-- <template v-slot:item.phone_number="props">
        <v-edit-dialog :return-value.sync="props.item.phone_number">
          {{
            props.item.phone_number == ''
              ? '07__ - ______'
              : props.item.phone_number
          }}
          <template v-slot:input>
            <v-text-field
              type="number"
              v-model="props.item.phone_number"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.products="{ item, index }">
        <v-chip
          :key="selection[item.id]"
          small
          @click="setNoProducts(item, index)"
          :color="
            item.products && !item.products.length
              ? 'deep-purple lighten-3'
              : ''
          "
          class="mr-1"
        >
          Nej
        </v-chip>
        <v-chip
          small
          @click="addProducts(item, index)"
          :color="item.products && item.products.length ? 'accent' : ''"
        >
          Ja
        </v-chip>
      </template>-->
          <!-- <template v-slot:item.delete="{ item }">
        <v-simple-checkbox v-model="rowsToDelete"></v-simple-checkbox>
      </template> -->
          <!-- <template v-slot:top>
            <v-toolbar flat dense>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                :disabled="!selected.length"
                @click="deleteAllSelectedRows"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-toolbar>
          </template> -->
          <template #[`item.phoneNumber`]="{item}">
            <v-edit-dialog :return-value.sync="item.phoneNumber">
              {{ item.phoneNumber == "" ? "07__ - ______" : item.phoneNumber }}
              <template v-slot:input>
                <v-text-field
                  type="number"
                  v-model="item.phoneNumber"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template #[`item.products`]="{item, index}">
            <v-chip
              small
              @click="setNoProducts(item, index)"
              :color="
                item.products && !item.products.length
                  ? 'deep-purple lighten-3'
                  : ''
              "
              class="mr-1"
            >
              Nej
            </v-chip>
            <v-chip
              small
              @click="addProducts(item, index)"
              :color="item.products && item.products.length ? 'accent' : ''"
            >
              Ja
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("order", ["sales"])
  },

  data() {
    return {
      selected: [],
      productsDrawer: false,
      currentSale: null,
      page: 1,
      pageCount: 0,
      headers: [
        { text: "Produkter", value: "products" },
        { text: "Typ", value: "type" },
        { text: "Abonnemang", value: "subscription" },
        { text: "Avtalstid", value: "contractPeriod" },
        { text: "Pristillägg", value: "surcharge" },
        { text: "Mandat", value: "discount" },
        { text: "Operatör", value: "operator" },
        { text: "Telefonnummer", value: "phoneNumber" },
        { text: "Kick", value: "kick" }
        // { text: 'ID', value: 'id' },
      ]
    };
  },

  methods: {
    hasNumbers(t) {
      var regex = /\d/g;
      return regex.test(t);
    },

    addProducts(item, index) {
      this.productsDrawer = true;
      this.currentSale = index;
    },

    setNoProducts(item, index) {
      if (this.hasNumbers(item.surcharge)) {
        this.addMessage(
          //'Du måste välja produkt när du lagt till pristillägg',
          "Det vore oklokt att lägga till pristilläg utan produkter",
          "warning"
        );
      } else {
        // item.products = [];
        // this.order.sales[index].products = [];
        // this.selection[item.id] = false;
        // item.productsToggled = true;
        //console.log("No selected", this.selection[item.id], item.products);
        //this.productsToggled = true;
        store.dispatch("order/updateProductsOnSale", {
          id: index,
          products: []
        });
        //this.$forceUpdate();
      }
    },

    deleteAllSelectedRows() {
      store.dispatch("order/deleteSales", this.selected);
      this.selected = [];
      //   for (let i = 0; i < this.rowsToDelete.length; i++) {
      //     if (this.rowsToDelete[i])
      //       store.dispatch('order/deleteSale', this.sales[i - 1]);
      //   }
      //   this.rowsToDelete = [];
    }
  }
};
</script>
