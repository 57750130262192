<template>
  <v-container>
    <v-layout>
      <v-flex xs6>
        <v-card class="mx-auto text-xs-center grey--text" color="white" dark>
          <v-card-text>
            <v-sheet color="white">
              <v-sparkline
                :value="value"
                color="primary"
                height="100"
                line-width="1"
                padding="24"
                smooth
              >
                <template slot="label" slot-scope="item">
                  ${{ item.value }}
                </template>
              </v-sparkline>
            </v-sheet>
          </v-card-text>

          <v-card-text>
            <div class="display-1 font-weight-thin">Sales Last 24h</div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="justify-center">
            <v-btn block text dark>Go to Report</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
    value: [200, 675, 410, 390, 310, 460, 250, 240]
  })
};
</script>

<style>
</style>
