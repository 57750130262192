import router from '@/router';
import store from '@/store';
import axios from 'axios';
import { db, firebase, storage } from '../firebase/init.js';

const storageRef = storage.ref();

let baseURL = 'http://localhost:8091';
if (process.env.NODE_ENV === 'production') {
  // baseURL = 'https://api.optify.nu';
  baseURL = 'https://optify-api.herokuapp.com';
}

const webroot = baseURL + '/api/v1/';

//axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  function(response) {
    if (response.status !== 200) {
      store.dispatch('xhr/authenticated', false);
      router.push('/login');
    }
    return response;
  },
  function(error) {
    //router.push("/login");
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

let api = {
  async get(collection, filter) {
    let token = await firebase.auth().currentUser.getIdToken();
    let user = JSON.parse(localStorage.getItem('user'));

    let result = await axios.get(webroot + collection, {
      params: { filter },
      headers: { authorization: token, uid: user.uid},
    });

    return result.data;
  },

  async put(collection, data) {
    let token = await firebase.auth().currentUser.getIdToken();
    let user = JSON.parse(localStorage.getItem('user'));

    let result = await axios.put(webroot + collection, data, {
      headers: { authorization: token, uid: user.uid},
    });

    return result.data;
  },

  async post(collection, data) {
    let token = await firebase.auth().currentUser.getIdToken();

    let result = await axios.post(webroot + collection, data, {
      headers: { authorization: token },
    });

    return result.data.res;
  },

  async getOrders() {
    let token = await firebase.auth().currentUser.getIdToken(true);

    let result = await axios.get(webroot + 'orders', {
      headers: { authorization: token },
    });
    return result.data;
  },

  async saveOrder(order) {
    let token = await firebase.auth().currentUser.getIdToken(true);
    order.customer.orgNr = order.customer.orgNr.replace('-', '');

    //let user = JSON.parse(localStorage.getItem('user'));

    //order.seller = { uid: user.uid, name: user.name, office: user.office };

    let result = await axios.put(webroot + 'orders', order, {
      headers: { authorization: token },
    });

    return result.data.res;
  },

  async saveGeneratedOrder(orders) {
    let token = await firebase.auth().currentUser.getIdToken(true);
    //order.customer.orgNr = order.customer.orgNr.replace('-', '');

    let result = await axios.put(webroot + 'orders/generated', orders, {
      headers: { authorization: token },
    });

    return result.data.res;
  },

  async updateOrder(order) {
    console.log('Updating order...');
    let token = await firebase.auth().currentUser.getIdToken(true);

    let result = await axios.post(webroot + 'orders', order, {
      headers: { authorization: token },
    });

    return result.data.res;
  },

  async saveComment(data) {
    console.log(data);
  },

  // async get(collection, id) {
  //   // return new Promise((resolve) => {
  //   //   db.collection(collection)
  //   //     .doc(id)
  //   //     .onSnapshot((doc) => {
  //   //       console.log('Current data: ', doc.data());
  //   //       resolve(doc);
  //   //     });
  //   // });

  //   return db
  //     .collection(collection)
  //     .doc(id)
  //     .get();
  //   // // Need to refresh the token before it is sent to the backend
  //   // let token = await firebase.auth().currentUser.getIdToken(true);

  //   // let result = await axios.get(webroot + url, {
  //   //   params: payload,
  //   //   headers: { authorization: token },
  //   // });
  //   // return result.data.res;
  // },

  getWhere(data) {
    return db
      .collection(data.collection)
      .doc(data.id)
      .where(data.where)
      .get();
  },

  // async post(collection, item) {
  //   let token = await firebase.auth().currentUser.getIdToken(true);

  //   let result = await axios.post(webroot + collection, item, {
  //     headers: { authorization: token },
  //   });

  //   return result.data.res;
  // },

  // async post(url, data) {
  //   let result = await axios.post(webroot + url, data);

  //   return result.data.res;
  // },

  // async put(collection, data) {
  //   let token = await firebase.auth().currentUser.getIdToken();

  //   let result = await axios.put(webroot + collection, data, {
  //     headers: { authorization: token },
  //   });

  //   return result.data;
  // },

  async update(collection, data) {
    if (data.file) {
      await this.upload({
        filename: data.item.id,
        path: collection,
        file: data.file,
      });
    }
    let item;

    if (data.item) item = data.item;
    else if (data.field) item = data.field;

    await db
      .collection(collection)
      .doc(data.id)
      .update(item);
  },

  async deleteField(collection, data) {
    await db
      .collection(collection)
      .doc(data.item.id)
      .update({
        [data.field]: firebase.firestore.FieldValue.delete(),
      });
  },

  async deleteFile(file) {
    // Create a reference to the file to delete
    var imgRef = storageRef.child(file);

    // Delete the file
    await imgRef.delete();
  },

  async upload(data) {
    /* 
        data: {
            filename
            path
            file
        }
      */

    //let rootPath = 'uploads/profilepictures/' + data.file.name;
    let rootPath = `${data.path}/${data.filename}`;

    let listRef = storageRef.child(rootPath);
    var uploadTask = listRef.put(data.file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        function(snapshot) {
          // var progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          // );
          //state.progress[data.name].percent = progress;
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              break;
          }
        },
        // Error
        function(error) {
          reject(error);
        },

        function() {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(async function(downloadURL) {
              // let user = JSON.parse(localStorage.getItem('user'));
              // await db
              //   .collection('files')
              //   .doc(data.filename)
              //   .set({
              //     type: data.file.type,
              //     path: rootPath,
              //     url: downloadURL,
              //     size: data.file.size,
              //     office: user.office,
              //   });

              resolve(downloadURL);
            });
        },
      );
    });
  },
};

export default api;
