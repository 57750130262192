<template>
  <v-container v-if="user !== null">
    <v-row>
      <v-col xs="12" md="3">
        <v-card height="80vh">
          <v-container class="pr-15 pl-15 pt-10">
            <v-card flat justify="center">
              <v-card-title justify="center">
                <v-avatar color="primary" size="128" tile style="left: 20%">
                  <v-img
                    :aspect-ratio="1"
                    :src="
                      user.img.url === ''
                        ? require('@/assets/profile.png')
                        : user.img.url
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          :width="1"
                          indeterminate
                          color="grey lighten-1"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
              </v-card-title>
            </v-card>
          </v-container>
        </v-card>
      </v-col>
      <v-col xs="12" md="8">
        <v-tabs color="primary" class="mb-5" background-color="transparent">
          <v-tab>Item One</v-tab>
          <v-tab>Item Two</v-tab>
          <v-tab>Item Three</v-tab>
        </v-tabs>
      </v-col>
      <!-- <v-col xs="12" md="10">
        <v-toolbar flat color="transparent">
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row no-gutters>
          <v-col xs="12" md="3">
            <v-select
              :value="user.role"
              :items="roles"
              item-text="name"
              filled
              label="Roll"
            ></v-select>
          </v-col>
          <v-col xs="12" md="3">
            <v-select
              v-model="office"
              :value="user.office"
              :items="offices"
              item-text="name"
              item-id="name"
              filled
              label="Kontor"
              return-object
            ></v-select>
          </v-col>
          <v-col xs="12" md="3">
            <v-select
              :items="office.subOffices"
              item-text="name"
              filled
              label="Team"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col xs="12" md="3">
            <v-text-field disabled filled :value="user.email"></v-text-field>
          </v-col>
        </v-row>
      </v-col> -->
    </v-row>
  </v-container>
  <!-- <v-toolbar flat color="transparent">
      <v-btn @click="$router.go(-1)">Tillbaka</v-btn>
    </v-toolbar> -->
</template>

<script>
import store from "@/store";
import router from "@/router";
import api from "../clasess/api";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("app", ["roles"])
  },

  data() {
    return {
      user: null,
      uploading: false,
      offices: [],
      office: ""
    };
  },

  methods: {
    async handleFile() {
      this.uploading = true;

      const reader = new FileReader();
      let file = this.$refs.file.files[0];

      let newFile;
      reader.onload = function(e) {
        newFile = e.target.result;
      };
      reader.readAsDataURL(file);
      let downloadURL = await this.upload(file);
      await store.dispatch("firestore/updateUser", {
        uid: this.user.uid,
        img: { url: downloadURL, name: file.name }
      });

      // if (this.user.img.url !== '') {
      //   await api.deleteFile(
      //     `uploads/profilepictures/${this.user.img.name}`,
      //   );
      // }

      this.user.img.url = newFile;

      api.post("comments/profilePicture", {
        uid: this.user.uid,
        url: downloadURL
      });
      this.uploading = false;
      store.dispatch("user/addMessage", {
        body: "Ny profilbild sparad!",
        type: "success",
        messageType: "message"
      });
    },

    backToUsers() {
      router.push("/users");
    }
  },

  async mounted() {
    let { user } = await api.get("users/user", { uid: this.$route.params.id });
    this.user = user;
  }
};
</script>
