<template>
  <v-data-table :headers="headers" :items="records" class="elevation-1">
    <template slot="items" slot-scope="props">
      <td v-for="(header, index) in headers" :key="index">
        <div v-if="header.value == 'name'">
          {{ props.item.user.name }}
        </div>
        <div v-else>
          {{ props.item[header.value] }}
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["headers", "records"],

  data() {
    return {};
  },

  mounted() {}
};
</script>