const state = {
  agreement: null,
  data: [],
};

const getters = {
  data: (state) => {
    return state.data;
  },

  type: (state) => (id) => {
    return state.data.find((type) => type.id === id);
  },

  agreement: (state) => {
    return state.agreement;
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },

  setType(state, type) {
    let found = false;
    state.data.array.forEach((element) => {
      if (element.id === type.id) {
        found = true;
        element = type;
      }
    });

    if (!found) {
      state.data.push(type);
    }
  },

  setAgreement(state, agreement) {
    state.agreement = agreement;
  },
};

const actions = {
  updateData({ commit }, data) {
    commit('setData', data);
  },

  updateType({ commit }, type) {
    commit('setType', type);
  },

  updateAgreement({ commit }, agreement) {
    commit('setAgreement', agreement);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
