<template>
  <div>
    <!-- <v-btn @click="generateSaleRow">GENERATE SALES ROW</v-btn> -->
    <div>
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-row no-gutters>
          <v-col xs="12" md="2">
            <v-select
              :items="agreements"
              v-model="order.agreement.type"
              item-value="id"
              item-text="name"
              label="Avtal"
              dense
              outlined
              required
              return-object
              @change="setTypes"
              :disabled="agreementDone"
            ></v-select>
          </v-col>
          <v-col
            xs="12"
            md="2"
            v-if="order.agreement.type.name != 'Ingen förändring'"
          >
            <v-select
              :items="agreementPeriods"
              v-model="order.agreement.period"
              label="Avtalstid"
              item-value="name"
              item-text="name"
              dense
              outlined
              return-object
              :required="agreement.id != 1"
              :disabled="agreementDone"
            ></v-select>
          </v-col>
          <v-col
            xs="12"
            md="2"
            v-if="order.agreement.type.name != 'Ingen förändring'"
          >
            <v-select
              :items="agreementUsers"
              v-model="order.agreement.users"
              label="Användare"
              item-value="name"
              item-text="name"
              dense
              outlined
              :required="order.agreement.type.name != 'Ingen förändring'"
              return-object
              :disabled="agreementDone"
            ></v-select>
          </v-col>
          <v-col xs="12" md="2">
            <v-btn
              icon
              class="ml-4"
              v-if="agreementDone"
              @click="resetAgreement"
              ><v-icon>mdi-cancel</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <div v-show="agreementDone">
          <v-row no-gutters>
            <v-col xs="12" md="2">
              <v-select
                :items="contractPeriods"
                v-model="sale.contractPeriod"
                :rules="contractRule"
                item-value="name"
                item-text="name"
                label="Bidningstid"
                dense
                outlined
                required
              ></v-select>
            </v-col>
            <v-col xs="12" md="2">
              <v-select
                :items="types"
                v-model="sale.type"
                label="Typ"
                :rules="typeRule"
                item-value="id"
                item-text="name"
                dense
                outlined
                required
                :loading="loading.types"
                @change="setSubs"
              ></v-select>
            </v-col>
            <v-col xs="12" md="2">
              <v-select
                :items="subscriptions"
                v-model="sale.subscription"
                label="Abonnemangsform"
                :rules="subRule"
                item-value="id"
                item-text="name"
                dense
                outlined
                required
                :loading="loading.subs"
                @change="setLinkedData"
              ></v-select>
            </v-col>
            <v-col xs="12" md="2">
              <v-select
                :items="discounts"
                v-model="sale.discount"
                label="Mandat"
                item-value="name"
                item-text="name"
                dense
                outlined
                required
                return-object
                :rules="discountRule"
              >
              </v-select>
            </v-col>
            <v-col xs="12" md="1">
              <v-select
                :items="surcharges"
                v-model="sale.surcharge"
                label="Pristillägg"
                item-value="name"
                item-text="name"
                dense
                outlined
                required
                return-object
                :rules="surchargeRule"
              ></v-select>
            </v-col>
            <v-col xs="12" md="1">
              <v-text-field
                v-model="sale.kick"
                label="Kick"
                dense
                outlined
                required
                :rules="kickRule"
              ></v-text-field>
            </v-col>
            <v-col xs="12" md="1">
              <v-select
                :items="amounts"
                v-model="amount"
                label="Antal"
                dense
                outlined
                required
              ></v-select>
            </v-col>
            <v-col xs="12" md="1">
              <v-btn class=" ml-4" @click="add"><v-icon>add</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="12" md="2">
              <v-select
                v-if="disabled"
                :items="operators"
                v-model="sale.operator"
                label="Operatör"
                item-value="name"
                item-text="name"
                dense
                outlined
                return-object
                :rules="operatorRule"
                :required="disabled"
              ></v-select>
            </v-col>
            <v-col xs="12" md="8"></v-col>
            <v-col xs="12" md="1">
              <v-btn
                color="error"
                v-if="deleteRows"
                fab
                small
                @click="deleteAllSelectedRows"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </div>
    <div style="overflow: scroll; height: 55vh">
      <dss-table-sales v-if="agreementDone"></dss-table-sales>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";

import fakes from "../../clasess/fakes";

export default {
  async mounted() {
    store
      .dispatch("firestore/getData", {
        collection: "whitelist",
        id: "whitelist_operators"
      })
      .then(doc => {
        this.whitelist_operators = doc.data().records;
      })
      .catch(() => {
        this.addMessage(
          "Finns ingen white list för att visa vilka abonnemangsformer som ska visa operatörsrutan",
          "error"
        );
      });
  },

  computed: {
    ...mapGetters("app", ["operators", "agreements", "contractPeriods"]),
    ...mapGetters("order", ["order"]),

    agreementDone() {
      if (this.order.agreement) {
        return (
          (this.order.agreement.type.id != "" &&
            this.order.agreement.period.id != "" &&
            this.order.agreement.users.id != "") ||
          this.order.agreement.type.name === "Ingen förändring"
        );
      } else return false;
    },

    deleteRows() {
      for (let i = 0; i < this.rowsToDelete.length; i++) {
        if (this.rowsToDelete[i]) return true;
      }

      return false;
    },

    disabled() {
      if (this.sale.type.name) {
        return this.whitelist_operators.includes(this.sale.type.name);
      } else return false;
    }
  },

  data() {
    return {
      packages: [],
      agreementPeriods: [],
      agreementUsers: [],
      whitelist_operators: [],
      value: 0,
      rowsToDelete: [],
      loading: {
        types: false,
        subs: false
      },
      types: [],
      discounts: [],
      surcharges: [],
      valid: true,
      show: false,
      amount: 1,
      amounts: [1, 2, 3, 4, 5, 10, 20, 50],
      agreement: {},
      agreementData: {
        period: "",
        users: ""
      },
      sale: {
        id: null,
        type: "",
        subscription: "",
        phoneNumber: "",
        contractPeriod: 1,
        surcharge: "",
        operator: "",
        discount: "",
        kick: "",
        products: null,
        transfer: {
          name: null,
          orgNumber: null
        }
      },
      subscriptions: [],
      headers: [
        { text: "Produkter", value: "products" },
        { text: "Typ", value: "type" },
        { text: "Abonnemang", value: "subscription" },
        { text: "Avtalstid", value: "contractPeriod.name" },
        { text: "Pristillägg", value: "surcharge.name" },
        { text: "Mandat", value: "discount.name" },
        { text: "Operatör", value: "operator.name" },
        { text: "Telefonnummer", value: "phoneNumber" },
        { text: "Kick", value: "kick" },
        { text: "Radera", value: "delete" }
      ],
      menus: [],
      phoneMask: "#### - ######",
      typeRule: [v => !!v || "Ingen typ vald"],
      subRule: [v => !!v || "Ingen abonnemangsform vald"],
      contractRule: [v => !!v || "Ingen bindningstid vald"],
      phoneRule: [v => !!v || "Ingen bindningstid vald"],
      surchargeRule: [v => !!v || "Inget pristillägg vald"],
      operatorRule: [v => !!v || "Inget operatör vald"],
      discountRule: [v => !!v || "Inget mandat vald"],
      kickRule: [v => !!v || "Ingen kick ifylld"],
      agreementUsersRule: [v => !!v || "Välj antalet användare"]
    };
  },

  methods: {
    resetAgreement() {
      this.order.agreement = this.deepCopyObject({
        type: { name: "", id: "" },
        period: { id: "", name: "" },
        users: { id: "", name: "" }
      });
      this.order.sales = [];
    },

    deleteAllSelectedRows() {
      for (let i = 0; i < this.rowsToDelete.length; i++) {
        if (this.rowsToDelete[i])
          store.dispatch("order/deleteSale", this.sales[i - 1]);
      }
      this.rowsToDelete = [];
    },

    addMessage(body, type = "info") {
      store.dispatch("user/addMessage", {
        body: body,
        type: type,
        messageType: "message"
      });
    },

    setUsersAndPeriods(agreement) {
      this.agreementPeriods = agreement.periods;
      this.agreementUsers = agreement.users;
    },

    resetTypesSubs() {
      this.types = [];
      this.sale.type = "";
      this.subscriptions = [];
      this.sale.subscription = "";
    },

    setTypes() {
      this.loading.types = true;
      this.resetTypesSubs();

      store
        .dispatch("firestore/getLlinkedData", this.order.agreement.type)
        .then(result => {
          let doc = result.docs[0].data();
          this.types = doc.records;
          if (!this.order.agreement.type.name.includes("Ingen förändring")) {
            this.setUsersAndPeriods(doc.agreement);
          }
        })
        .catch(() => {
          this.addMessage(
            "Finns inga typer eller abonnemangsformer knytna till detta avtal",
            "error"
          );
        });
      this.loading.types = false;
    },

    setLinkedData(item) {
      this.subscriptions.forEach(subscription => {
        if (subscription.name === item) {
          this.surcharges = subscription.surcharges;
          this.discounts = subscription.discounts;
        }
      });
    },

    setSubs(item) {
      this.surcharges = [];
      this.discounts = [];
      this.types.forEach(t => {
        if (t.name === item) this.subscriptions = t.subs;
      });
    },

    hasNumbers(t) {
      var regex = /\d/g;
      return regex.test(t);
    },

    deleteSale(item) {
      this.menus[item.id] = false;
    },

    generateSaleRow() {
      store.dispatch("order/addSale", fakes.sale());

      // let type = fakes.sale();
      // console.log(type);
      // let sale = {
      //   status: fakes.status(),
      //   type: type,
      //   subscription: fakes.subscription(),
      //   phone_number: "070939800" + (this.order.sales.length + 1),
      //   contract_period: fakes.contractPeriod(),
      //   surcharge: fakes.surcharge(),
      //   operator: type.name.includes("Portering") ? fakes.operator() : "",
      //   discount: fakes.discount(),
      //   kick: fakes.kick(),
      //   time: fakes.date(),
      //   transfer: fakes.transfer(),
      //   products: fakes.products()
      // };

      //this.order.sales.push(sale);
    },

    add() {
      if (this.$refs.form.validate()) {
        for (let i = 0; i < this.amount; i++) {
          let newSale = this.deepCopyObject(this.sale);
          newSale.status = "OBEHANDLAD";
          store.dispatch("order/addSale", newSale);
        }

        this.sale = {
          id: null,
          type: "",
          subscription: "",
          phoneNumber: "",
          contractPeriod: "",
          surcharge: "",
          operator: "",
          discount: "",
          kick: "",
          transfer: {
            name: null,
            orgNumber: null
          },
          products: null
        };
        this.$refs.form.resetValidation();
        this.valid = true;
      }
    }
  }
};
</script>

<style>
.progg {
  height: 3px;
  background: #ef9a9a;
  -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  position: absolute;
  margin-top: -20px;
  left: -16px;
}
</style>
