<template>
  <v-navigation-drawer
    :mini-variant="mini"
    v-model="drawer"
    width="240"
    mini-variant-width="68"
    app
    clipped
    fixed
    floating
    class="nav-background"
  >
    <!-- <v-list class="pa-0" flat>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img :src="image" width="10px" aspect-ratio="1.7778"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>OPTIFY</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->
    <v-list shaped expand>
      <template v-for="(item, i) in items">
        <v-list-item
          :key="i"
          :to="item.to"
          active-class="highlighted"
          :class="item.path === $route.path ? 'highlighted' : ''"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-for="item in adminItems">
        <v-list-item
          :key="item.name"
          :to="item.to"
          active-class="highlighted"
          :class="item.path === $route.path ? 'highlighted' : ''"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-for="item in superAdmin">
        <v-list-item
          v-if="checkSuperAdmin"
          :key="item.name"
          :to="item.to"
          active-class="highlighted"
          :class="item.path === $route.path ? 'highlighted' : ''"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-for="item in devOptions">
        <v-list-item
          v-if="checkSuperAdmin && user.email === 'hannes@almar.se'"
          :key="item.name"
          :to="item.to"
          active-class="highlighted"
          :class="item.path === $route.path ? 'highlighted' : ''"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="10"
        offset-x
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-list class="pa-0">
            <v-list-item class="px-2" v-on="on">
              <v-list-item-avatar>
                <v-img
                  :src="
                    user.img !== '' ? user.img : require('@/assets/profile.png')
                  "
                  width="10px"
                  aspect-ratio="3"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>

        <v-card width="400">
          <!-- <v-list>
            <v-list-item>
              <v-list-item-title>Färgtema</v-list-item-title>
              <v-switch
                v-model="toggle"
                append-icon="brightness_medium"
                @change="toggleDark"
              ></v-switch>
            </v-list-item>
          </v-list> -->

          <v-form ref="form" v-model="form" class="pa-3 pt-4">
            <v-text-field
              v-model="password"
              :rules="[rules.password, rules.length(6)]"
              filled
              label="Nytt lösenord"
              style="min-height: 96px"
              type="password"
              autocomplete
            ></v-text-field>
            <v-text-field
              v-model="password_confirm"
              filled
              label="Upprepa lösenordet"
              type="password"
              autocomplete
            ></v-text-field>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="primary" @click="logout">Logga ut</v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!form" class="white--text" depressed>Spara</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-list-item @click="mini = !mini">
        <v-list-item-action v-show="mini">
          <v-icon small>mdi-chevron-right</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-show="!mini">
          <v-icon color="grey lighten-1" small>mdi-chevron-left</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { mapGetters } from "vuex";
import { firebase } from "../firebase/init";

export default {
  computed: {
    /* authenticated() {
      return store.state.isLogged;
    }, */

    ...mapGetters("app", ["authenticated"]),
    ...mapGetters("user", ["user"]),

    checkSuperAdmin() {
      if (this.authenticated && this.user) {
        return this.user.role.toLowerCase() === "superadmin";
      }

      return false;
    },

    icon() {
      if (this.prominent) return "mdi-chevron-up";

      return "mdi-chevron-down";
    }
  },

  data: () => ({
    security: false,
    search: "",
    collapse: false,
    model: 0,
    prominent: false,
    image: { src: require("@/assets/favicon.png") },
    form: "",
    toggle: false,
    mini: false,
    menu: false,
    drawer: true,
    password: "",
    password_confirm: "",
    items: [
      { icon: "mdi-home", text: "Startsida", to: "/" },
      { icon: "bar_chart", text: "Statistik", to: "/statistics" },
      { icon: "mdi-newspaper", text: "Nyheter", to: "/news" },
      {
        icon: "mdi-file-document-outline",
        text: "Dokumentation",
        to: "/documentation"
      },
      {
        icon: "mdi-cart-plus",
        text: "Registrera Sälj",
        to: "/sale/register"
      }
    ],
    adminItems: [{ icon: "mdi-cart", text: "Ordrar", to: "/orders" }],
    superAdmin: [
      { icon: "mdi-package-variant", text: "Paket", to: "/packages" },
      { icon: "mdi-database-settings", text: "Data", to: "/static" },
      { icon: "mdi-link-variant", text: "Länka Data", to: "/linked" },
      //{ icon: 'mdi-image-multiple', text: 'Filer', to: '/files' },
      { icon: "mdi-account-multiple", text: "Användare", to: "/users" },
      { icon: "mdi-office-building", text: "Kontor", to: "/offices" }
    ],
    devOptions: [{ icon: "mdi-shield", text: "Admin", to: "/admin" }],
    rules: {
      password_confirm: v => v === this.password || "Lösenorden matchar inte",
      length: len => v =>
        (v || "").length >= len || `Invalid character length, required ${len}`,
      password: v =>
        (v || "").match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
        "Lösenordet måste innehålla en stor bokstav, en siffra, och ett specialtecken",
      required: v => !!v || "This field is required"
    }
  }),

  methods: {
    generateNote() {
      let typesRandom = ["info", "error", "warning", "success"];
      let types = ["message", "file"];

      let int = Math.floor(Math.random() * 4);
      let mtype = Math.floor(Math.random() * 2);

      store.dispatch("user/addMessage", {
        body: "Uppdateringen lyckades!",
        type: typesRandom[int],
        messageType: types[mtype]
      });
    },

    toggleDark() {
      this.$vuetify.theme.dark = this.toggle;
    },

    logout() {
      // db.collection('users')
      //   .doc(this.user.uid)
      //   .onSnapshot({ includeMetadataChanges: true }, (snap) => {
      //     if (!snap.metadata.fromCache) {
      //       const user = snap.data();
      //       // Code here
      //       console.log(user);
      //     }
      //   });
      store.dispatch("user/updateUser", undefined);
      store.dispatch("app/updateAuthenticated", false);
      localStorage.removeItem("user");
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          router.push("/login");
        })
        .catch(error => {
          console.log(error);
          // An error happened.
        });
    }
  }
};
</script>

<style>
.highlighted {
  background: #14a6a9;
  border-left: 5px solid #0ddbc7;
  color: white !important;
}
.extend {
  background-color: #173d52;
  border-left: 5px solid #0ddbc7;
}
.nav-background {
  background-color: #fafafa !important;
}
</style>
