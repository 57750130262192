<template>
  <v-layout>
    <v-flex xs6>
      <v-toolbar>
        <v-btn @click="generateUuid" v-if="dev">Generate UUID</v-btn>
        <v-btn @click="logout" v-if="dev">Logout</v-btn>
        <v-btn @click="generateOrder">Save fake order</v-btn>
        <!-- <v-btn @click="getTest" v-if="dev">Test</v-btn>
        <v-btn @click="generateStaticData" v-if="dev">SEED static data</v-btn>
        <v-btn @click="getAllUsers" v-if="dev">USERS</v-btn>
        <v-btn @click="createUser" v-if="dev">Skapa ny användare</v-btn>
        <v-btn @click="addRole" v-if="dev">Add admin role</v-btn>
        <v-btn @click="changePassword" v-if="dev">Change password</v-btn>
        <v-btn @click="getOrdersRestApi" v-if="dev"
          >Get Orders from rest api</v-btn
        > -->
      </v-toolbar>
      <v-card class="mx-auto text-xs-center grey--text" color="white" dark>
        <v-card-text>
          <v-sheet color="white">
            <v-sparkline
              :value="value"
              color="primary"
              height="100"
              line-width="1"
              padding="24"
              smooth
            >
              <template slot="label" slot-scope="item">
                ${{ item.value }}
              </template>
            </v-sparkline>
          </v-sheet>
        </v-card-text>

        <v-card-text>
          <div class="display-1 font-weight-thin">Sales Last 24h</div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center">
          <v-btn block text dark>Go to Report</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import store from "@/store";
import router from "@/router";
import api from "../clasess/api";
import fake from "../clasess/fakes";
import { firebase } from "../firebase/init";

export default {
  computed: {
    dev() {
      return process.env.NODE_ENV === "development";
    }
  },

  data: () => ({
    labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
    value: [200, 675, 410, 390, 310, 460, 250, 240]
  }),

  methods: {
    async generateOrder() {
      let order = fake.order();
      await api.put("orders", order);
    },
    generateStaticData() {
      store.dispatch("firestore/insertStaticData");
    },

    generateUuid() {
      console.log(this.uuidv4());
    },

    getAllUsers() {
      store.dispatch("firestore/getAllUsers").then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.data());
        });
      });
    },
    addRole() {
      store.dispatch("firestore/addAdminRole", "hannes@almar.se");
    },

    changePassword() {
      store.dispatch("firestore/changePasswordForCurrentUser");
    },

    createUser() {
      let { displayName, email } = firebase.auth().currentUser;
      let user = {
        displayName,
        email
      };
      console.log(user);
      // let user = {
      //   email: 'test@dital.nu',
      //   password: 'bytmig123',
      //   office: 'Kalmar',
      //   name: 'Test Testsson',
      //   role: 'seller',
      //   active: true,
      // };
      // store
      //   .dispatch('firestore/createUser', user)
      //   .then(() => {
      //     console.log('User created');
      //   })
      //   .catch((err) => console.log(err));
    },

    async getOrdersRestApi() {
      let orders = await api.getOrders();
      console.log(orders);
    },

    logout() {
      // db.collection('users')
      //   .doc(this.user.uid)
      //   .onSnapshot({ includeMetadataChanges: true }, (snap) => {
      //     if (!snap.metadata.fromCache) {
      //       const user = snap.data();
      //       // Code here
      //       console.log(user);
      //     }
      //   });
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          localStorage.removeItem("user");
          store.dispatch("user/updateUser", {});
          router.push("/login");
          store.dispatch("app/updateAuthenticated", false);
        })
        .catch(error => {
          console.log(error);
          // An error happened.
        });
    },

    async getTime() {
      await api.get("time", {});
    },

    async getTest() {
      let res = await api.get("orders");
      console.log(res);
    }
  }
};
</script>

<style></style>
