var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authenticated)?_c('v-app',{attrs:{"id":"app"}},[_c('div',{staticClass:"note"},[_c('dss-notification-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.messages.length > 0),expression:"messages.length > 0"}]})],1),_c('v-app-bar',{attrs:{"fixed":"","app":"","clipped-left":"","flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Optify")]),_c('v-col',{attrs:{"md":"1"}}),_c('v-menu',{attrs:{"offset-y":"","rounded":"b-xl","close-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"hide-details":"","solo-inverted":"","dark":"","label":"Sök","loading":_vm.loading},on:{"click":function($event){_vm.menu = true},"blur":_vm.closeMenu},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]}}],null,false,2993676632),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.searchItems.length)?_c('v-card',{attrs:{"max-height":"300"}},[_c('v-list',[_c('v-list-item',[_vm._v(_vm._s(_vm.searchItems.length)+" sökresultat")]),_c('v-divider'),_vm._l((_vm.searchItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":("/orders/" + (item.id))}},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"primary","size":"24"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(index + 1))])])],1),_c('v-list-item-title',[_vm._v("ID #"+_vm._s(item.id))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.customer.orgNr))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.customer.companyName))])],1)})],2)],1):_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-alert',{attrs:{"text":"","color":"primary","icon":"mdi-cloud-alert"}},[_vm._v(" Inga sökresultat ")])],1)],1)],1)],1),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}],null,false,2662133037)},[_c('v-card',{attrs:{"width":"400"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"overline"},[_vm._v("Orderhistorik")])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.clearHistory}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-playlist-remove")])],1)]}}],null,false,2000909526)},[_c('span',[_vm._v("Rensa listan")])])],1)],1)],1),_c('v-divider'),(_vm.history.length)?_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.history),function(item){return _c('v-list-item',{key:item.id,attrs:{"to":("/orders/" + (item.id))}},[_c('v-list-item-avatar',[_vm._v(" #"+_vm._s(item.id)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.formatOrgNr(item.customer.orgNr)))]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.customer.companyName)}})],1)],1)}),1):_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-alert',{attrs:{"text":"","type":"warning","icon":"mdi-cloud-alert"}},[_vm._v(" Inga tidigare ordrar öppnade ")])],1)],1)],1)],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{staticClass:"mr-10",attrs:{"color":_vm.newItems > 0 ? 'error' : 'transparent',"offset-x":"20","offset-y":"20","content":_vm.newItems}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-bell-outline")])],1)],1)]}}],null,false,3349085080)},[_c('v-card',{attrs:{"width":"400"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"overline"},[_vm._v("Notifikationer")])],1)],1)],1),_c('v-divider'),(_vm.notifications.length)?_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.notifications),function(item){return _c('v-list-item',{key:item.title},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.message))])],1),(!item.read)?_c('v-list-item-action',[_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v("Nytt")])],1):_vm._e()],1)}),1):_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-alert',{attrs:{"text":"","type":"warning","icon":"mdi-cloud-alert"}},[_vm._v(" Inga notiser hittade ")])],1)],1)],1)],1)],1)],1),_c('dss-navbar'),_c('v-container',{attrs:{"fluid":""}},[_c('v-main',[_c('transition',{attrs:{"name":"fade","mode":"out-in","duration":200}},[_c('router-view')],1)],1)],1)],1):_c('v-app',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('keep-alive',[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }