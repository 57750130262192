import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: 'fa',
  theme: {
    themes: {
      light: {
        darkBackground: '#363636',
        primary: '#14A6A9',
        primary_medium: '#3895c7',
        primary_light: '#d7eaf4',
        accent: '#0ddbc7',
        secondary: '#F4F3F4',
        yellow: '#FED32F',
        error: '#FF5E5B',
        dss_green: '#C8E6C9',
        back: '#fafafa',
        nav: '#5C7685',
        registered: '#C8E6C9',
        success: '#1AC5AB',
      },
      dark: {
        background: '#424242',
        darkBackground: '#363636',
        primary: '#5C7685',
        primary_light: '#d7eaf4',
        accent: '#0ddbc7',
        secondary: '#F4F3F4',
        yellow: '#FED32F',
        error: '#b71c1c',
        dss_green: '#C8E6C9',
        back: '#212121',
      },
    },
  },
});
