<template>
  <div>
    <v-container fluid>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Sök"
        single-line
        hide-details
        solo
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="orders"
        :search="search"
        :loading="true"
        :pagination.sync="pagination"
        rows-per-page-text="Rader per sida"
        class="elevation-1"
      >
        <v-progress-linear
          slot="progress"
          color="primary"
          :indeterminate="loading"
          v-show="loading"
        ></v-progress-linear>
        <template slot="items" slot-scope="props">
          <td class="text-xs-left">{{ props.item.name }}</td>
          <td class="text-xs-left">{{ props.item.org_number }}</td>
          <td class="text-xs-left">{{ date(props.item.created_at) }}</td>
          <td class="justify-center layout px-0">
            <v-btn color="orange" dark small>
              <v-icon :dark="dark" small>fas fa-recycle</v-icon>
            </v-btn>
            <v-btn color="error" dark small>
              <v-icon :dark="dark" small>fas fa-trash</v-icon>
            </v-btn>
          </td>
        </template>
        <template slot="pageText" slot-scope="props">
          {{ props.pageStart }} - {{ props.pageStop }} av
          {{ props.itemsLength }}
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapGetters("app", ["dark"])
  },

  watch: {
    orders() {
      if (this.orders.length > 0) this.loading = false;
    }
  },

  data() {
    return {
      search: "",
      loading: true,
      pagination: {
        descending: true,
        rowsPerPage: 25,
        sortBy: "created_at",
        totalItems: 0
      },
      headers: [
        { text: "Säljare", value: "name", align: "left" },
        { text: "Kund", value: "org_number", align: "left" },
        { text: "Säljdatum", value: "created_at", align: "left" },
        { text: "Åtgärd", value: "edit", align: "left" }
      ],
      orders: []
    };
  },

  methods: {
    trash() {},

    recycle() {},

    orgNumber(number) {
      return number.slice(0, 6) + "-" + number.slice(6);
    },

    date(date) {
      return moment(date).format("YYYY-MM-DD");
    },

    fetchData() {
      store.dispatch("xhr/get", { url: "/api/orders/deleted" }).then(orders => {
        this.orders = orders;
      });
    }
  },

  mounted() {
    this.fetchData();
  }
};
</script>

<style>
table.v-table tbody td:first-child,
table.v-table tbody td:not(:first-child),
table.v-table tbody th:first-child,
table.v-table tbody th:not(:first-child),
table.v-table thead td:first-child,
table.v-table thead td:not(:first-child),
table.v-table thead th:first-child,
table.v-table thead th:not(:first-child) {
  padding: 0 10px;
}
</style>
