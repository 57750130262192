<template>
  <div>
    <v-navigation-drawer fixed app right width="450" v-model="drawer">
      <v-container>
        <v-card tile :loading="saving">
          <v-toolbar flat dense color="transparent" class="mb-3">
            <v-switch
              v-show="!addPackage"
              v-model="archive"
              :value="currentItem.archive ? currentItem.archive : false"
              color="primary"
              hide-details
              inset
              label="Arkiverad"
            ></v-switch>

            <v-spacer></v-spacer>

            <v-btn
              v-if="archive && !currentItem.archive"
              class="ml-4"
              color="error"
              @click="updatePackage('archive')"
              :loading="loading.archive"
              >Arkivera</v-btn
            >
            <v-btn
              v-else-if="currentItem.archive"
              class="ml-4"
              color="success"
              @click="updatePackage('active')"
              :loading="loading.active"
              >Aktivera</v-btn
            >
            <v-btn v-else icon @click="save"
              ><v-icon>mdi-content-save</v-icon></v-btn
            >
            <v-btn icon @click="drawer = !drawer"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-alert text type="error" icon="mdi-cloud-alert" v-if="error">
            {{ errorMessage }}
          </v-alert>
          <v-card
            :disabled="archive || currentItem.archive"
            flat
            color="transparent"
          >
            <v-card :loading="uploading" flat @click="$refs.file.click()">
              <input
                id="fileUpload"
                type="file"
                ref="file"
                style="display: none"
                v-on:change="handleFile"
              />
              <v-img
                class="pointer"
                :src="
                  currentItem.url
                    ? currentItem.url
                    : require('@/assets/placeholder-img.png')
                "
                aspect-ratio="1.8"
                contain
              ></v-img>
            </v-card>
            <v-card flat class="mb-2">
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12" class="pa-2">
                    <v-text-field
                      required
                      outlined
                      dense
                      label="Namn"
                      hide-details
                      v-model="currentItem.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="ml-3">
                    <v-switch
                      v-model="indefinitely"
                      inset
                      label="Gäller tillsvidare"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <v-date-picker
                      locale="sv-se"
                      v-model="currentItem.validTo"
                      full-width
                      :disabled="indefinitely"
                    ></v-date-picker>
                  </v-col>
                  <!-- <v-col cols="5" class="pt-2">
                  <v-menu
                    v-model="menu.date"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="currentItem.indefinitely"
                        outlined
                        dense
                        hide-details
                        label="Giltig till"
                        v-model="currentItem.validTo"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="currentItem.validTo"
                      no-title
                      @input="menu.date = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col> -->
                </v-row>
              </v-container>
            </v-card>

            <v-card flat class="mb-2">
              <v-container>
                <v-form ref="product" v-model="validProduct">
                  <v-row no-gutters>
                    <v-col cols="12" class="pa-2">
                      <v-text-field
                        required
                        hide-details
                        outlined
                        dense
                        label="Namn"
                        v-model="product.name"
                        :rules="rules.name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pa-2">
                      <v-text-field
                        required
                        hide-details
                        outlined
                        dense
                        label="Pris"
                        v-model="product.price"
                        :rules="rules.price"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pa-2">
                      <v-text-field
                        required
                        hide-details
                        outlined
                        dense
                        label="Artikelnummer"
                        v-model="product.article_nr"
                        :rules="rules.article_nr"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="addProduct" text>
                    Lägg till
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card>

            <v-list-item-group>
              <v-list-item
                v-for="item in currentItem.products"
                :key="item.name"
              >
                <v-list-item-icon>
                  <v-btn icon @click="deleteProduct(item)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle class="text--primary"
                    >{{ item.price }} kr</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    v-text="item.subtitle"
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text
                    >Artickelnummer</v-list-item-action-text
                  >
                  {{ item.article_nr }}
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-card>
        </v-card>
      </v-container>
    </v-navigation-drawer>
    <v-card>
      <v-toolbar color="transparent" flat>
        <v-col xs="12" md="3">
          <v-text-field
            clearable
            dense
            v-model="search"
            label="Sök paket..."
            single-line
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col xs="12" md="2">
          <v-select
            v-model="activeFilter"
            :items="filterUsers"
            dense
            single-line
            hide-details
            outlined
          ></v-select>
        </v-col>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar color="transparent" flat>
        <v-spacer></v-spacer>
        <v-btn class="mr-3" @click="add" color="primary">
          <v-icon left>
            mdi-pencil
          </v-icon>
          Nytt paket
        </v-btn>
        <!-- <v-toolbar-items>
          <v-btn-toggle color="primary" group v-model="filterType">
            <v-btn value="active">
              Aktiva
            </v-btn>
            <v-btn value="archived">
              Arkiverade
            </v-btn>
          </v-btn-toggle>
        </v-toolbar-items> -->
      </v-toolbar>
      <v-container fluid>
        <v-data-table
          :headers="headers"
          :items="filteredPackages"
          :items-per-page="5"
          @click:row="setCurrentPackage"
        >
          <template #[`item.url`]="{item}">
            <v-avatar>
              <img :src="item.url" />
            </v-avatar>
          </template>
          <template #[`item.validTo`]="{item}">
            <v-chip color="primary">{{
              item.validTo === ""
                ? "Gäller tillsvidare"
                : formatDate(item.validTo, "YYYY-MM-DD")
            }}</v-chip>
            <!-- <v-switch inset :value="item.active" v-model="item.active"></v-switch> -->
          </template>
          <template #[`item.products`]="{item}">
            <v-avatar size="32" class="success white--text">
              {{ item.products.length }}
            </v-avatar>
          </template>
        </v-data-table>
        <!-- <v-row>
        <v-col
          sm="12"
          md="4"
          v-for="(pack, i) in filteredPackages"
          :key="pack.name + i"
        >
          <v-card @click="setCurrentPackage(pack)">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5" v-text="pack.name"></v-card-title>

                <v-card-subtitle>{{ getText(pack) }}</v-card-subtitle>
                <v-card-text class="text--primary">
                  <v-chip class="ma-2" color="primary" text-color="white">
                    <v-avatar left class="green darken-4">
                      {{ pack.products.length }}
                    </v-avatar>
                    {{ pack.products.length > 1 ? "produkter" : "produkt" }}
                  </v-chip>
                  <div>{{ pack.products.length }}st produkter</div>
                </v-card-text>
              </div>

              <v-avatar class="ma-3" size="125" tile>
                <v-img :src="pack.url" aspect-ratio="0.9"></v-img>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row> -->
      </v-container>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import moment from "moment";
import api from "../clasess/api";

export default {
  computed: {
    // ...mapGetters("package", ["packages"]),
    ...mapGetters("user", ["user"]),

    filteredFiles() {
      return this.files.filter(file => file.path.includes("package"));
    },

    fileSelected() {
      return Object.keys(this.file).length > 0;
    },

    filteredPackages() {
      if (this.packages.length) {
        let packages = this.packages;

        if (this.search !== "" && this.search !== null)
          packages = packages.filter(pack => pack.name.includes(this.search));

        if (this.activeFilter === "Aktiva")
          packages = packages.filter(pack => !pack.archive);
        if (this.activeFilter === "Inaktiva")
          packages = packages.filter(pack => pack.archive);

        return packages;
      } else return [];
    }

    // filteredPackages() {
    //   if (this.filterType === "active")
    //     return this.packages.filter(el => !el.archive);
    //   else return this.packages.filter(el => el.archive);
    // }
  },

  watch: {
    panel() {
      if (this.panel != undefined) this.rm = false;
      else this.rm = true;
    },
    archivedActive() {
      if (this.archivedActive === "archived") {
        // api.get('packages/archived').then((res) => {
        //   this.archivedPackages = res;
        // });
      }
    }
  },

  data() {
    return {
      indefinitely: false,
      packages: [],
      search: "",
      headers: [
        { text: "", value: "url" },
        { text: "Namn", value: "name" },
        { text: "Gäller till", value: "validTo" },
        { text: "Produkter", value: "products" }
      ],
      loading: {
        active: false,
        archive: false
      },
      activeFilter: "Aktiva",
      filterUsers: ["Aktiva", "Inaktiva"],
      archive: false,
      archiveing: false,
      activating: false,
      filterType: "active",
      upploadNewFile: false,
      error: false,
      errorMessage: "",
      uploading: false,
      addPackage: false,
      saving: false,
      archivedActive: "active",
      archivedPackages: [],
      files: [],
      file: null,
      checkbox: false,
      length: 3,
      onboarding: 0,
      overlay: false,
      items: [
        { title: "Home", icon: "dashboard" },
        { title: "About", icon: "question_answer" }
      ],
      drawer: false,
      rules: {
        package: {
          name: [v => !!v || "Namn måste vara ifyllt"]
        },
        name: [v => !!v || "Namn måste vara ifyllt"],
        price: [v => !!v || "Pris måste vara ifyllt"],
        article_nr: [v => !!v || "Artikelnummer måste vara ifyllt"]
      },
      validProduct: true,
      validPackage: true,
      addImage: false,
      menu: {
        date: false,
        addImage: false
      },
      date: "",
      // headers: [
      //   { text: "Namn", value: "name" },
      //   { text: "Pris", value: "price" },
      //   { text: "Artikelnummer", value: "article_nr" },
      //   { text: "Radera", value: "delete" }
      // ],
      update: false,
      product: {
        name: "",
        price: "",
        articleNr: ""
      },
      rm: true,
      panel: undefined,
      newPanel: undefined,
      show: false,
      showProduct: false,
      currentItem: {
        url: require("@/assets/placeholder-img.png"),
        name: "",
        validTo: "",
        products: []
      },
      newPackage: {
        name: "",
        products: []
      }
    };
  },

  methods: {
    async uploadAndSave() {
      let { count } = await api.get("packages/count");
      let id = !this.currentItem.id ? `${count + 1}` : this.currentItem.id;

      //console.log(count);
      return await api.upload({
        filename: `${id}`,
        path: `packages/`,
        file: this.file
      });
    },

    handleFile() {
      console.log("Handeling file...");
      const reader = new FileReader();
      let file = this.$refs.file.files[0];
      this.file = file;
      let that = this;
      reader.onload = function(e) {
        that.currentItem.url = e.target.result;
        console.log(that.currentItem);
      };
      reader.readAsDataURL(file);
      //this.currentItem.url = file.url;
      this.upploadNewFile = true;
      console.log("After read as URL");
      //this.currentItem.url = this.$refs.file.files[0];
      //console.log(this.file);
      // this.uploadAndSave();
    },

    async updatePackage(process) {
      console.log(process);
      this.loading[process] = true;

      if (process === "active") {
        api.deleteField("packages", {
          item: this.currentItem,
          field: "archive"
        });
        this.filterType = process;
      } else if (process === "archive") {
        api.update("packages", {
          id: this.currentItem.id,
          field: { archive: true }
        });
      }
      this.currentItem = {};
      this.loading[process] = false;
      this.drawer = false;

      store.dispatch("user/addMessage", {
        body: `${this.currentItem.name} är nu updaterad`,
        type: "success",
        messageType: "message"
      });
    },

    getText(item) {
      if (!item.indefinitely)
        return `Gilltig till ${this.formatDate(item.validTo)}`;
      else return "Gilltig tillsvidare";
    },

    setCurrentImg(img) {
      this.file = null;
      document.getElementById("fileUpload").value = "";
      this.currentItem.new = false;
      this.currentItem.url = img.url;
      this.addImage = false;
    },

    setCurrentPackage(pack) {
      this.newPackage = false;
      this.currentItem = this.deepCopyObject(pack);
      if (!pack.url) this.currentItem.url = null;
      // if (this.currentItem.validTo == null) {
      //   this.currentItem.validTo = Date.now();
      // }
      if (this.currentItem.validTo !== "") {
        this.currentItem.validTo = moment(this.currentItem.validTo).format(
          "YYYY-MM-DD"
        );
        this.indefinitely = false;
      } else this.indefinitely = true;
      // pack.indefinitely === 0
      //   ? (this.currentItem.indefinitely = false)
      //   : (this.currentItem.indefinitely = true);
      // if (pack.indefinitely === 0) this.currentItem.indefinitely = false;
      // else this.currentItem.indefinitely = true;
      this.drawer = true;
    },

    formatDate(date) {
      if (date != null) return moment(date).format("D MMM YYYY");
    },

    async save() {
      if (this.currentItem.products.length && this.currentItem.name !== "") {
        this.saving = true;
        if (this.upploadNewFile) {
          this.currentItem.url = await this.uploadAndSave();
        }
        if (!this.currentItem.id) {
          if (this.currentItem.validTo !== "")
            this.currentItem.validTo = moment(this.currentItem.validTo).format(
              "YYYY-MM-DD HH:mm:ss"
            );

          let { packages } = await api.put(
            "packages",
            this.deepCopyObject(this.currentItem)
          );
          this.packages = packages;
          this.drawer = false;
          this.saving = false;
          store.dispatch("user/addMessage", {
            body: this.currentItem.name + " sparad!",
            type: "success",
            messageType: "message"
          });
          this.addPackage = false;
        } else {
          await api.post("packages", this.currentItem);
          this.getAllPackages();
          store.dispatch("user/addMessage", {
            body: this.currentItem.name + " updaterad!",
            type: "success",
            messageType: "message"
          });
          this.drawer = false;
          this.saving = false;
          this.addPackage = false;
        }
      } else {
        this.error = true;
        if (this.currentItem.name === "") {
          this.errorMessage = "Paketet måste ha ett namn";
        } else if (!this.currentItem.products.length) {
          this.errorMessage = "Inga produkter tillagda";
        }
      }

      this.update = false;
      this.saving = false;
    },

    add() {
      this.currentItem = {
        url: require("@/assets/placeholder-img.png"),
        name: "",
        products: [],
        validTo: ""
      };
      this.error = false;
      this.drawer = !this.drawer;
      this.addPackage = true;
    },

    addProduct() {
      if (this.$refs.product.validate()) {
        let newProduct = Object.assign({}, this.product);
        this.currentItem.products.push(newProduct);
        this.product = {};
        this.$refs.product.resetValidation();
      }
    },

    deleteProduct(item) {
      const index = this.currentItem.products.indexOf(item);
      this.currentItem.products.splice(index, 1);
      this.showProduct = false;
    },

    generatePackage() {
      this.update = true;
      this.currentItem = {
        name: "Testpaket",
        validTo: "2019-07-30",
        products: [
          { name: "Ett", price: 3456, article_nr: "Edd123" },
          { name: "Två", price: 4523, article_nr: "Reff544" },
          { name: "Tre", price: 5434, article_nr: "Tr44456" }
        ]
      };
    },

    async getAllPackages() {
      let { packages } = await api.get("packages", {});
      this.packages = packages;
    }
  },

  mounted() {
    this.getAllPackages();
    // store.dispatch("package/getPackages");
  }
};
</script>

<style>
.dss-item {
  border-left: 5px solid white;
}
.dss-item-active {
  border-left: 5px solid black;
}
.pointer {
  cursor: pointer !important;
}
</style>
