<template>
  <v-container fluid>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-stepper v-model="e1" flat elevation="0">
      <v-stepper-header flat>
        <v-stepper-step :complete="e1 > 1" step="1">
          Kundinformation
          <small>Alert message</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">
          Sälj
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3">
          Orderinformation
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">
          Översikt
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-12" flat>
            <o-customer-info ref="customer"></o-customer-info>
          </v-card>

          <v-btn color="primary" @click="next(1)">
            Nästa
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-12" flat>
            <o-sales-products></o-sales-products>
          </v-card>
          <v-btn text @click="e1 = 1">
            Tillbaka
          </v-btn>
          <v-btn color="primary" @click="next(2)">
            Nästa
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-12">
            <o-order-information></o-order-information>
          </v-card>
          <v-btn text @click="e1 = 2">
            Tillbaka
          </v-btn>
          <v-btn color="primary" @click="next(3)">
            Nästa
          </v-btn>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-card class="mb-12">
            <o-finish-sale></o-finish-sale>
          </v-card>
          <v-btn text @click="e1 = 3">
            Tillbaka
          </v-btn>
          <v-btn color="primary" @click="createOrder">
            Skapa order
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import CustomerInfo from "../../components/steps/Customerinfo";
import SalesAndProducts from "@/components/steps/SalesAndProducts";
import Orderinformation from "@/components/steps/Orderinformation";
import FinishSale from "@/components/steps/FinishSale";

import store from "@/store";
import { mapGetters } from "vuex";
import api from "../../clasess/api";

export default {
  components: {
    "o-customer-info": CustomerInfo,
    "o-sales-products": SalesAndProducts,
    "o-order-information": Orderinformation,
    "o-finish-sale": FinishSale
  },

  computed: {
    ...mapGetters("order", ["order", "customer"]),
    ...mapGetters("user", ["users"])
  },

  data() {
    return {
      overlay: false,
      steps: ["Kundinformation", "Sälj", "Orderinformation", "Översikt"],
      e1: 1
    };
  },

  methods: {
    async createOrder() {
      if (this.order.tb === "") {
        store.dispatch("user/addMessage", {
          body: `Fyll i TB för att spara order`,
          type: "warning",
          messageType: "message"
        });
      } else {
        this.overlay = true;
        this.order.customer.orgNr = this.order.customer.orgNr.replace("-", "");
        console.log(this.order);
        //this.order = this.trimOrder(this.order);
        let { id } = await api.put("orders", this.deepCopyObject(this.order));
        // await store.dispatch(
        //   'firestore/saveOrder',
        //   this.deepCopyObject(this.order),
        // );
        this.overlay = false;
        this.resetOrder();
        store.dispatch("user/addMessage", {
          body: `Order med id #${id} skapad`,
          type: "success",
          messageType: "message"
        });
      }
    },

    resetOrder() {
      localStorage.removeItem("order");
      store.dispatch("order/resetOrder");
      this.e1 = 1;
    },

    next(step) {
      if (step === 1) {
        if (this.$refs.customer.$refs.form.validate()) {
          this.e1 = step + 1;
        }
      } else if (step === 2) {
        let notDone = [];
        let done = 0;
        let noPhone = false;
        this.order.sales.forEach(sale => {
          if (sale.phoneNumber === "") noPhone = true;
          if (sale.products) {
            done++;
          } else {
            notDone.push(sale.id);
          }
        });
        if (
          done === this.order.sales.length &&
          this.order.sales.length > 0 &&
          !noPhone
        ) {
          // this.order.sales.forEach(sale => {
          //   this.menu[sale.id] = false;
          // });
          // this.steps[this.currentStep - 1].done = true;
          this.e1 = step + 1;
        } else {
          if (this.order.sales.length < 1) {
            store.dispatch("user/addMessage", {
              body: "Inget sälj inlagt",
              type: "error",
              messageType: "message"
            });
          } else if (notDone.length > 0) {
            store.dispatch("user/addMessage", {
              body: `Någon rad har ingen vald produkt`,
              type: "error",
              messageType: "message"
            });
          } else if (noPhone) {
            store.dispatch("user/addMessage", {
              body: `Fyll i telefonnummer på alla rader`,
              type: "error",
              messageType: "message"
            });
          }
        }
      } else if (step === 3) {
        let count = 0;

        this.order.sales.forEach(sale => {
          if (sale.time !== undefined) {
            count++;
          }
        });
        if (this.order.sales.length > count) {
          store.dispatch("user/addMessage", {
            body: "Bindningstid är inte vald för alla abonnemang",
            type: "error",
            messageType: "message"
          });
        } else {
          if (
            this.order.agreement.type.name.includes("Ingen förändring") &&
            this.order.info === ""
          ) {
            store.dispatch("user/addMessage", {
              body: 'Är "Ingen förändring" vald måste övrigtrutan vara ifylld',
              type: "warning",
              messageType: "message"
            });
          } else {
            this.e1 = step + 1;
          }
        }
      }
    }
  },

  mounted() {
    store.dispatch("app/refreshStaticData");
  }
};
</script>

<style>
.theme--light.v-stepper .v-stepper__header .v-divider {
  border-width: 2px !important;
  border-color: #90a4ae !important;
}
.v-stepper__header {
  height: 65px !important;
  -webkit-box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%) !important;
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 20%), 0 0px 2px 0 rgb(0 0 0 / 14%),
    0 1px 0px 0 rgb(0 0 0 / 12%) !important;
}
</style>
