import moment from 'moment';
//import store from "@/store";

export default {
  methods: {
    formatOrgNr(nr) {
      return nr.substring(0, 6) + '-' + nr.substring(6, 10);
    },

    formatDate(date, format) {
      return moment(date).format(format);
    },
  },
};
