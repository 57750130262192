import axios from "axios";
import router from "@/router";

const state = {
  webroot: "api/",
  authenticated: false,
  loading: false,
  loadingText: "Loggar in ...",
};

const getters = {
  authenticated: (state) => {
    return state.authenticated;
  },

  loading: (state) => {
    return state.loading;
  },

  loadingText: (state) => {
    return state.loadingText;
  },
};

const mutations = {
  setAuthenticated(state, value) {
    state.authenticated = value;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  setLoadingText(state, text) {
    state.loadingText = text;
  },
};

const actions = {
  authenticated({ commit }, value) {
    commit("setAuthenticated", value);
  },

  login({}, credentials) {
    return axios.post("/api/auth/login", credentials);
  },

  loading({ commit }, value) {
    commit("setLoading", value);
  },

  loadingText({ commit }, text) {
    commit("setLoadingText", text);
  },

  checkJwt({}, url) {
    return axios.get(url);
  },

  get({ state, commit }, payload) {
    return axios
      .get(state.webroot + payload.url, { params: payload.params })
      .then((res) => {
        if (res.data.verified) {
          return res.data;
        } else {
          commit("setAuthenticated", false);
          router.push("/login");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            commit("setAuthenticated", false);
            router.push("/login");
          }
        }
      });
  },

  post({}, payload) {
    return axios.post("api/" + payload.url, payload.params);
  },

  put({}, payload) {
    return axios.put("api/" + payload.url, payload.params);
  },

  delete({}, payload) {
    return axios.delete("api/" + payload.url, { data: payload.params });
  },

  fetchData({}, data) {
    return axios[data.method]("api/" + data.url, data.credentials);
    //return axios[data.method](data.url, data.credentials).then(res => result = res);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
