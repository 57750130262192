<template>
  <div>
    <v-row>
      <v-col cols="5">
        <v-card color="transparent" flat>
          <v-container>
            <v-text-field
              solo
              v-model="order.tb"
              suffix="TB"
              label="TB för order"
              hide-details
              type="number"
            ></v-text-field>
            <v-card-text>
              <v-list-item-title>Kundinformation</v-list-item-title>
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-subtitle>Orgnummer: </v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    formatOrgNr(order.customer.orgNr)
                  }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>Företagsnamn: </v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    order.customer.companyName
                  }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>Adress: </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ order.customer.street }}
                    <br />
                    {{ order.customer.zip }} {{ order.customer.city }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
              <v-divider class="mt-3 mb-3"></v-divider>

              <v-list-item-title>Firmatecknare</v-list-item-title>
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-subtitle>Namn: </v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    order.customer.signatory.name
                  }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>Personnummer: </v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    order.customer.signatory.personalNumber
                  }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>Telefonnummer: </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ order.customer.signatory.phoneNumber }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>E-post: </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ order.customer.signatory.email }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="7">
        <div style="overflow: scroll; height: 70vh">
          <v-timeline dense clipped align-top v-if="order.sales.length">
            <v-timeline-item hide-dot>
              <div class="subtitle-1 black--text">Säljrader</div>
            </v-timeline-item>
            <v-timeline-item
              class="mb-3"
              color="grey"
              icon-color="grey lighten-5"
              small
              v-for="sale in order.sales"
              :key="sale.id"
            >
              <v-card>
                <v-layout wrap justify-space-between>
                  <v-flex xs8>
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ sale.type }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ sale.subscription }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ sale.discount }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="sale.operator != ''">
                          Portering från {{ sale.operator }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          class="text-subtitle-1"
                          v-if="sale.phoneNumber != ''"
                        >
                          {{ sale.phoneNumber }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-flex>

                  <v-flex xs4>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ sale.contractPeriod }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Pristillägg: {{ sale.surcharge }}
                        </v-list-item-subtitle>

                        <v-list-item-title
                          v-if="
                            sale.transfer.name !== null ||
                              sale.transfer.name !== ''
                          "
                        >
                          Överlåtelse
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="
                            sale.transfer.name !== null ||
                              sale.transfer.name !== ''
                          "
                        >
                          {{ sale.transfer.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ sale.transfer.orgNumber }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-flex>
                  <v-flex xs12>
                    <v-simple-table
                      v-if="sale.products !== null && sale.products.length > 0"
                    >
                      <tbody>
                        <tr
                          v-for="item in sale.products"
                          :key="item.article_nr"
                        >
                          <td>{{ item.name }}</td>
                          <td>{{ item.article_nr }}</td>
                          <td>{{ item.price }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("order", ["order", "customer"])
  }
};
</script>
