<template>
  <div>
    <quill ></quill>
    <v-row>
      <v-col sm="3">
        <v-navigation-drawer absolute color="back" width="20%" permanent>
          <v-list dense>
            <v-list-item>
              <v-list-item-title>Sidor</v-list-item-title>
              <v-list-item-action>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" small v-bind="attrs">
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in items" :key="index">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>

            <v-list-group
              no-action
              sub-group
              v-for="(item, i) in data"
              :key="i"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item v-for="(item, i) in item.children" :key="i" link>
                <v-list-item-title v-text="item.name"></v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="icon" small></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>

            <!-- <v-list-group no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Actions</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="([title, icon], i) in cruds"
            :key="i"
            link
            class="activeItem"
          >
            <v-list-item-title v-text="title"></v-list-item-title>

            <v-list-item-icon>
              <v-icon v-text="icon" small></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group> -->
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col sm="8">
        <h2>Test</h2>
        <dss-editor></dss-editor>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Editor from "../components/Editor";
export default {
  components: {
    "dss-editor": Editor,
  },

  data: () => ({
    data: [
      {
        type: "folder",
        name: "Test 1",
        children: [
          {
            type: "page",
            name: "test page 1",
            children: [],
          },
          {
            type: "page",
            name: "test page 2",
            children: [],
          },
        ],
      },
      {
        type: "folder",
        name: "Test 2",
        children: [
          {
            type: "page",
            name: "test page 3",
            children: [],
          },
          {
            type: "page",
            name: "test page 4",
            children: [],
          },
        ],
      },
    ],
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
  }),
};
</script>

<style>
.activeItem {
  margin-left: 5px;
  border-left: 5px solid #0ddbc7;
}
</style>
