<template>
  <editor ref="editor" :config="config" :initialized="onInitialized" />
</template>

<script>
//import EditorJS from "@editorjs/editorjs";
//import Editor from "vue-editor-js";
//import Header from "@editorjs/header";

export default {
  // components: {
  //   Editor,
  // },

  data() {
    return {
      config: {
        image: {
          // Like in https://github.com/editor-js/image#config-params
          endpoints: {
            byFile: 'http://localhost:8090/image',
            byUrl: 'http://localhost:8090/image-by-url',
          },
          field: 'image',
          types: 'image/*',
        },
      },
    };
  },
};
</script>
