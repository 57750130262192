<template>
  <v-card>
    <v-navigation-drawer v-model="historyDawer" absolute temporary right>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title>Foo</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Bar</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Fizz</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Buzz</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar color="secondary">
      <v-toolbar-items>
        <v-btn @click="closeDialog" text><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar-items>
      <v-col xs="12" md="2">
        <v-select
          solo
          :value="agreement.name"
          :items="agreements"
          label="Avtal"
          item-text="name"
          hide-details
        ></v-select>
      </v-col>

      <!-- 
      <v-tabs v-model="tab" centered>
        <v-tab>Typer - Abonnemang</v-tab>
        <v-tab>Användare - Bindningstid</v-tab>
      </v-tabs> -->

      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon @click="saveData" :loading="loading"
          ><v-icon>mdi-content-save-outline</v-icon></v-btn
        >
        <v-btn icon @click="historyDawer = !historyDawer"
          ><v-icon>mdi-history</v-icon></v-btn
        >
      </v-toolbar-items>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col xs="12" md="2">
          <v-sheet>
            <v-sheet class="pa-3 text-right">
              <v-select
                solo
                v-model="selectedTypes"
                item-text="name"
                multiple
                hide-details
                :items="types"
                placeholder="Typer"
                return-object
              >
                <template v-slot:selection="data">{{
                  data.index === 0 ? 'Typer' : ''
                }}</template>
                <template v-slot:append-outer>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="toggleTypes"
                      >
                        <v-icon class="mb-2">{{
                          selectedTypes.length !== types.length
                            ? 'mdi-book-plus-multiple'
                            : 'mdi-book-minus-multiple'
                        }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Lägg till alla typer</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-sheet>
          </v-sheet>
          <v-item-group
            multiple
            v-model="selectedTypes"
            return-object
            :value="selectedTypes"
            dense
            rounded
          >
            <draggable
              class="drag-cursor"
              v-bind="dragOptions"
              @start="drag = false"
              @end="drag = false"
              :group="{ name: 'types' }"
              :list="selectedTypes"
            >
              <v-card v-for="(n, i) in selectedTypes" :key="n.id + n.name">
                <v-row no-gutters>
                  <v-list-item
                    dense
                    :dark="type.name === n.name"
                    selected
                    :class="type && type.name === n.name ? 'primary' : ''"
                  >
                    <v-list-item-avatar>
                      <v-icon small>
                        mdi-drag-vertical
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content @click="setType(n)">
                      <v-list-item-title v-text="n.name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip
                        small
                        :class="
                          type && type.name === n.name
                            ? 'primary_light black--text'
                            : 'primary white--text'
                        "
                        >{{ i + 1 }}</v-chip
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-row>
              </v-card>
            </draggable>
          </v-item-group>
        </v-col>
        <!-- <v-col v-for="n in 40" :key="n" cols="12" md="2" class="ma-1">
          <v-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'accent' : ''"
              class="d-flex align-center"
              height="70"
              width="100"
              @click="toggle"
            >
              <v-scroll-y-transition>
                <div
                  v-if="active"
                  class="overline flex-grow-1 text-center white--text"
                >
                  {{ n * 10 }} kr
                </div>
                <div v-else class="overline flex-grow-1 text-center">
                  {{ n * 10 }} kr
                </div>
              </v-scroll-y-transition>
              <v-card-subtitle class="overline flex-grow-1 text-center">
                {{ n }}
              </v-card-subtitle>
            </v-card>
          </v-item>
        </v-col> -->
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

export default {
  props: ['agreement'],

  components: {
    draggable,
  },

  computed: {
    ...mapGetters('app', [
      'subscriptions',
      'surcharges',
      'discounts',
      'agreementUsers',
      'agreementPeriods',
      'agreements',
      'types',
      'subscriptions',
    ]),

    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
      };
    },
  },

  data() {
    return {
      historyDawer: false,
      filterTypes: false,
      edited: false,
      loading: false,
      tab: null,
      selectedTypes: [],
      type: '',
    };
  },

  methods: {
    closeDialog() {
      this.$parent.$parent.$parent.dialog = false;
      //this.resetData();
    },

    saveData() {
      console.log('Saving data...');
    },

    setType(type) {
      this.type = type;
      if (!this.type.subs) this.type.subs = [];
      else this.selectedSubs = type.subs;

      this.history.subs = this.deepCopyObject(this.type.subs);
      this.sub = {};
      this.selectedSurcharges = [];
      this.selectedDiscounts = [];
    },

    toggleTypes() {
      if (
        (this.selectedTypes && this.selectedTypes.length) === this.types.length
      ) {
        this.selectedTypes = [];
      } else {
        this.types.forEach((type) => {
          let arr = this.selectedTypes.filter((j) => j.name === type.name);
          if (arr.length < 1) this.selectedTypes.push(type);
        });
      }
    },
  },
};
</script>
