import { db } from '../../firebase/init';

const state = {
  packages: [],
  unsubscribe: null,
};

const getters = {
  packages: (state) => {
    return state.packages;
  },
};

const mutations = {};

const actions = {
  getPackages({ state }) {
    state.unsubscribe = db
      .collection('packages')
      .onSnapshot((querySnapshot) => {
        state.packages = [];
        querySnapshot.forEach((doc) => {
          state.packages.push(doc.data());
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
