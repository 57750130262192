<template>
  <div>
    <v-dialog v-model="dialog" no-click-animation width="600" height="700">
      <v-card width="600">
        <v-card-actions>
          <v-btn text @click="reset">avbryt</v-btn>
          <v-spacer></v-spacer>

          <v-btn icon @click="saveUser">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <!-- <v-btn block @click="generateFields"
                                  >Generate fields</v-btn
                                > -->
                  <v-text-field
                    label="Namn"
                    filled
                    hide-details
                    v-model="newUser.name"
                    required
                    :rules="rules.name"
                  ></v-text-field>
                </v-list-item-title>
                <v-list-item-title>
                  <v-text-field
                    label="Personnummer"
                    filled
                    hide-details
                    v-model="newUser.personal_number"
                    required
                  ></v-text-field>
                </v-list-item-title>
                <v-list-item-title>
                  <v-text-field
                    label="E-post"
                    filled
                    hide-details
                    v-model="newUser.email"
                    required
                    :rules="rules.email"
                  ></v-text-field>
                </v-list-item-title>
                <v-list-item-title>
                  <v-select
                    :items="roles"
                    filled
                    label="Välj roll"
                    item-text="name"
                    v-model="newUser.role"
                    hide-details
                    required
                  ></v-select>
                </v-list-item-title>
                <v-list-item-title>
                  <v-file-input
                    @change="file"
                    label="Välj profilbild"
                    filled
                    prepend-icon=""
                    hide-details
                    required
                  ></v-file-input>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="error">
              <v-alert
                width="100%"
                border="left"
                colored-border
                type="error"
                elevation="2"
              >
                {{ errorMessage }}
              </v-alert>
            </v-list-item>
            <v-list-item>
              <v-card flat class="mx-auto" :loading="saving" @click="reset">
                <v-img
                  :src="
                    newUser.img.url !== ''
                      ? newUser.img.url
                      : require('@/assets/profile.png')
                  "
                ></v-img>
              </v-card>
            </v-list-item>
          </v-list>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- <v-btn @click="syncUsersToServer">Get firebase users</v-btn> -->
    <v-card>
      <!-- <v-card-title>
        <v-toolbar color="transparent" flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            solo
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-switch inset></v-switch>
        </v-toolbar>
      </v-card-title> -->
      <v-toolbar color="transparent" flat>
        <v-col xs="12" md="3">
          <v-text-field
            clearable
            dense
            v-model="search"
            label="Sök användare..."
            single-line
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col xs="12" md="3">
          <v-select
            v-model="role"
            :items="roles"
            dense
            single-line
            hide-details
            outlined
            label="Välj roll"
            item-text="name"
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col xs="12" md="2">
          <v-select
            v-model="activeFilter"
            :items="filterUsers"
            dense
            single-line
            hide-details
            outlined
          ></v-select>
        </v-col>
        <v-col xs="12" md="1">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="clearAllFilter"
                ><v-icon>mdi-filter-remove-outline</v-icon></v-btn
              >
            </template>
            <span>Rensa filter</span>
          </v-tooltip>
        </v-col>
      </v-toolbar>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="filteredUsers"
        dense
        class="row-pointer"
        @click:row="setCurrentUser"
        :items-per-page="15"
      >
        <template v-slot:top>
          <v-toolbar color="transparent" flat>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="mr-5"
              ><v-icon left>mdi-plus</v-icon>Ny användare</v-btn
            >
          </v-toolbar>
        </template>
        <template #[`item.active`]="{item}">
          <v-chip small :color="item.active ? 'success' : 'error'">{{
            item.active ? "Aktiv" : "Inaktiv"
          }}</v-chip>
          <!-- <v-switch inset :value="item.active" v-model="item.active"></v-switch> -->
        </template>
        <!-- <template #[`item.name`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'user-view', params: { id: item.id } }"
          >
            #{{ item.name }}
          </router-link>
        </template> -->
        <template #[`item.img`]="{item}">
          <v-avatar size="32" class="mt-1 mb-1">
            <v-img
              :aspect-ratio="1"
              :src="
                item.img.url !== ''
                  ? item.img.url
                  : require('@/assets/profile.png')
              "
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    :width="1"
                    indeterminate
                    color="grey lighten-1"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </template>
      </v-data-table>
    </v-card>

    <!-- <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Name
            </th>
            <th class="text-left">
              E-post
            </th>
            <th class="text-left">
              Roll
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in users" :key="item.email">
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.role }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
    <!-- <v-card class="mt-15">
      <v-btn v-if="firebaseUsers.length !== 0" @click="syncUsers">Sync</v-btn>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                E-post
              </th>
              <th class="text-left">
                Roll
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in firebaseUsers" :key="item.uid">
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.role }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card> -->
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import api from "../clasess/api";
import { db } from "../firebase/init";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("app", ["roles"]),
    // filteredUsers() {
    //   if (this.search !== "" && this.search !== null)
    //     return this.users.filter(user => user.name.includes(this.search));
    //   return this.users;
    // }
    filteredUsers() {
      let users = this.users;

      if (this.search !== "" && this.search !== null)
        users = users.filter(user => user.name.includes(this.search));

      if (this.activeFilter === "Aktiva")
        users = users.filter(user => user.active);
      if (this.activeFilter === "Inaktiva")
        users = users.filter(user => !user.active);

      if (this.role !== "") {
        users = users.filter(
          user => user.role.toLowerCase() === this.role.toLowerCase()
        );
      }

      return users;
    }
  },

  data() {
    return {
      role: "",
      valid: true,
      rules: {
        name: [v => !!v || "Name is required"],
        email: [
          v => !!v || "E-mail is required",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ]
      },
      saving: false,
      error: false,
      errorMessage: "Något fält är inte ifyllt",
      dialog: false,
      users: [],
      search: "",
      activeFilter: "Alla",
      firebaseUsers: [],
      filterUsers: ["Alla", "Aktiva", "Inaktiva"],
      headers: [
        { text: "Foto", value: "img" },
        { text: "Namn", value: "name" },
        { text: "E-post", value: "email" },
        { text: "Roll", value: "role" },
        { text: "Aktiv", value: "active" }
      ],
      newUser: {
        img: { url: "" },
        name: "",
        email: "",
        role: "Säljare",
        active: true
      }
    };
  },

  methods: {
    clearAllFilter() {
      this.search = "";
      this.role = "";
      this.activeFilter = "Alla";
    },

    reset() {
      this.error = false;
      this.errorMessage = "";
      this.newUser = {
        img: { url: "" },
        name: "",
        email: "",
        role: "Säljare",
        active: true
      };
    },

    async saveUser() {},

    file(file) {
      if (file) {
        this.profilePicture = file;
        this.addedFile = false;
        const reader = new FileReader();
        let that = this;
        reader.onload = function(e) {
          that.addedFile = true;
          //file.url = e.target.result;
          //file.percent = 0;
          that.newUser.img.url = e.target.result;
          //that.filesToUpload.push(file);
          //that.total += file.size;
        };
        reader.readAsDataURL(file);
      } else {
        this.addedFile = false;
        this.newUser.img.url = "";
      }
    },

    setCurrentUser(user) {
      //store.dispatch("user/updateUser", user);
      router.push(`users/${user.uid}`);
    },

    async getUsers() {
      let { users } = await api.get("users", {});
      this.users = users;
    },

    async syncUsers() {
      this.firebaseUsers.forEach(async user => {
        let { message } = await api.put("users/newUser", { user: user });
        console.log(message);
      });
    },

    async syncUsersToServer() {
      let snapshots = await db.collection("users").get();
      snapshots.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        this.firebaseUsers.push(doc.data());
      });
    }
  },

  mounted() {
    this.getUsers();
    if (!this.roles.length) store.dispatch("app/refreshStaticData");
  }
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.selected {
  background-color: #eeeeee;
}
</style>
