import api from '../../clasess/api';

const state = {
  ordersEditing: {},
  selectedOrder: {},
  orders: [],
  sale: undefined,
  order: {
    external: '',
    invoice: { amount: 0, paid: false },
    finalInvoice: { amount: 0, paid: false },
    file: [],
    info: '',
    tb: '',
    agreement: {
      type: { name: '', id: '' },
      period: { id: '', name: '' },
      users: { id: '', name: '' },
    },
    sales: [],
    customer: {
      companyName: '',
      orgNr: '',
      zip: '',
      street: '',
      city: '',
      signatory: {
        name: '',
        email: '',
        personalNumber: '',
        phoneNumber: '',
      },
    },
    noFiles: false,
    noFile: false,
    comments: [],
    status: 'OBEHANDLAD',
  },
  emptyOrder: {
    external: '',
    invoice: { amount: 0, paid: false },
    finalInvoice: { amount: 0, paid: false },
    file: [],
    info: '',
    tb: '',
    agreement: {
      type: { name: '', id: '' },
      period: { id: '', name: '' },
      users: { id: '', name: '' },
    },
    sales: [],
    customer: {
      companyName: '',
      orgNr: '',
      zip: '',
      street: '',
      city: '',
      signatory: {
        name: '',
        email: '',
        personalNumber: '',
        phoneNumber: '',
      },
    },
    noFiles: false,
    noFile: true,
    comments: [],
    status: 'OBEHANDLAD',
  },
  history: [],
  tabs: ['ordrar'],
};

const getters = {
  tabs: (state) => {
    return state.tabs;
  },

  orders: (state) => {
    return state.orders;
  },

  order: (state) => {
    return state.order;
  },

  selectedOrder: () => {
    return state.selectedOrder;
  },

  saleById: (state) => (id) => {
    return state.order.sales.find((sale) => sale.id === id);
  },

  sales: (state) => {
    return state.order.sales;
  },

  sale: (state) => {
    return state.sale;
  },

  history: (state) => {
    let orders = JSON.parse(localStorage.getItem("orders"));
    if(orders) {
      state.history = orders;
    }

    return state.history;
  },

  getOrderById: (state) => (id) => {
    return state.todos.find((todo) => todo.id === id);
  },

  customer: (state) => {
    return state.order.customer;
  },

  historyLength: (state) => {
    return Object.keys(state.history).length;
  },

  ordersEditing: (state) => {
    return state.ordersEditing;
  },
};

const mutations = {
  setHistory(state) {
    state.history = [];
    localStorage.removeItem('orders');
  },

  removeFromHistory(state, id) {
    state.history.splice(state.history.indexOf(id), 1);
  },

  setOrders(state, orders) {
    state.orders = orders;
  },

  setOrder(state, order) {
    state.order = order;
  },

  setSelectedOrder(state, order) {
    state.selectedOrder = order;
  },

  addToHistory(state, order) {
    let arr = state.history.filter(item => item.id === order.id);
    if(!arr.length) state.history.push(order);

    localStorage.setItem('orders', JSON.stringify(state.history))
  },

  setSalesOnOrder(state, data) {
    state.orders.forEach((order) => {
      if (order.id === data.id) order.sales = data.sales;
    });
  },

  setCommentsOnOrder(state, data) {
    state.orders.forEach((order) => {
      if (order.id === data.id) order.comments = data.comments;
    });
  },

  addSaleToOrder(state, sale) {
    sale.id = state.order.sales.length;
    state.order.sales.push(sale);
  },

  setProductsOnSale(state, data) {
    state.order.sales[data.id].products = data.products;
  },

  delete(state, sales) {
    if (sales.length === state.order.sales.length) state.order.sales = [];
    else {
      let arr = [];
      sales.forEach((sale) => arr.push(sale.id));
      let t = state.order.sales.filter((f) => !arr.includes(f.id));
      // const filteredArray = state.order.sales.filter((el) => {
      //   return sales.some((f) => {
      //     return f.id !== el.id;
      //   });
      // });
      // console.log(filteredArray);
      t.forEach((el, i) => (el.id = i));
      state.order.sales = t;
    }

    // state.order.sales.forEach((obj, i) => {
    //   console.log(obj.id);
    //   if (obj.id === sale.id) state.order.sales.splice(i, 1);
    //   else obj.id = i;
    // });
    // let arr = state.order.sales.filter((obj) => obj.id !== sale.id);
    // state.order.sales = arr;
  },

  reset(state) {
    state.order = JSON.parse(JSON.stringify(state.emptyOrder)); // Deep copy
  },

  setSale(state, sale) {
    state.sale = sale;
  },

  setOrdersEdited(state, data) {
    state.ordersEditing[data.id] = data.value;
  },
};

const actions = {
  clearHistory({commit}) {
    commit('setHistory');
  },

  updateOrders({ commit }, orders) {
    commit('setOrders', orders);
  },

  updateSalesOnOrder({ commit }, data) {
    commit('setSalesOnOrder', data);
  },

  updateCommentsOnOrder({ commit }, data) {
    commit('setCommentsOnOrder', data);
  },

  updateOrder({ commit, state }, order) {
    commit('setOrder', order);
    return Object.keys(state.history).indexOf(order.id);
  },

  updateSelectedOrder({ commit }, order) {
    commit('setSelectedOrder', order);
    return Object.keys(state.history).indexOf(order.id.toString());
  },

  updateHistory({ commit }, order) {
    commit('addToHistory', order);
  },

  // checkHistory({ state }, id) {
  //   return {
  //     found: state.history.includes(id),
  //     index: state.history.indexOf(id),
  //   };
  // },

  async dataForOrder({ state }, order) {
    // var usersRef = db.collection('users').doc(order.seller.uid);
    //var commentsRef = db.collection('comments').doc(order.id);
    try {
      // var user = await usersRef.get();
      // let { comments } = await api.get('comments', { orderId: order.id });
      // let { sales } = await api.get('orders/sales', { orderId: order.id });

      // order.seller = user.data();
      // order.comments = comments;
      // order.sales = sales;

      let result = await api.get(`orders/${order.id}`)

      state.history[order.id] = result.order;
      state.selectedOrder = result.order;

      return state.history[order.id];
    } catch (err) {
      console.log('Error getting documents', err);
    }

    // order.comments = [];
    // await db
    //   .collection('users')
    //   .doc(order.seller.uid)
    //   .get((doc) => {
    //     console.log('User fetched', doc.data());
    //     order.seller = doc.data();
    //   });

    // await db
    //   .collection('comments')
    //   .doc(order.id)
    //   .onSnapshot((doc) => {
    //     console.log(doc.data().comments);
    //     order.comments = doc.data().comments;
    //   });

    // await db
    //   .collection('users')
    //   .doc(order.seller.uid)
    //   .onSnapshot((doc) => {
    //     order.seller = doc.data();
    //   });
    // //state.order = state.history[order.id];
    // state.history[order.id] = order;
    // console.log('All data fetched...', state.history[order.id].comments);

    // return state.history[order.id];

    // order.sales = await db
    //   .collection('sales')
    //   .doc(order.id)
    //   .get();

    // order.comments = await db
    //   .collection('comments')
    //   .doc(order.id)
    //   .get();
  },

  byId({ state }, id) {
    return state.orders.find((order) => order.id === id);
  },

  removeOrderFromHistory({ commit }, id) {
    commit('removeFromHistory', id);
  },

  addSale({ commit }, sale) {
    commit('addSaleToOrder', sale);
  },

  updateProductsOnSale({ commit }, data) {
    commit('setProductsOnSale', data);
  },

  deleteSales({ commit }, sales) {
    commit('delete', sales);
  },

  resetOrder({ commit }) {
    commit('reset');
  },

  updateSale({ commit }, sale) {
    commit('setSale', sale);
  },

  updateEditingOrder({ commit }, data) {
    commit('setOrdersEdited', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
