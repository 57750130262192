const records = {
  agreements: [
    { name: 'Ingen förändring', active: true, archived: false },
    { name: 'MFA - ALL IN', active: true, archived: false },
    { name: 'MFA - TOTAL', active: true, archived: false },
    { name: 'RAM', active: true, archived: false },
  ],
  agreementUsers: [
    { name: '0-5', active: true, archived: false },
    { name: '6-10', active: true, archived: false },
    { name: '11+', active: true, archived: false },
  ],
  agreementPeriods: [
    { name: '0mån', active: true, archived: false },
    { name: '24mån', active: true, archived: false },
    { name: '36mån', active: true, archived: false },
  ],
  types: [
    { name: 'Portering', order: null, active: true },
    { name: 'Portering-FCA', order: null, active: true },
    { name: 'Nyteck-Tal', order: null, active: true },
    { name: 'Nyteck-Data', order: null, active: true },
    { name: 'Förlängning-Tal', order: null, active: true },
    { name: 'Förlängning-Data', order: null, active: true },
    { name: 'Fast Bredband', order: null, active: true },
    { name: 'Växel-Ny', order: null, active: true },
    { name: 'Växel-Förlängning', order: null, active: true },
    { name: 'Delning', order: null, active: true },
    { name: 'Faktura', order: null, active: true },
    { name: 'Privat', order: null, active: true },
  ],
  subscriptions: [
    { name: 'Jobbmobil 1 GB', active: true },
    { name: 'Jobbmobil 3 GB', active: true },
    { name: 'Jobbmobil 5 GB', active: true },
    { name: 'Jobbmobil 15 GB', active: true },
    { name: 'Jobbmobil 25 GB', active: true },
    { name: 'Jobbmobil 40 GB', active: true },
    { name: 'Jobbmobil 120 GB', active: true },
    { name: 'Jobbmobil Obegränsat', active: true },
    { name: 'Osub 1 GB', active: true },
    { name: 'Osub 3 GB', active: true },
    { name: 'Osub 5 GB', active: true },
    { name: 'Osub 15 GB', active: true },
    { name: 'Osub 25 GB', active: true },
    { name: 'Osub 40 GB', active: true },
    { name: 'Osub 120 GB', active: true },
    { name: 'Osub Obegränsat', active: true },
    { name: 'Datasim', active: true },
    { name: 'MBB 20 GB', active: true },
    { name: 'MBB 50 GB', active: true },
    { name: 'MBB 100 GB', active: true },
    { name: 'MBB 500 GB', active: true },
    { name: 'Touchpoint - Ny', active: true },
    { name: 'Touchpoint Plus', active: true },
    { name: 'Touchpoint - Förlängning', active: true },
    { name: 'Start', active: true },
    { name: 'Plus', active: true },
    { name: 'Pro', active: true },
    { name: 'Delning', active: true },
    { name: 'Privat', active: true },
    { name: 'Bas', active: true },
  ],
  discounts: [
    {
      name: 'Ingen rabatt',
      active: true,
      archived: false,
    },
    {
      name: '1 fri mån',
      active: true,
      archived: false,
    },
    {
      name: '2 fria mån',
      active: true,
      archived: false,
    },
    {
      name: '3 fria mån',
      active: true,
      archived: false,
    },
  ],
  operators: [
    {
      name: 'Telia',
      active: true,
      archived: false,
    },
    {
      name: 'Telenor',
      active: true,
      archived: false,
    },
    {
      name: 'Tele 2',
      active: true,
      archived: false,
    },
    {
      name: 'Tre',
      active: true,
      archived: false,
    },
  ],
  contractPeriods: [
    { name: '0 mån', active: true },
    { name: '24 mån', active: true },
    { name: '36 mån', active: true },
  ],
  offices: [
    { name: 'Kalmar', subOffices: ['Innesäljare', 'Utesäljare'] },
    { name: 'Karlskrona', subOffices: ['Innesäljare', 'Utesäljare'] },
  ],
  // users: [
  //   {
  //     email: 'hannes@dital.se',
  //     password: 'abc123',
  //     office: 'Kalmar',
  //     name: 'Hannes Alm',
  //     role: 'Superadmin',
  //     active: true,
  //   },
  //   {
  //     email: 'test@dital.se',
  //     password: 'abc123',
  //     office: 'Kalmar',
  //     name: 'Test Testsson',
  //     role: 'Säljare',
  //     active: true,
  //   },
  // ],
  roles: [
    { name: 'Superadmin' },
    { name: 'Administratör' },
    { name: 'Säljare' },
  ],

  surcharges: () => {
    let arr = [];
    for (let i = 10; i < 500; i += 10) {
      arr.push({
        name: `${i} kr`,
        value: i,
        active: true,
      });
    }

    return arr;
  },

  statuses: [
    'OBEHANDLAD',
    'REGISTRERAD',
    'PÅBÖRJAD',
    'HÄVD',
    'FRAMTIDA',
    'BORTTAGEN',
  ],
};

// export const agreements = [
//   { name: 'Ingen förändring', active: true },
//   { name: 'MFA - ALL IN', active: true },
//   { name: 'MFA - TOTAL', active: true },
//   { name: 'RAM', active: true },
// ];

// export const agreementUsers = [
//   { name: '0-5', active: true },
//   { name: '6-10', active: true },
//   { name: '11+', active: true },
// ];

// export const agreementPeriods = [
//   { name: '0mån', active: true },
//   { name: '24mån', active: true },
//   { name: '36mån', active: true },
// ];

// export const types = [
//   { name: 'Portering', order: null, active: true },
//   { name: 'Portering-FCA', order: null, active: true },
//   { name: 'Nyteck-Tal', order: null, active: true },
//   { name: 'Nyteck-Data', order: null, active: true },
//   { name: 'Förlängning-Tal', order: null, active: true },
//   { name: 'Förlängning-Data', order: null, active: true },
//   { name: 'Fast Bredband', order: null, active: true },
//   { name: 'Växel-Ny', order: null, active: true },
//   { name: 'Växel-Förlängning', order: null, active: true },
//   { name: 'Delning', order: null, active: true },
//   { name: 'Faktura', order: null, active: true },
//   { name: 'Privat', order: null, active: true },
// ];

// export const subs = [
//   { name: 'Jobbmobil 1 GB', active: true },
//   { name: 'Jobbmobil 3 GB', active: true },
//   { name: 'Jobbmobil 5 GB', active: true },
//   { name: 'Jobbmobil 15 GB', active: true },
//   { name: 'Jobbmobil 25 GB', active: true },
//   { name: 'Jobbmobil 40 GB', active: true },
//   { name: 'Jobbmobil 120 GB', active: true },
//   { name: 'Jobbmobil Obegränsat', active: true },
//   { name: 'Osub 1 GB', active: true },
//   { name: 'Osub 3 GB', active: true },
//   { name: 'Osub 5 GB', active: true },
//   { name: 'Osub 15 GB', active: true },
//   { name: 'Osub 25 GB', active: true },
//   { name: 'Osub 40 GB', active: true },
//   { name: 'Osub 120 GB', active: true },
//   { name: 'Osub Obegränsat', active: true },
//   { name: 'Datasim', active: true },
//   { name: 'MBB 20 GB', active: true },
//   { name: 'MBB 50 GB', active: true },
//   { name: 'MBB 100 GB', active: true },
//   { name: 'MBB 500 GB', active: true },
//   { name: 'Touchpoint - Ny', active: true },
//   { name: 'Touchpoint Plus', active: true },
//   { name: 'Touchpoint - Förlängning', active: true },
//   { name: 'Start', active: true },
//   { name: 'Plus', active: true },
//   { name: 'Pro', active: true },
//   { name: 'Delning', active: true },
//   { name: 'Privat', active: true },
//   { name: 'Bas', active: true },
// ];

// export const discounts = [
//   {
//     name: 'Ingen rabatt',
//     active: true,
//   },
//   {
//     name: '1 fri mån',
//     active: true,
//   },
//   {
//     name: '2 fria mån',
//     active: true,
//   },
//   {
//     name: '3 fria mån',
//     active: true,
//   },
// ];

// export const operators = [
//   {
//     name: 'Telia',
//     active: true,
//   },
//   {
//     name: 'Telenor',
//     active: true,
//   },
//   {
//     name: 'Tele 2',
//     active: true,
//   },
// ];

// export const contractPeriods = [
//   { name: '0 mån', actvie: true },
//   { name: '24 mån', actvie: true },
//   { name: '36 mån', actvie: true },
// ];

// export const offices = [
//   { name: 'Kalmar', subOffices: ['Innesäljare', 'Utesäljare'] },
//   { name: 'Karlskrona', subOffices: ['Innesäljare', 'Utesäljare'] },
// ];

export default records;
