<template>
  <div>
    <v-img :aspect-ratio="16 / 9" :src="src" height="100vh">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="3">
            <v-card elevation="24" shaped>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-img
                  class="loginImage"
                  :src="image"
                  width="10px"
                  aspect-ratio="1"
                ></v-img>

                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card class="text-center mt-5" flat>
                <h1>Optify</h1>
              </v-card>

              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    label="E-post"
                    name="email"
                    prepend-icon="mdi-email"
                    type="email"
                    v-model="email"
                    shaped
                    outlined
                    required
                    :rules="rules.emailRules"
                  ></v-text-field>
                  <!-- <v-text-field
                    :rules="emailRules"
                    v-model="email"
                    prepend-icon="email"
                    name="email"
                    label="E-post"
                    type="email"
                  ></v-text-field> -->
                  <v-text-field
                    label="Lösenord"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="password"
                    shaped
                    outlined
                    required
                    :rules="rules.password"
                    @keyup.enter="submit"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  style="padding: 20px"
                  class="mb-4"
                  color="primary"
                  x-large
                  :loading="loading"
                  @click="submit"
                  >Logga in</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="mb-4" small disabled>OPTIFY 1.0</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
              <v-alert border="left" color="red lighten-2" dark v-if="error">
                {{ errorMessage }}
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
// import api from '../../clasess/api';
import { db, firebase } from '../../firebase/init';

export default {
  data() {
    return {
      valid: false,
      rules: {
        personal_number: [(v) => !!v || 'Personnummer måste va angivet'],
        password: [(v) => !!v || 'Lösenord måste va angivet'],
        email: [(v) => !!v || 'Du måste fylla i en epost'],
      },
      email: '',
      password: '',
      error: null,
      errorMessage: '',
      src: require('@/assets/login.jpg'),
      logo: require('@/assets/favicon.png'),
      image: { src: require('@/assets/favicon.png') },
      loading: false,
      messages: {
        // "The user account has been disabled by an administrator."
        'auth/user-not-found':
          'Det finns ingen användare med angiven e-post, eller så är användaren inaktiverad',
        'auth/wrong-password': 'Fel användarnamn eller e-post',
        'auth/too-many-requests':
          'För många inloggningsförsök, prova igen om en stund',
        'auth/user-disabled': 'Användaren har blivit avstängt av administratör',
      },
    };
  },
  methods: {
    async submit() {
      this.error = false;
      this.loading = true;
      if (this.$refs.form.validate()) {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then((user) => {
            db.collection('users')
              .doc(user.user.uid)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  if (doc.data().active) {
                    localStorage.setItem('user', JSON.stringify(doc.data()));
                    store.dispatch('user/updateUser', doc.data());
                    store.dispatch('app/updateAuthenticated', true);
                    router.push('/');
                  } else {
                    localStorage.removeItem('user');
                    //router.push('/login');
                    store.dispatch('app/updateAuthenticated', false);
                    this.error = true;
                    this.errorMessage = 'Denna användare är avaktiverad';
                  }
                  this.loading = false;
                } else {
                  this.loading = false;
                  this.error = true;
                  this.errorMessage =
                    'Ingen användare hittat i databasen, kontakta systemadministratör.';
                }
              });
          })
          .catch((err) => {
            this.loading = false;
            store.dispatch('app/updateAuthenticated', false);
            this.error = true;
            this.errorMessage = this.messages[err.code];
          });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.loginImage {
  margin-bottom: 90px;
  opacity: 1;
}
</style>
