<template>
  <div>
    <v-app-bar flat color="back">
      <v-btn-toggle v-model="folder" tile group>
        <v-btn value="profile">
          Profilbilder
        </v-btn>

        <v-btn value="news">
          Nyhetsbilder
        </v-btn>

        <v-btn value="package">
          Paketbilder
        </v-btn>
        <v-btn value="excel">
          Excelfiler
        </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-menu
        eager
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="600"
        offset-x
        max-width="500"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" text><v-icon>mdi-upload</v-icon></v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-file-input
                  @change="file"
                  label="Välj fil"
                  clearable
                  multiple
                  outlined
                >
                </v-file-input>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              v-for="(item, i) in filesToUpload"
              :key="item.name"
              :class="{ test: progress[i].percent >= 100 }"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  formatBytes(item.size)
                }}</v-list-item-subtitle>
                <v-progress-linear
                  v-if="progress[i].percent < 100"
                  background-opacity="0"
                  :value="progress[i].percent"
                  color="primary"
                ></v-progress-linear>
                <v-progress-linear
                  v-else
                  background-opacity="0"
                  :value="100"
                  color="green"
                ></v-progress-linear>
              </v-list-item-content>
              <v-list-item-action>
                <v-img aspect-ratio="1" width="100px" :src="item.url"> </v-img>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="filesToUpload.length > 0">
              <v-list-item-content></v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text
                  >Totalt: {{ formatBytes(total) }}</v-list-item-action-text
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-btn color="secondary" @click="clear" class="black--text" small
              >Rensa lista</v-btn
            >
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="uploadFiles" small>Ladda upp</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <!-- <v-btn icon @click="refresh">
        <v-icon>mdi-database-refresh</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-container fluid v-if="folder != 'excel'">
      <v-card>
        <v-container fluid>
          <v-card max-width="150" flat>
            <v-slider
              dense
              v-model="columns"
              min="1"
              max="6"
              prepend-icon="mdi-apps"
              thumb-label
            ></v-slider>
          </v-card>

          <v-row>
            <v-col v-for="file in files" :key="file.url" :cols="columns">
              <v-card flat class="pointer" @click="setDrawer(file)">
                <v-img :src="file.url" :aspect-ratio="1">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-1"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <v-container v-else>
      <input type="file" />
    </v-container>
    <v-navigation-drawer v-model="drawer" fixed right width="500" temporary>
      <v-overlay absolute :value="overlay" color="red lighten-4">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-app-bar>
        <div>
          <v-btn id="delete"
            ><v-icon>mdi-delete</v-icon>
            <div class="progg" :style="'width:' + counter + '%'"></div>
          </v-btn>
        </div>
        <!-- <v-progress-circular
          :rotate="-90"
          :size="50"
          :width="15"
          :value="counter"
          color="primary"
        >
          {{ counter }}
        </v-progress-circular> -->
        <v-progress-linear
          color="red"
          class="ml-2"
          :value="counter"
          v-if="loading.delete"
        ></v-progress-linear>
        <!-- <v-card width="100" flat>
          <v-progress-linear
            color="red"
            class="ml-2"
            :value="counter"
            v-if="loading.delete"
          ></v-progress-linear>
        </v-card> -->

        <v-spacer></v-spacer>

        <v-btn icon @click="drawer = !drawer">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <!-- <v-progress-linear
        color="red"
        class="ml-2"
        :value="counter"
        v-if="loading.delete"
      ></v-progress-linear> -->

      <v-container>
        <v-divider class="mb-4"></v-divider>
        <v-card class="mx-auto">
          <v-img :src="currentImage.url" max-width="100%"></v-img>
          <v-card-subtitle>
            {{ currentImage.name }}
          </v-card-subtitle>
        </v-card>
        <!-- <v-card>
          <v-img
            :src="currentImage.url"
            class="grey lighten-2"
            max-width="100%"
          >
          </v-img>
        </v-card> -->
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { firebase } from '../firebase/init.js';
import moment from 'moment';
import store from '@/store';
import { mapGetters } from 'vuex';

import api from '../clasess/api';

const storage = firebase.storage();
const storageRef = storage.ref();

//const db = firebase.firestore();

export default {
  computed: {
    //...mapGetters("filemanager", ["files"]),
    ...mapGetters('user', ['user']),
    ...mapGetters('filemanager', ['files']),

    filteredFiles() {
      return this.files.filter((file) => file.path.includes(this.folder));
    },
  },

  mounted() {
    store.dispatch('filemanager/getAllFiles');
    //store.dispatch("filemanager/getAll", {})
    //this.fetchFiles();
    //this.fetchTest()
    let button = document.querySelector('#delete');
    button.addEventListener('mousedown', this.mouseDown);
    document.addEventListener('mouseup', this.mouseUp);
  },

  methods: {
    fetchTest() {
      let listRef = storageRef.child('uploads/');

      // Get metadata properties
      listRef
        .getMetadata()
        .then(function() {
          // Metadata now contains the metadata for 'images/forest.jpg'
        })
        .catch(function() {
          // Uh-oh, an error occurred!
        });
    },

    mouseDown() {
      this.loading.delete = true;
      this.interval = setInterval(() => {
        this.counter += 5;
        if (this.counter >= 110) {
          clearTimeout(this.interval);
          this.loading.delete = false;
          this.counter = 0;
          this.deleteCurrentImage();
        }
      }, 100);
    },

    mouseUp() {
      clearTimeout(this.interval);
      this.loading.delete = false;
      this.counter = 0;
    },

    setDrawer(img) {
      this.currentImage = img;
      this.drawer = true;
    },

    clear() {
      this.filesToUpload = [];
      this.file = this.progress = [];
    },

    refresh() {
      store.dispatch('filemanager/getAll', {});
    },

    async fetchFiles() {
      let res = await api.get('files');
      this.files = res.files;

      // store.dispatch("xhr/get", { url: "files" }).then((data) => {
      //   this.files = data.res.files;
      // });
    },

    deleteCurrentImage() {
      this.overlay = true;
      let listRef = storageRef.child(this.currentImage.path);
      listRef.delete().then(() => {
        store.dispatch('filemanager/getAll');
        // this.fetchFiles();
        this.overlay = false;
        this.drawer = false;
        this.addMessage('Filen är nu bortgaten!');
      });
    },

    formatBytes(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return 'n/a';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
      if (i === 0) return `${bytes} ${sizes[i]})`;
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },

    file(files) {
      files.forEach((file) => {
        this.progress.push({ name: file.name, percent: 0 });
        const reader = new FileReader();
        let that = this;
        reader.onload = function(e) {
          file.url = e.target.result;
          file.percent = 0;
          that.filesToUpload.push(file);
          that.total += file.size;
        };
        reader.readAsDataURL(file);
      });
    },

    uploadFiles() {
      this.uploadStarted = true;
      this.filesToUpload.forEach((file, i) => {
        this.upload(
          {
            file: file,
            path: this.folder,
            name: file.name,
            type: file.type,
            size: file.size,
          },
          i,
        );
      });
    },

    upload(file, index) {
      let rootPath = 'uploads/' + file.path + '/' + file.name;

      let listRef = storageRef.child(rootPath);
      var uploadTask = listRef.put(file.file);
      let that = this;
      uploadTask.on(
        'state_changed',
        function(snapshot) {
          var progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          );
          //state.progress[data.name].percent = progress;
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              that.progress[index].percent = progress;
              break;
          }
        },
        // Error
        function() {},

        function() {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            that.fetchFiles();
            store
              .dispatch('xhr/post', {
                url: 'files',
                params: [
                  {
                    name: file.name,
                    type: file.type,
                    path: rootPath,
                    url: downloadURL,
                    uploaded_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                    size: file.size,
                    office: that.user.office,
                  },
                ],
              })
              .then(() => {
                that.fetchFiles();
                that.menu = false;
                that.clear();
              });
            // db.collection("files")
            //   .doc(file.name)
            //   .set({
            //     name: file.name,
            //     type: file.type,
            //     path: rootPath,
            //     url: downloadURL,
            //     uploaded: moment().format("YYYY-MM-DD HH:mm:ss"),
            //     size: file.size,
            //     office: that.user.office
            //   })
            //   .then(() => that.refresh())
          });
        },
      );
    },
  },

  data() {
    return {
      columns: 2,
      overlay: false,
      counter: 0,
      interval: '',
      drawer: false,
      progress: [],
      total: 0,
      menu: false,
      filesToUpload: [],
      selectedFolder: '',
      uploadStarted: false,
      uploadDone: false,
      active: [],
      open: [],
      caseSensitive: false,
      search: '',
      folder: 'profile',
      selected: [],
      dialogs: {
        folder: false,
      },
      loading: {
        delete: false,
      },
      done: {
        percent: 0,
        success: false,
      },
      images: [],
      listRef: '',
      knowledge: 33,
      dialog: false,
      image: { url: '', name: '' },
      currentFile: '',
      currentImage: '',
    };
  },
};
</script>

<style>
.test {
  background-color: #e8f5e9;
}
.pointer {
  cursor: pointer;
}
.progg {
  height: 3px;
  background: #ef9a9a;
  -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  position: absolute;
  margin-top: -20px;
  left: -16px;
}
</style>
