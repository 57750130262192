<template>
  <transition name="scroll-x-reverse-transition">
    <v-alert
      v-if="showMessage"
      :color="message.type"
      dark
      border="left"
      dismissible
    >
      <v-row align="center">
        <v-col class="grow">
          {{ message.body }}
        </v-col>
      </v-row>
      <v-progress-linear
        v-model="value"
        color="white"
        height="1"
        reactive
        class="mt-3"
      ></v-progress-linear>
    </v-alert>
    <!-- <div class="notification" :class="message.type" v-if="showMessage">
      <v-card color="grey lighten-3" elevation="15">
        <div v-if="messageType == 'message'">
          <v-card-title v-if="message.title != ''">{{
            message.title
          }}</v-card-title>
          <v-card-text class="black--text">{{ message.body }}</v-card-text>
        </div>
        <div v-else-if="messageType == 'file'">
          <v-card-text class="black--text">File uppload</v-card-text>
        </div>

        <v-progress-linear
          v-model="value"
          :color="message.type"
          height="2"
          reactive
        ></v-progress-linear>
      </v-card>
    </div> -->
  </transition>
</template>

<script>
/*eslint-disable */
export default {
  props: ['message', 'dark', 'messageType'],

  data() {
    return {
      show: false,
      timeout: 6000,
      value: 0,
      interval: {},
      power: 50,
    };
  },

  mounted() {
    this.show = true;
  },

  close() {
    clearInterval(this.interval);
    this.remove();
  },

  computed: {
    showMessage() {
      let that = this;
      if (this.show) {
        let num = 100 / (this.timeout / 1000) / 10;
        setTimeout(() => {
          that.show = false;
          this.remove();
        }, this.timeout);

        this.interval = setInterval(() => {
          if (this.value >= 100) {
            clearInterval(this.interval);
            return (this.value = 0);
          }
          this.value += num;
        }, 100);
      }

      return this.show;
    },
  },

  methods: {
    remove() {
      setTimeout(() => {
        this.$parent.remove(this.message);
      }, 1000);
    },
  },
};
</script>

<style>
.notification {
  border-left: 12px solid;
  border-radius: 10px;
  margin-bottom: 3px;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
