<template>
  <div>
    <v-snackbar v-model="snackbar" top color="success">
      Sparat!
    </v-snackbar>
    <v-toolbar>
      <v-toolbar-title>Correct data</v-toolbar-title>
      <!-- <v-divider vertical class="ml-3 "></v-divider>
      <v-toolbar-items>
        <v-btn text @click="fetchAllCollections">Get all collections</v-btn>
      </v-toolbar-items> -->
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col md="3">
          <v-btn @click="save">Save collection</v-btn>
          <v-text-field
            label="Add fields"
            filled
            hide-details
            v-model="fields"
          ></v-text-field>
        </v-col>
        <v-col md="5">
          <v-text-field
            label="Collection"
            filled
            hide-details
            v-model="collection"
          ></v-text-field>
          <v-text-field
            label="Filter"
            filled
            hide-details
            v-model="filter"
            @keyup.enter="fetch"
          ></v-text-field>
          <pre>{{ this.items }}</pre>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { db } from "../../firebase/init";
import api from "../../clasess/api";

export default {
  data() {
    return {
      snackbar: false,
      fields: [],
      filter: "",
      selectedCollection: null,
      items: [],
      where: "",
      collection: "",
      collections: ["users", "types", "subscriptions"],
      queryObject: {}
    };
  },
  methods: {
    async save() {
      // Get a new write batch
      var batch = db.batch();

      // Set the value of 'NYC'
      //   var nycRef = db.collection('cities').doc('NYC');
      //   batch.set(nycRef, { name: 'New York City' });

      this.items.forEach(item => {
        var sfRef = db.collection(this.queryObject["collection"]).doc(item.id);
        batch.update(sfRef, item);
      });
      // Update the population of 'SF'
      //   var sfRef = db.collection('cities').doc('SF');
      //   batch.update(sfRef, { population: 1000000 });

      // Delete the city 'LA'
      //   var laRef = db.collection('cities').doc('LA');
      //   batch.delete(laRef);

      // Commit the batch
      batch.commit().then(() => (this.snackbar = true));
    },

    async fetch() {
      // this.items = [];
      // let arr = this.query.split(' ');
      // console.log(arr);
      // for (let i = 0; i < arr.length; i += 2) {
      //   this.queryObject[arr[i]] = arr[i + 1];
      // }
      console.log(typeof this.filter, typeof this.collection);

      let res = await api.get(this.collection, this.filter);

      this.items = res[this.collection];
      console.log(res);

      // db.collection(this.queryObject['collection'])
      //   .limit(this.queryObject['limit'])
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       console.log(doc.id);
      //       let item = doc.data();
      //       item.id = doc.id;
      //       this.items.push(item);
      //       // doc.data() is never undefined for query doc snapshots
      //     });
      //   });
    }
  }
};
</script>
