<template>
  <v-card flat color="transparent" style="overflow: auto">
    <v-list-item>
      <v-text-field
        label="Faktura till kund"
        outlined
        dense
        single-line
        hide-details
        suffix="kr"
        v-model="selectedOrder.invoice.amount"
        :value="selectedOrder.invoice.amount"
      ></v-text-field>
      <v-list-item-action>
        <v-list-item-action-text>Betald</v-list-item-action-text>
        <v-switch
          :value="selectedOrder.invoice.paid"
          v-model="selectedOrder.invoice.paid"
        ></v-switch>
      </v-list-item-action>
    </v-list-item>
    <v-list-item>
      <v-text-field
        label="Faktura till dital"
        outlined
        dense
        single-line
        hide-details
        suffix="kr"
        v-model="selectedOrder.finalInvoice.amount"
        :value="selectedOrder.finalInvoice.amount"
      ></v-text-field>
      <v-list-item-action>
        <v-list-item-action-text>Betald</v-list-item-action-text>
        <v-switch
          :value="selectedOrder.finalInvoice.paid"
          v-model="selectedOrder.finalInvoice.paid"
        ></v-switch>
      </v-list-item-action>
    </v-list-item>
    <v-list-item dense>
      <v-list-item-content>
        <v-list-item-subtitle>TB</v-list-item-subtitle>
        <v-text-field
          label="TB"
          outlined
          dense
          hide-details
          single-line
          v-model="selectedOrder.tb"
          :value="selectedOrder.tb"
        ></v-text-field>
      </v-list-item-content>
    </v-list-item>
    <v-list-item dense>
      <v-list-item-content>
        <v-list-item-subtitle>Dustin ordernummer</v-list-item-subtitle>
        <v-text-field
          label="Dustin Order"
          outlined
          dense
          hide-details
          single-line
          :value="selectedOrder.external"
          v-model="selectedOrder.external"
        ></v-text-field>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-tabs v-model="tab" class="mt-2">
        <v-tab>Order</v-tab>
        <v-tab>Företaget</v-tab>
        <v-tab>Filer</v-tab>
      </v-tabs>
    </v-list-item>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Avtal</v-list-item-subtitle>
              <v-list-item-title>
                <v-select
                  outlined
                  dense
                  hide-details
                  single-line
                  :items="agreements"
                  :value="selectedOrder.agreement.type"
                  v-model="selectedOrder.agreement.type"
                  label="Avtal"
                  item-text="name"
                  item-value="name"
                >
                </v-select>
              </v-list-item-title>
              <v-list-item-title>
                <v-select
                  :disabled="
                    selectedOrder.agreement.type === 'Ingen förändring'
                      ? true
                      : false
                  "
                  outlined
                  dense
                  label="Avtalsperiod"
                  hide-details
                  single-line
                  :items="agreementPeriods"
                  :value="selectedOrder.agreement.period"
                  v-model="selectedOrder.agreement.period"
                  item-text="name"
                  item-value="name"
                >
                </v-select>
              </v-list-item-title>
              <v-list-item-title>
                <v-select
                  :disabled="
                    selectedOrder.agreement.type === 'Ingen förändring'
                      ? true
                      : false
                  "
                  outlined
                  dense
                  hide-details
                  single-line
                  label="Användare på avtal"
                  :items="agreementUsers"
                  :value="selectedOrder.agreement.users"
                  v-model="selectedOrder.agreement.users"
                  item-text="name"
                  item-value="name"
                >
                </v-select>
              </v-list-item-title>
              <v-list-item-title>
                <v-textarea
                  @change="updateEdtingOnOrder"
                  outlined
                  single-line
                  name="input-7-4"
                  label="Övrig info"
                  :value="selectedOrder.info"
                  v-model="selectedOrder.info"
                ></v-textarea>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-tab-item>
      <v-tab-item>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Firmatecknare</v-list-item-subtitle>
              <v-list-item-title>
                <v-text-field
                  @change="updateEdtingOnOrder"
                  outlined
                  dense
                  single-line
                  hide-details
                  :value="selectedOrder.customer.signatory.name"
                  label="Namn"
                ></v-text-field>
              </v-list-item-title>
              <v-list-item-title>
                <v-text-field
                  @change="updateEdtingOnOrder"
                  outlined
                  dense
                  label="Personnummer"
                  single-line
                  hide-details
                  :value="selectedOrder.customer.signatory.personal_number"
                ></v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Företagsinformation</v-list-item-subtitle>
              <v-list-item-title>
                <v-text-field
                  @change="updateEdtingOnOrder"
                  label="Organisationsnummer"
                  outlined
                  dense
                  single-line
                  hide-details
                  :value="selectedOrder.customer.orgNr"
                ></v-text-field>
              </v-list-item-title>
              <v-list-item-title>
                <v-text-field
                  @change="updateEdtingOnOrder"
                  label="Företagsnamn"
                  outlined
                  dense
                  single-line
                  hide-details
                  :value="selectedOrder.customer.companyName"
                ></v-text-field>
              </v-list-item-title>
              <v-list-item-title>
                <v-row no-gutters>
                  <v-col xs="12" md="8">
                    <v-text-field
                      @change="updateEdtingOnOrder"
                      label="Gata"
                      outlined
                      dense
                      single-line
                      hide-details
                      :value="selectedOrder.customer.street"
                    ></v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-text-field
                      @change="updateEdtingOnOrder"
                      label="Postnummer"
                      outlined
                      dense
                      single-line
                      hide-details
                      :value="selectedOrder.customer.zip"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-list-item-title>
                <v-text-field
                  @change="updateEdtingOnOrder"
                  label="Företagsnamn"
                  outlined
                  dense
                  single-line
                  hide-details
                  :value="selectedOrder.customer.city"
                ></v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-tab-item>
      <v-tab-item>
        <v-list dense>
          <v-list-item
            v-for="file in selectedOrder.files"
            :key="file.name"
            @click="selectFile"
          >
            <v-list-item-avatar>
              <v-icon class="grey lighten-1" dark>
                mdi-folder
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="file.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-tab-item>
      <!-- <v-tab-item>
        <v-list v-if="sale">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-select
                  label="Typ"
                  v-model="sale.type"
                  :value="sale.type"
                  item-text="name"
                  item-value="name"
                  hide-details
                  :items="types"
                  dense
                  outlined
                  @change="updateEdtingOnOrder"
                ></v-select>
              </v-list-item-title>
              <v-list-item-title>
                <v-select
                  label="Abonnemangsform"
                  v-model="sale.subscription"
                  :value="sale.subscription"
                  item-text="name"
                  item-value="name"
                  hide-details
                  :items="subscriptions"
                  dense
                  outlined
                  @change="updateEdtingOnOrder"
                ></v-select>
              </v-list-item-title>
              <v-list-item-title>
                <v-select
                  label="Operatör"
                  v-model="sale.operator"
                  :value="sale.operator"
                  item-text="name"
                  item-value="name"
                  hide-details
                  :items="operators"
                  dense
                  outlined
                  @change="updateEdtingOnOrder"
                ></v-select>
              </v-list-item-title>
              <v-list-item-title>
                <v-select
                  label="Bindningstid"
                  v-model="sale.contract_period"
                  :value="sale.contract_period"
                  item-text="name"
                  item-value="name"
                  hide-details
                  :items="contractPeriods"
                  dense
                  outlined
                  @change="updateEdtingOnOrder"
                ></v-select>
              </v-list-item-title>
              <v-list-item-title>
                <v-select
                  label="Mandat"
                  v-model="sale.discount"
                  :value="sale.discount"
                  item-text="name"
                  item-value="name"
                  hide-details
                  :items="discounts"
                  dense
                  outlined
                  @change="updateEdtingOnOrder"
                ></v-select>
              </v-list-item-title>
              <v-list-item-title>
                <v-select
                  label="Pristillägg"
                  v-model="sale.surcharge"
                  :value="sale.surcharge"
                  item-text="name"
                  item-value="name"
                  hide-details
                  :items="surcharges"
                  dense
                  outlined
                  @change="updateEdtingOnOrder"
                ></v-select>
              </v-list-item-title>
              <v-list-item-title>
                <v-text-field
                  @change="updateEdtingOnOrder"
                  label="Kick"
                  outlined
                  dense
                  hide-details
                  :value="sale.kick"
                ></v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="sale.transfer.name !== ''">
            <v-list-item-subtitle>Överlåtelse</v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-if="sale.transfer.name !== ''">
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  @change="updateEdtingOnOrder"
                  label="Namn"
                  outlined
                  dense
                  hide-details
                  :value="sale.transfer.name"
                  v-model="sale.transfer.name"
                ></v-text-field>
              </v-list-item-title>
              <v-list-item-title>
                <v-text-field
                  @change="updateEdtingOnOrder"
                  label="Organisationsnummer"
                  outlined
                  dense
                  hide-details
                  :value="sale.transfer.orgNumber"
                ></v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="sale.products.length">
            <v-list-item-subtitle>Produkter</v-list-item-subtitle>
            <v-list-item-action
              ><v-btn icon
                ><v-icon small>mdi-plus</v-icon></v-btn
              ></v-list-item-action
            >
          </v-list-item>
          <v-list-item
            v-for="product in sale.products"
            :key="product.name"
            class="mb-1"
          >
            <v-list-item-title>
              <v-text-field
                @change="updateEdtingOnOrder"
                label="Namn"
                outlined
                dense
                hide-details
                :value="product.name"
              ></v-text-field>
            </v-list-item-title>
            <v-list-item-title>
              <v-text-field
                @change="updateEdtingOnOrder"
                label="Pris"
                suffix="kr"
                outlined
                dense
                hide-details
                :value="product.price"
              ></v-text-field>
            </v-list-item-title>
            <v-list-item-title>
              <v-text-field
                @change="updateEdtingOnOrder"
                label="Artikelnummer"
                outlined
                dense
                hide-details
                :value="product.article_nr"
              ></v-text-field>
            </v-list-item-title>
            <v-list-item-action>
              <v-btn icon small><v-icon>mdi-plus</v-icon></v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item></v-list-item>
        </v-list>
      </v-tab-item> -->
    </v-tabs-items>
  </v-card>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";

export default {
  // watch: {
  //   selectedOrder: {
  //     handler: (selectedOrder) => {
  //       store.dispatch('order/updateEditingOrder', {
  //         id: selectedOrder.id,
  //         value: true,
  //       });
  //     },
  //     deep: true,
  //   },
  // },

  computed: {
    ...mapGetters("order", ["selectedOrder", "sale"]),
    ...mapGetters("app", [
      "subscriptions",
      "surcharges",
      "discounts",
      "operators",
      "agreements",
      "agreementUsers",
      "agreementPeriods",
      "types",
      "contractPeriods",
      "contractPeriods"
    ])
  },

  data() {
    return {
      tab: 0
    };
  },

  methods: {
    updateEdtingOnOrder() {
      //this.order.editing = true;
      store.dispatch("order/updateEditingOrder", {
        id: this.selectedOrder.id,
        value: true
      });
    }
  }
};
</script>
