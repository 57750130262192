var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":"","dense":"","fixed":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","disabled":!_vm.selected.length},on:{"click":_vm.deleteAllSelectedRows}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1),_c('div',{staticStyle:{"overflow":"scroll","height":"50vh"}},[_c('v-card',[_c('dss-dialog-products',{attrs:{"show":_vm.productsDrawer,"sale":_vm.currentSale}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sales,"hide-default-footer":"","items-per-page":-1,"no-data-text":"Inga sälj inlagda","dense":"","show-select":"","item-key":"id","page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.phoneNumber},on:{"update:returnValue":function($event){return _vm.$set(item, "phoneNumber", $event)},"update:return-value":function($event){return _vm.$set(item, "phoneNumber", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","single-line":""},model:{value:(item.phoneNumber),callback:function ($$v) {_vm.$set(item, "phoneNumber", $$v)},expression:"item.phoneNumber"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.phoneNumber == "" ? "07__ - ______" : item.phoneNumber)+" ")])]}},{key:"item.products",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-chip',{staticClass:"mr-1",attrs:{"small":"","color":item.products && !item.products.length
                ? 'deep-purple lighten-3'
                : ''},on:{"click":function($event){return _vm.setNoProducts(item, index)}}},[_vm._v(" Nej ")]),_c('v-chip',{attrs:{"small":"","color":item.products && item.products.length ? 'accent' : ''},on:{"click":function($event){return _vm.addProducts(item, index)}}},[_vm._v(" Ja ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }