<template>
  <v-container fluid>
    <!-- <v-toolbar color="transparent" flat dense>
      <v-tabs v-model="tab">
        <v-tab v-for="t in tabs" :key="t" @click="changeCurrentOrder(t)"
          ><v-badge v-if="ordersEditing[t]" color="primary" dot bordered>
            {{ t }}
          </v-badge>
          <div v-else>{{ t }}</div></v-tab
        >
      </v-tabs>
    </v-toolbar> -->

    <div name="fade" mode="out-in" v-if="tab < 1">
      <div>
        <v-row class="mt-2" no-gutters>
          <v-col xs="12" md="2" class="mr-1">
            <v-autocomplete outlined single-line dense></v-autocomplete>
          </v-col>
          <v-col xs="12" md="2" class="mr-1">
            <v-autocomplete outlined single-line dense></v-autocomplete>
          </v-col>
          <v-col xs="12" md="1" class="mr-1">
            <v-autocomplete
              outlined
              single-line
              dense
              label="Status"
              :items="getStatuses()"
            ></v-autocomplete>
          </v-col>
          <v-col xs="12" md="2" class="mr-1">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  readonly
                  outlined
                  single-line
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Avbryt
                </v-btn>
                <v-btn text color="primary" @click="menu = false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col xs="12" md="1" class="mr-1">
            <v-autocomplete
              outlined
              single-line
              dense
              label="Kontor"
            ></v-autocomplete>
          </v-col>
          <v-col xs="12" md="2" class="mr-1">
            <v-autocomplete outlined single-line dense></v-autocomplete>
          </v-col>
          <!-- <v-col xs="12" md="1">
            <v-btn fab depressed>Sök</v-btn>
          </v-col> -->
        </v-row>
        <v-data-table
          dense
          :loading="loading"
          no-data-text="Hittade inga ordar med det sök kriteriet"
          loading-text="Laddar ordrar ..."
          :options="options"
          :headers="headers"
          :items="orders"
          class="elevation-1 row-pointer"
          :hide-default-footer="rowSelected"
          :page.sync="page"
          @page-count="pageCount = $event"
          @click:row="setCurrentOrder"
        >
          <template #[`item.status`]="{item}">
            <v-chip :color="getStatusColor(item.status)">{{
              item.status
            }}</v-chip>
          </template>
          <template #[`item.created`]="{item}">
            {{ item.created }}
          </template>
          <template v-slot:footer="{}" v-if="rowSelected">
            <v-container>
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </v-container>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- <div name="fade" mode="out-in" v-if="tab > 0">
      <dss-order></dss-order>
    </div> -->
  </v-container>
</template>

<script>
//import SingleOrder from "./SingleOrder";

import store from "@/store";
import router from "@/router";
import { mapGetters } from "vuex";
import { db } from "../../firebase/init";
import api from "../../clasess/api";
import fakes from "../../clasess/fakes";

export default {
  computed: {
    ...mapGetters("user", ["user", "users"]),
    ...mapGetters("order", ["orders", "history", "ordersEditing", "tabs"]),

    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    orderTabs() {
      return this.tabs ? this.tabs : ["orders"];
    }
  },

  watch: {
    ordersEditing: {
      handler: () => {
        console.log("ordersEditing");
        // store.dispatch('order/updateEditingOrder', {
        //   id: selectedOrder.id,
        //   value: true,
        // });
      },
      deep: true
    }
  },

  components: {
    //"dss-order": SingleOrder
  },

  data: () => ({
    order: {},
    dates: ["2019-09-10", "2019-09-20"],
    menu: false,
    tab: 0,
    options: { itemsPerPage: 25 },
    page: 1,
    pageCount: 2,
    rowSelected: false,
    singleView: false,
    currentOrder: { id: -1 },
    loading: false,
    headers: [
      { text: "Företagsnamn", value: "customer.companyName", align: "left" },
      {
        text: "Orgnummer",
        value: "customer.orgNr"
      },
      { text: "TB", value: "tb", align: "left" },
      { text: "Säljare", value: "seller.name", align: "left" },
      { text: "Kontor", value: "seller.office", align: "left" },
      { text: "Säljdatum", value: "created", align: "left" },
      { text: "Orderstatus", value: "status", align: "center" },
      { text: "Framtida datum", value: "futureDate", align: "center" }
      // { text: 'Säljrader', value: 'sales.length', align: 'left' },
    ]
  }),

  methods: {
    async refresh() {
      this.loading = true;
      let { orders } = await api.get("orders", { limit: 50 });

      store.dispatch("order/updateOrders", orders);
      this.loading = false;
    },

    async generateOrders() {
      let sellers = this.users.filter(user => user.role === "Säljare");

      for (let i = 0; i < 1; i++) {
        let order = fakes.order();
        let nr = Math.floor(Math.random() * sellers.length);
        let seller = sellers[nr];
        order.seller.uid = seller.uid;
        order.seller.name = seller.name;
        order.seller.office = seller.office;
        console.log(order);
        // order = this.trimOrder(order);
        let result = await api.saveGeneratedOrder(order);
        this.orders.push(result.order);
        let comments = fakes.comments(sellers, result.order.id);
        comments.forEach(async comment => {
          await api.put("comments", comment);
        });
        console.log(order.customer.orgNr);
      }
    },

    updateEditedOrder(orderId) {
      this.tabs.forEach(tab => {
        if (tab.name === orderId) {
          tab.edited = true;
          tab.name = "";
          tab.name = orderId;
        }
      });
    },

    async fetchAllOrders() {
      this.loading = true;
      let { orders } = await api.get("orders", { limit: 500 });

      store.dispatch("order/updateOrders", orders);

      // orders.forEach(async order => {
      //   if (!order.comments) order.comments = [];
      //   if (
      //     this.$route.query["order"] !== undefined &&
      //     parseInt(this.$route.query["order"]) === order.id
      //   ) {
      //     await store.dispatch("order/dataForOrder", order);
      //     await store.dispatch("order/updateSelectedOrder", order);
      //     this.tabs.push(order.id);
      //     this.tab = Object.keys(this.history).length;
      //   }
      // });
      this.loading = false;
    },

    async changeCurrentOrder(orderId) {
      store.dispatch("order/updateSale", undefined);
      if (orderId !== "orders") {
        this.setOrderParam(orderId);
        store.dispatch("order/updateSelectedOrder", this.history[orderId]);
      }
    },

    async getSellerForSale(order) {
      db.collection("users")
        .doc(order.seller.uid)
        .onSnapshot(doc => {
          console.log("Current data: ", doc.data());
        });
    },

    setOrderParam(orderId) {
      if (
        this.$route.query["order"] !== undefined &&
        orderId !== this.$route.query["order"]
      ) {
        this.addParam({ order: orderId }, true);
      } else if (this.$route.query["order"] === undefined) {
        this.addParam({ order: orderId }, true);
      }
    },

    async setCurrentOrder(item) {
      console.log(item.id);
      router.push(`/orders/${item.id}`);
      // this.loading = true;
      //let { order } = await api.get(`orders/${item.id}`);
      //store.dispatch("order/updateSelectedOrder", order);
      //this.loading = false;
      // if (this.history[order.id]) {
      //   let index = await store.dispatch("order/updateSelectedOrder", order);
      //   console.log(index);
      //   this.tab = index + 1;
      // } else {
      //   await store.dispatch("order/dataForOrder", order);
      //   this.tabs.push(order.id);
      //   this.tab = Object.keys(this.history).length;
      // }
      // this.setOrderParam(order.id);
      // this.loading = false;
    }
  },

  mounted() {
    //store.dispatch("user/getUsers");
    this.fetchAllOrders();
  },

  beforeDestroy() {
    console.log("Orders view destroyed...");
  }
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.selected {
  background-color: #eeeeee;
}
</style>
