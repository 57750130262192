<template>
  <div>
    <v-navigation-drawer v-model="drawer" temporary app width="500" right>
      <v-container>
        <v-row>
          <v-col v-for="file in files" :key="file.name" cols="4">
            <v-card flat class="pointer" @click="testAddImage(file)">
              <v-img :src="file.url" aspect-ratio="1" class="grey lighten-2">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
    <v-divider></v-divider>
    <v-app-bar
      dense
      flat
      :dark="dark"
      :color="dark ? 'darkBackground' : 'white'"
    >
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn :dark="dark" text v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="addFolder(item)">
            <v-list-item-icon>
              <v-icon small>mdi-folder-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Mapp</v-list-item-title>
          </v-list-item>
          <v-list-item @click="addFile(item)">
            <v-list-item-icon>
              <v-icon small>mdi-book-open-page-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sida</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-divider vertical></v-divider>
        <v-btn
          @click="drawer = !drawer"
          color="primary"
          small
          text
          :disabled="editorData === ''"
          >Publicera</v-btn
        >
        <v-divider vertical></v-divider>
        <v-btn @click="editSelected = !editSelected" text>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn
          @click="deleteItem"
          text
          color="error"
          :dark="dark"
          :disabled="currentSelected === '' && !currentSelected.folder"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
        <v-divider vertical></v-divider>
      </v-toolbar-items>
    </v-app-bar>
    <v-row no-gutters>
      <v-col sm="12" md="3">
        <v-card
          class="tree-view"
          flat
          :color="dark ? 'darkBackground' : 'white'"
        >
          <v-treeview
            :items="treeView.tree"
            item-key="id"
            dense
            :active.sync="tree"
            :open.sync="open"
            transition
            return-object
            activatable
            rounded
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon v-if="!item.file">{{
                open ? "mdi-folder-open" : "mdi-folder"
              }}</v-icon>
              <v-icon v-else>mdi-file-document-box-outline</v-icon>
            </template>
            <template v-slot:append="{ item, open }">
              <div v-if="checkSamePath(item, open)">
                <v-btn-toggle shaped>
                  <v-btn x-small @click="addFolder(item)" v-if="item.folder">
                    <v-icon>mdi-folder-plus</v-icon>
                  </v-btn>
                  <v-btn x-small @click="addFile(item)" v-if="item.folder">
                    <v-icon>mdi-book-open-page-variant</v-icon>
                  </v-btn>
                </v-btn-toggle>
                <!-- <v-btn
                  small
                  text
                  @click="addFolder(item)"
                  color="primary"
                  v-if="item.folder"
                >
                  <v-icon>mdi-folder-plus</v-icon>
                </v-btn>
                <v-btn
                  small
                  text
                  @click="addFile(item)"
                  color="primary"
                  v-if="item.folder"
                >
                  <v-icon>mdi-book-open-page-variant</v-icon>
                </v-btn> -->
              </div>
            </template>
            <template v-slot:label="{ item }">
              <div v-if="item.new">
                <v-text-field
                  v-model="nameField"
                  label="Namn"
                  v-on:keyup.enter="saveRow(item)"
                  autofocus
                ></v-text-field>
              </div>
              <div
                v-else
                class="v-treeview-node__label pointer"
                @mouseover="checkFolder(item)"
              >
                {{ item.name }}
              </div>
            </template>
          </v-treeview>
        </v-card>
      </v-col>
      <v-col sm="12" md="9">
        <editor
          :disabled="currentSelected === '' && !currentSelected.folder"
          v-model="editorData"
          ref="editor"
          api-key="foyzi2idyhaq0t9qvye0pioorad3ypt1m6arcbmntsy3espb"
          :init="editor.settings"
        />
      </v-col>
    </v-row>
    <v-container fluid> </v-container>
  </div>
</template>

<script>
// import * as firebase from "firebase"
import store from "@/store";
import { mapGetters } from "vuex";

// const storage = firebase.storage()
// const storageRef = storage.ref()

// const db = firebase.firestore()

import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    editor: Editor,
  },

  computed: {
    ...mapGetters("app", ["dark"]),

    breadcrumbs() {
      let data = [{ text: "" }];
      this.open.forEach((item) => {
        data.push({
          text: item.name,
          disabled: false,
          href: "",
        });
      });

      return data;
    },
    //...mapGetters("filemanager", ["files"]),

    filteredFiles() {
      return this.files.filter((file) => file.path.includes("news"));
    },

    currentSelected() {
      if (this.tree.length > 0) return this.tree[0];
      else return "";
    },
  },

  mounted() {
    store.dispatch("xhr/get", { url: "files" }).then((data) => {
      this.files = data.res;
    });

    store
      .dispatch("xhr/get", { url: "infobas/json" })
      .then((res) => (this.treeView = res.res));
  },

  methods: {
    saveRow(item) {
      item.new = false;
      item.name = this.nameField;
      this.nameField = "";

      // store.dispatch("filemanager/saveJson", {
      //   name: "treeview",
      //   json: this.treeView,
      // })

      store.dispatch("xhr/put", {
        url: "infobas/json/update",
        params: this.treeView,
      });
    },

    findNode(item) {
      let getSubMenuItem = function(subMenuItems, name) {
        if (subMenuItems) {
          for (var i = 0; i < subMenuItems.length; i++) {
            if (subMenuItems[i].name == name) {
              return subMenuItems[i];
            }
            var found = getSubMenuItem(subMenuItems[i].children, name);
            if (found) return found;
          }
        }
      };

      var searchedItem = getSubMenuItem(this.treeView.tree, item.name);

      return searchedItem;
      // let found
      // var res = this.treeView.forEach(function f(o) {
      //   if (o.name === item.name) {
      //     found = o
      //     return o
      //   }

      //   if (o.children) {
      //     return (o.children = o.children.forEach(f)).length
      //   }
      // })

      // return found
    },

    addFolder(item) {
      this.treeView.numbers.folders++;
      if (item) {
        let leaf = this.findNode(item);

        leaf.children.push({
          name: "",
          folder: true,
          new: true,
          path: `/${item.name}`,
          id: `folder_${this.treeView.numbers.folders}`,
          children: [],
        });
        this.open.push(leaf);
      } else {
        this.treeView.tree.push({
          name: "",
          folder: true,
          new: true,
          path: `/`,
          id: `folder_${this.treeView.numbers.folders}`,
          children: [],
        });
      }
    },

    addFile(item) {
      this.treeView.numbers.files++;
      if (item) {
        let leaf = this.findNode(item);

        leaf.children.push({
          name: "Placeholder...",
          file: "txt",
          new: true,
          path: `/${item.name}`,
          id: `file_${this.treeView.numbers.files}`,
        });
        this.open.push(leaf);
      } else {
        this.treeView.tree.push({
          name: "Placeholder...",
          file: "txt",
          new: true,
          path: `/${item.name}`,
          id: `file_${this.treeView.numbers.files}`,
        });
      }
    },

    checkSamePath(item) {
      return this.currentPath === this.setPath(item);
    },

    checkFolder(item) {
      this.currentPath = this.setPath(item);
    },

    setPath(item) {
      let path = "";

      if (item.path !== "/") path = item.path;

      return `${path}/${item.id}`;
    },

    deleteItem() {
      //let leaf = this.findNode(this.tree[0]);
    },

    imageHandler() {
      this.menu = true;
      //   var range = this.quill.getSelection();
      //   var value = prompt("What is the image URL");
      //   this.quill.insertEmbed(range.index, "image", value, Quill.sources.USER);
    },

    loadImages() {
      return this.files;
      //return this.files;
      //return this.files.filter(file => file.path.includes("news"));
    },

    saveContent() {
      //let data = JSON.stringify(this.editorData);
    },

    testAddImage() {
      this.drawer = false;
      // eslint-disable-next-line no-use-before-define
      // tinymce.execCommand(
      //   "mceInsertContent",
      //   false,
      //   '<img alt="Smiley face" height="200" width="200" src="' +
      //     file.url +
      //     '"/>'
      // );
    },
  },

  data() {
    let that = this;
    return {
      editSelected: false,
      editor: {
        settings: {
          height: "100%",
          menubar: false,
          content_css: "//www.tiny.cloud/css/codepen.min.css",
          plugins: [
            "advlist autolink lists link image imagetools charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          image_list: [
            { title: "My image 1", value: "https://www.example.com/my1.gif" },
            {
              title: "My image 2",
              value: "http://www.moxiecode.com/my2.gif",
            },
          ],
          setup: function(editor) {
            editor.ui.registry.addButton("insertImage", {
              icon: "image",
              onAction: function() {
                that.drawer = true;
              },
            });
            editor.on("init", function() {});
          },
          file_picker_types: "image",
          file_picker_callback: function() {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");

            /*
      Note: In modern browsers input[type="file"] is functional without
      even adding it to the DOM, but that might not be the case in some older
      or quirky browsers like IE, so you might want to add it to the DOM
      just in case, and visually hide it. And do not forget do remove it
      once you do not need it anymore.
    */

            input.onchange = function() {
              var file = this.files[0];

              var reader = new FileReader();
              reader.onload = function() {
                /*
          Note: Now we need to register the blob in TinyMCEs image blob
          registry. In the next release this part hopefully won't be
          necessary, as we are looking to handle it internally.
        */
                // = "blobid" + new Date().getTime();
                //var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                // var base64 = reader.result.split(",")[1];
                // var blobInfo = blobCache.create(id, file, base64);
                // blobCache.add(blobInfo);
                /* call the callback and populate the Title field with the file name */
                // cb(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
            };

            input.click();
          },
          toolbar:
            "undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | insertImage",
        },
      },
      items: [{ title: "Mapp" }, { title: "Sida" }],
      nameField: "",
      currentPath: "",
      adding: false,
      drawer: false,
      files: [],
      open: [{ name: "/" }],
      update: [],
      // breadcrumbs: [
      //   {
      //     text: "",
      //     disabled: false,
      //     href: "breadcrumbs_dashboard",
      //   },
      //   {
      //     text: "Link 1",
      //     disabled: false,
      //     href: "breadcrumbs_link_1",
      //   },
      // ],
      config: {
        placeholderText: "Skriv din nyhet...",
        requestWithCredentials: true,
        imageManagerLoadURL: "http://localhost:8090/api/firebase/files",
        events: {},
      },
      types: ["Mapp", "Fil"],
      selectedType: "",
      item: "",
      tree: [],
      treeView: {},
      // treeView: [
      //   {
      //     name: "/",
      //     folder: true,
      //     path: "/",
      //     children: []
      //   }
      // ],
      // treeView: {
      //   tree: [],
      //   numbers: { folders: 0, files: 0 }
      // },
      editorData: "",
      addNews: false,
      newContent: "",
      menu: false,
      admins: [
        ["Management", "people_outline"],
        ["Settings", "settings"],
      ],
      cruds: [
        ["Create", "add"],
        ["Read", "insert_drive_file"],
        ["Update", "update"],
        ["Delete", "delete"],
      ],
    };
  },
};
</script>

<style scoped>
.tree-view {
  height: 100vh;
}
.pointer {
  cursor: pointer;
}
</style>
