var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"temporary":"","app":"","width":"500","right":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-container',[_c('v-row',_vm._l((_vm.files),function(file){return _c('v-col',{key:file.name,attrs:{"cols":"4"}},[_c('v-card',{staticClass:"pointer",attrs:{"flat":""},on:{"click":function($event){return _vm.testAddImage(file)}}},[_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":file.url,"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)],1)}),1)],1)],1),_c('v-divider'),_c('v-app-bar',{attrs:{"dense":"","flat":"","dark":_vm.dark,"color":_vm.dark ? 'darkBackground' : 'white'}},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":_vm.dark,"text":""}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.addFolder(_vm.item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-folder-plus")])],1),_c('v-list-item-title',[_vm._v("Mapp")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.addFile(_vm.item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-book-open-page-variant")])],1),_c('v-list-item-title',[_vm._v("Sida")])],1)],1)],1),_c('v-spacer'),_c('v-toolbar-items',[_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{attrs:{"color":"primary","small":"","text":"","disabled":_vm.editorData === ''},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_vm._v("Publicera")]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.editSelected = !_vm.editSelected}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{attrs:{"text":"","color":"error","dark":_vm.dark,"disabled":_vm.currentSelected === '' && !_vm.currentSelected.folder},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-divider',{attrs:{"vertical":""}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"12","md":"3"}},[_c('v-card',{staticClass:"tree-view",attrs:{"flat":"","color":_vm.dark ? 'darkBackground' : 'white'}},[_c('v-treeview',{attrs:{"items":_vm.treeView.tree,"item-key":"id","dense":"","active":_vm.tree,"open":_vm.open,"transition":"","return-object":"","activatable":"","rounded":""},on:{"update:active":function($event){_vm.tree=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(!item.file)?_c('v-icon',[_vm._v(_vm._s(open ? "mdi-folder-open" : "mdi-folder"))]):_c('v-icon',[_vm._v("mdi-file-document-box-outline")])]}},{key:"append",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(_vm.checkSamePath(item, open))?_c('div',[_c('v-btn-toggle',{attrs:{"shaped":""}},[(item.folder)?_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.addFolder(item)}}},[_c('v-icon',[_vm._v("mdi-folder-plus")])],1):_vm._e(),(item.folder)?_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.addFile(item)}}},[_c('v-icon',[_vm._v("mdi-book-open-page-variant")])],1):_vm._e()],1)],1):_vm._e()]}},{key:"label",fn:function(ref){
var item = ref.item;
return [(item.new)?_c('div',[_c('v-text-field',{attrs:{"label":"Namn","autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveRow(item)}},model:{value:(_vm.nameField),callback:function ($$v) {_vm.nameField=$$v},expression:"nameField"}})],1):_c('div',{staticClass:"v-treeview-node__label pointer",on:{"mouseover":function($event){return _vm.checkFolder(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}])})],1)],1),_c('v-col',{attrs:{"sm":"12","md":"9"}},[_c('editor',{ref:"editor",attrs:{"disabled":_vm.currentSelected === '' && !_vm.currentSelected.folder,"api-key":"foyzi2idyhaq0t9qvye0pioorad3ypt1m6arcbmntsy3espb","init":_vm.editor.settings},model:{value:(_vm.editorData),callback:function ($$v) {_vm.editorData=$$v},expression:"editorData"}})],1)],1),_c('v-container',{attrs:{"fluid":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }