<template>
  <div v-if="!loadingPage">
    <v-progress-linear
      v-if="loadingPage"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <div class="mt-5" v-if="typeof order === 'object'">
      <v-menu
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
        transition="scroll-x-reverse-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-fab-transition>
            <!-- <v-badge
            bordered
            color="grey"
            icon="mdi-lock"
            overlap
            :content="order.comments.length ? order.comments.length : '0'"
          >
            <v-btn
              color="pink"
              dark
              absolute
              bottom
              right
              fab
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-comment-text-multiple</v-icon>
            </v-btn>
          </v-badge> -->
            <v-btn
              color="grey"
              dark
              absolute
              bottom
              right
              fab
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-comment-text-multiple</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>

        <v-card min-width="600" min-height="800"> </v-card>
      </v-menu>
      <v-toolbar flat dense color="transparent">
        <v-btn to="/orders" color="primary" class="mr-10" small
          ><v-icon left>mdi-arrow-left</v-icon>Ordrar</v-btn
        >
      </v-toolbar>
      <v-toolbar flat dense color="transparent">
        <!-- <h5 class="grey--text">ID: {{ order.id }}</h5> -->
        <v-menu
          transition="slide-x-transition"
          bottom
          right
          v-model="showstatus"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              label
              v-bind="attrs"
              v-on="on"
              :color="getStatusColor(order.status)"
              >{{ order.status }}</v-chip
            >
          </template>

          <v-list>
            <v-list-item
              class="text-center"
              dense
              v-for="(element, i) in getStatuses()"
              :key="i"
              @click="updateOrderStatus(element)"
            >
              <v-list-item-title>{{ element }}</v-list-item-title>
              <v-list-item-action>
                <v-avatar :color="getStatusColor(element)" size="20">
                </v-avatar>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-divider vertical inset class="ml-5 mr-5"></v-divider>

        <h3 class="grey--text">
          {{ order.created }}
        </h3>
        <v-divider vertical class="ml-4 mr-2" inset></v-divider>
        <h3 class="grey--text ml-3" v-if="order.external !== ''">
          Dustin order
        </h3>
        <h2 class="ml-3" v-if="order.external !== ''">
          {{ order.external }}
        </h2>
        <h2 class="grey--text mr-3">TB</h2>
        <h2 text class="mr-5" @click="currentCategory = 'tb'">
          {{ order.tb }}
        </h2>
        <v-divider vertical inset class="mr-1"></v-divider>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon @click="startEditing" v-bind="attrs" v-on="on"
              ><v-icon v-if="!editing">mdi-square-edit-outline</v-icon>
              <v-icon v-else>mdi-cancel</v-icon>
            </v-btn>
          </template>
          <span v-if="editing">Avsluta redigeringsläge</span>
          <span v-else>Redigera order</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="primary"
              text
              :disabled="!order.edited && !editing"
              @click="saveOrder"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>Spara order</span>
        </v-tooltip>
        <v-divider vertical inset class="ml-1 mr-1"></v-divider>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              color="grey"
              icon="mdi-lock"
              overlap
              :content="order.comments.length ? order.comments.length : '0'"
            >
              <v-btn
                class="white--text"
                color="grey"
                depressed
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="navigation.shown = !navigation.shown"
              >
                <v-icon>mdi-comment-text-multiple</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Kommentarer</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="removeOrderFromHistory"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </template>
          <span>Stäng order</span>
        </v-tooltip>
      </v-toolbar>
      <v-row>
        <v-col xs="12" md="9">
          <v-container fluid>
            <v-row>
              <div style="overflow: scroll; height: 80vh">
                <v-card>
                  <v-col xs="12" md="12">
                    <v-data-table
                      :headers="headers"
                      :items="order.sales"
                      :items-per-page="-1"
                      dense
                      show-expand
                      :expanded.sync="expanded"
                      hide-default-footer
                      single-expand
                      @update:expanded="setCurrentSale"
                      @click:row="clicked"
                    >
                      <template #[`item.status`]="{item}">
                        <v-avatar
                          :color="getStatusColor(item.status)"
                          size="24"
                        ></v-avatar>
                      </template>
                      <template #[`item.transfer`]="{item}">
                        <v-icon v-if="item.transfer.name">mdi-check</v-icon>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-row class="mt-2 mb-2">
                            <v-col xs="12" md="12">
                              <v-snackbar
                                v-model="snackbar"
                                top
                                color="primary"
                                text
                                right
                              >
                                Text kpoierad!
                              </v-snackbar>

                              <v-toolbar flat color="transparent">
                                <v-alert
                                  @click="statusMenu = true"
                                  dense
                                  :color="getStatusColor(item.status)"
                                >
                                  <strong>{{ item.status }}</strong>
                                </v-alert>
                                <v-menu
                                  width="100%"
                                  transition="slide-x-transition"
                                  absolute
                                  :value="item.status"
                                  v-model="statusMenu"
                                >
                                  <v-list>
                                    <v-card
                                      :color="getStatusColor(element)"
                                      v-for="(element, i) in getStatuses()"
                                      :key="i"
                                    >
                                      <v-list-item
                                        class="text-center"
                                        dense
                                        @click="updateSaleStatus(element)"
                                      >
                                        <v-list-item-title>{{
                                          element
                                        }}</v-list-item-title>
                                      </v-list-item>
                                    </v-card>
                                  </v-list>
                                </v-menu>
                              </v-toolbar>
                              <v-row>
                                <v-col xs="12" md="6">
                                  <v-list
                                    dense
                                    v-if="item.transfer.name !== ''"
                                  >
                                    <v-list-item dense>
                                      <v-list-item-action>
                                        <v-btn
                                          icon
                                          small
                                          @click="
                                            copyToClipboard(item.transfer.name)
                                          "
                                          ><v-icon small
                                            >mdi-content-copy</v-icon
                                          ></v-btn
                                        >
                                      </v-list-item-action>
                                      <v-list-item-title>{{
                                        item.transfer.name
                                      }}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item dense>
                                      <v-list-item-action>
                                        <v-btn
                                          icon
                                          small
                                          @click="
                                            copyToClipboard(
                                              item.transfer.orgNumber
                                            )
                                          "
                                          ><v-icon small
                                            >mdi-content-copy</v-icon
                                          ></v-btn
                                        >
                                      </v-list-item-action>
                                      <v-list-item-title>{{
                                        item.transfer.orgNumber
                                      }}</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-col>
                                <v-col xs="12" md="6"></v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </td>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-card>
              </div>
            </v-row>
          </v-container>
        </v-col>
        <v-col xs="12" md="3">
          <v-container fluid v-if="!editing">
            <v-row>
              <div style="overflow: scroll; height: 80vh">
                <v-col md="12">
                  <v-card height="100">
                    <v-list-item>
                      <v-list-item-avatar size="68"
                        ><v-img :src="order.seller.img.url"></v-img
                      ></v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h4>{{ order.seller.name }}</h4>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
                <v-col md="12"
                  ><v-card height="100">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          ><h2>
                            {{ order.invoices.invoice.amount }} kr
                          </h2></v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Faktura till kund</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch
                          v-model="order.invoices.invoice.paid"
                          disabled
                        ></v-switch>
                      </v-list-item-action> </v-list-item></v-card
                ></v-col>
                <v-col md="12"
                  ><v-card height="100">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          ><h2>
                            {{ order.invoices.finalInvoice.amount }} kr
                          </h2></v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Faktura till Dital</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch
                          color="primary"
                          v-model="order.invoices.finalInvoice.paid"
                          disabled
                        ></v-switch>
                      </v-list-item-action> </v-list-item></v-card
                ></v-col>
                <v-col md="12">
                  <v-card min-width="400">
                    <v-tabs v-model="tab">
                      <v-tab>Kund</v-tab>
                      <v-tab>Avtal</v-tab>
                      <v-tab>Filer</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-list two-line color="transparent">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-subtitle
                                >Firmatäcknare</v-list-item-subtitle
                              >
                              <v-list-item-title class="item-pointer">{{
                                order.customer.signatory.name
                              }}</v-list-item-title>
                              <v-list-item-title class="item-pointer">{{
                                order.customer.signatory.personal_number
                              }}</v-list-item-title>
                              <v-list-item-title class="item-pointer">{{
                                order.customer.signatory.email
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-subtitle
                                >Företagsinformation</v-list-item-subtitle
                              >
                              <v-list-item-title>{{
                                order.customer.orgNr
                              }}</v-list-item-title>
                              <v-list-item-title>{{
                                order.customer.companyName
                              }}</v-list-item-title>
                              <v-list-item-title
                                >{{ order.customer.street }}
                                {{ order.customer.zip }}</v-list-item-title
                              >
                              <v-list-item-title>{{
                                order.customer.city
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-tab-item>
                      <v-tab-item>
                        <v-list two-line color="transparent">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-subtitle>Avtal</v-list-item-subtitle>
                              <v-list-item-title>{{
                                order.agreement.type
                              }}</v-list-item-title>
                              <v-list-item-title>{{
                                order.agreement.period
                              }}</v-list-item-title>
                              <v-list-item-title>{{
                                order.agreement.users
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-col>
                <v-col md="12">
                  <v-card>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Övrig info</v-list-item-subtitle>
                        {{ order.info }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-card></v-col
                >
              </div>
            </v-row>
          </v-container>
          <v-container v-else>
            <dss-edit-order></dss-edit-order>
          </v-container>
        </v-col>
      </v-row>

      <!-- <v-navigation-drawer
        ref="drawer"
        absolute
        right
        hide-overlay
        :width="navigation.width"
        v-model="navigation.shown"
      >
        <v-card flat>
          <v-card-text>
            <v-toolbar flat color="transparent">
              <v-toolbar-title>Kommentarer</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="show = !show"
                ><v-icon>{{ show ? "mdi-cancel" : "mdi-plus" }}</v-icon></v-btn
              >
              <v-btn icon @click="navigation.shown = !navigation.shown"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-toolbar>
            <v-row>
              <v-col xs="12" md="12">
                <v-container fluid>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-card flat>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            @click="saveComment"
                            :loading="saving.comment"
                            >Spara</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </div>
                  </v-expand-transition>
                  <v-list three-line>
                    <template v-for="(item, index) in order.comments">
                      <v-list-item :key="index">
                        <v-list-item-avatar>
                          <v-img
                            :src="item.user.img.url"
                            max-height="30"
                            max-width="30"
                          ></v-img>
                        </v-list-item-avatar>

                        <v-card flat>
                          <v-card-title>
                            {{ item.user.name }}
                          </v-card-title>
                          <v-card-text>
                            {{ item.comment.text }}
                          </v-card-text>
                        </v-card>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-container>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-navigation-drawer> -->
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import EditOrder from "@/components/singleOrder/Edit";
import api from "../../clasess/api";

export default {
  components: {
    "dss-edit-order": EditOrder
  },

  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("app", [
      "subscriptions",
      "surcharges",
      "discounts",
      "operators",
      "agreements",
      "agreementUsers",
      "agreementPeriods",
      "types",
      "contractPeriods"
    ]),

    orderId() {
      return this.$route.params.id;
    }
  },

  watch: {
    orderId() {
      this.fetchOrder();
    }
  },

  data() {
    return {
      loadingPage: true,
      order: null,
      snackbar: false,
      expanded: [],
      headers: [
        { text: "Telefonnummer", value: "phoneNumber" },
        { text: "Typ", value: "type" },
        { text: "Operatör", value: "operator" },
        { text: "Abonnemangsform", value: "subscription" },
        { text: "Bindningstid", value: "contractPeriod" },
        { text: "Mandat", value: "discount" },
        { text: "Pristillägg", value: "surcharge" },
        { text: "Kick", value: "kick" },
        { text: "Överlåtelse", value: "transfer" },
        { text: "Status", value: "status" },
        { text: "", value: "data-table-expand" }
      ],
      content: {
        ops: []
      },
      config: {
        readOnly: false,
        placeholder: "Compose an epic..."
      },
      editor: "",
      navigation: {
        shown: false,
        width: 400,
        borderSize: 5
      },
      show: false,
      comment: { text: "" },
      saving: { comment: false },

      statusMenu: false,
      status: null,
      menus: [],
      productsDrawer: false,
      sync: {
        type: ""
      },
      editTab: 0,
      dialog: false,
      currentCategory: "",
      currentProducts: [],
      tab: 0,
      tabEditing: 0,
      activeTab: 0,
      editing: false,
      img: "https://randomuser.me/api/portraits/men/3.jpg",
      showstatus: false,
      statusSale: false,
      selectedStatus: null,
      infoTab: 0,
      selected: null,
      commentSelected: null,
      drawer: false,
      fullscreen: false,
      persistent: false,
      sheet: false,
      currentComment: {
        body: ""
      }
    };
  },

  async beforeMount() {
    console.log("SingleOrder mounted...", this.orderId, this.order);
    await this.fetchOrder();
    // if (this.orderId) {
    // }
    console.log("Order fetched...");
    store.dispatch("app/refreshStaticData");
    // this.setBorderWidth();
    // this.setEvents();
  },

  methods: {
    async fetchOrder() {
      this.loadingPage = true;
      let { order } = await api.get(`orders/${this.$route.params.id}`);
      this.order = order;
      this.loadingPage = false;
      store.dispatch("order/updateHistory", order);
    },

    copyToClipboard(item) {
      navigator.clipboard.writeText(item);
      this.snackbar = true;
    },

    clicked(value) {
      if (this.expanded.length < 1) this.expanded.push(value);
      else if (this.expanded.length > 0 && this.expanded[0].id === value.id)
        this.expanded = [];
      else if (this.expanded.length > 0) {
        this.expanded = [];
        this.expanded.push(value);
      }
    },

    async profileImage(user) {
      if (user.img.url !== "") {
        fetch(user.img.url, { method: "HEAD" })
          .then(res => {
            if (res.ok) {
              console.log("Image exists.");
            } else {
              console.log("Image does not exist.");
            }
          })
          .catch(err => console.log("Error:", err));
      } else {
        return require("@/assets/profile.png");
      }
    },

    async saveComment() {
      this.saving.comment = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let comment = {
        comment: this.deepCopyObject(this.comment),
        orderId: this.order.id,
        user: { name: user.name, img: user.img, uid: user.uid }
      };

      let result = await api.put("comments", comment);
      console.log(result);
      this.order.comments = result.comments;
      this.addMessage("Kommentaren är sparad");
      this.comment.text = "";
      this.show = false;
      this.saving.comment = false;
    },

    setBorderWidth() {
      let i = this.$refs.drawer.$el.querySelector(
        ".v-navigation-drawer__border"
      );
      i.style.width = this.navigation.borderSize + "px";
      i.style.cursor = "ew-resize";
      i.style.backgroundColor = "#F4F3F4";
    },

    setEvents() {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      //const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
        "mousedown",
        e => {
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          this.navigation.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    },

    removeOrderFromHistory() {
      store.dispatch("order/removeOrderFromHistory", this.order.id);
    },

    startEditing() {
      this.editing = !this.editing;
      if (this.activeTab === 0) this.activeTab = 1;
      else this.activeTab = 0;
    },

    setCurrentProducts(item) {
      this.currentProducts = item.products.items;
      this.productsDrawer = true;
    },

    updateSaleStatus(status) {
      console.log(status);
    },

    updateEdtingOnOrder() {
      this.order.edited = true;
      this.editing = true;
      this.$emit("orderChanged", this.order.id);
    },

    setValue(item, prop) {
      item[prop].name = this.sync.type;
    },

    async saveOrder() {
      this.editing = false;

      await api.updateOrder(this.order);
    },

    selectFile() {
      console.log("selecting file...");
    },

    check() {
      return this.checkPermission([1], this.user);
    },

    updateOrderStatus(status) {
      this.order.status = status;
      this.order.edited = true;
      this.editing = true;
      this.$emit("orderChanged", this.order.id);
    },

    updateStatusOnCurrentSale(status) {
      this.sale.status = status;
    },

    setCurrentSale(item) {
      store.dispatch("order/updateSale", item[0]);
      // this.tab = 2;
    },

    openDetails(item) {
      this.currentComment = item;
    },

    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      if (!this.fullscreen) this.height = "30vh";
      else this.height = "100vh";
    }
  },
  beforeDestroy() {
    console.log("SingleOrder view destroyed...");
  }
};
</script>

<style>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.registrerad {
  background-color: #e0e0e0;
}

.selectedRow {
  background-color: #e0e0e0;
}

.minusmargin {
  margin-left: -16px;
}

.nav-background {
  background-color: #fafafa !important;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.item-pointer {
  cursor: pointer;
}

.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  padding: 0px 0 0px !important;
  font-size: 12px !important;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
