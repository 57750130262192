<template>
  <div>
    <v-navigation-drawer
      v-model="historyDawer"
      fixed
      temporary
      right
      width="600"
    >
      <v-container>
        <v-timeline dense clipped>
          <v-timeline-item color="primary" icon-color="grey lighten-2" small>
            <v-row justify="space-between">
              <v-col cols="7">
                Uppdaterat av Ivor Dina
              </v-col>
              <v-col class="text-right" cols="5">
                2021-06-01 09:13:00
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item color="primary" icon-color="grey lighten-2" small>
            <v-row justify="space-between">
              <v-col cols="7">
                Uppdaterat av Ivor Dina
              </v-col>
              <v-col class="text-right" cols="5">
                2021-06-01 09:13:00
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item color="primary" icon-color="grey lighten-2" small>
            <v-row justify="space-between">
              <v-col cols="7">
                Uppdaterat av Ivor Dina
              </v-col>
              <v-col class="text-right" cols="5">
                2021-06-01 09:13:00
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-container>
    </v-navigation-drawer>
    <v-card>
      <v-toolbar color="transparent" flat>
        <v-col xs="12" md="2">
          <v-select
            outlined
            single-line
            dense
            :items="agreements"
            label="Avtal"
            item-text="name"
            hide-details
            v-model="agreement"
            return-object
            @change="fetchLinkedData"
          ></v-select>
        </v-col>
        <v-col
          xs="12"
          md="3"
          v-if="agreement && agreement.name !== 'Ingen förändring'"
        >
          <v-select
            outlined
            single-line
            dense
            :items="agreementUsers"
            label="Användare"
            item-text="name"
            multiple
            chips
            hide-details
            v-model="agreement.users"
            return-object
          ></v-select>
        </v-col>
        <v-col
          xs="12"
          md="3"
          v-if="agreement && agreement.name !== 'Ingen förändring'"
        >
          <v-select
            outlined
            single-line
            dense
            v-model="agreement.periods"
            item-text="name"
            multiple
            chips
            hide-details
            :items="agreementPeriods"
            placeholder="Bindningstid"
            return-object
          ></v-select>
        </v-col>
        <v-toolbar-title v-if="!agreement"
          >Välj avtal för att länka</v-toolbar-title
        >
        <!-- 
      <v-tabs v-model="tab" centered>
        <v-tab>Typer - Abonnemang</v-tab>
        <v-tab>Användare - Bindningstid</v-tab>
      </v-tabs> -->

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="saveData"
                :loading="loading"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-content-save-outline</v-icon></v-btn
              >
            </template>
            <span>Spara</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="historyDawer = !historyDawer"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-history</v-icon></v-btn
              >
            </template>
            <span>Historik</span>
          </v-tooltip>
          <!-- <v-btn @click="addAll">Add all</v-btn> -->
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid v-if="agreement">
        <v-row>
          <v-col xs="12" md="2">
            <v-sheet color="transparent">
              <v-sheet class="pa-3 text-right" color="transparent">
                <v-select
                  outlined
                  single-line
                  dense
                  v-model="selectedTypes"
                  item-text="name"
                  multiple
                  hide-details
                  :items="types"
                  placeholder="Typer"
                  return-object
                  @change="update"
                >
                  <template v-slot:selection="data">{{
                    data.index === 0 ? "Typer" : ""
                  }}</template>
                  <template v-slot:append-outer>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="toggleTypes"
                        >
                          <v-icon class="mb-2">{{
                            selectedTypes.length !== types.length
                              ? "mdi-book-plus-multiple"
                              : "mdi-book-minus-multiple"
                          }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Lägg till alla typer</span>
                    </v-tooltip>
                  </template>
                </v-select>
              </v-sheet>
            </v-sheet>
            <v-item-group
              multiple
              v-model="selectedTypes"
              return-object
              :value="selectedTypes"
              dense
              rounded
            >
              <draggable
                class="drag-cursor"
                v-bind="dragOptions"
                @start="drag = false"
                @end="drag = false"
                :group="{ name: 'types' }"
                :list="selectedTypes"
              >
                <v-card v-for="(n, i) in selectedTypes" :key="n.id + n.name">
                  <v-row no-gutters>
                    <v-list-item
                      dense
                      :dark="type.name === n.name"
                      selected
                      :class="type && type.name === n.name ? 'primary' : ''"
                    >
                      <v-list-item-avatar>
                        <v-icon small>
                          mdi-drag-vertical
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content @click="setType(n)">
                        <v-list-item-title v-text="n.name"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          small
                          :class="
                            type && type.name === n.name
                              ? 'primary_light black--text'
                              : 'primary white--text'
                          "
                          >{{ i + 1 }}</v-chip
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </v-row>
                </v-card>
              </draggable>
            </v-item-group>
          </v-col>
          <v-col xs="12" md="3">
            <!-- Subs -->
            <v-sheet rounded="t-xl" color="transparent">
              <v-sheet
                class="pa-3 text-right"
                rounded="t-xl"
                color="transparent"
              >
                <v-select
                  :disabled="!haveType"
                  outlined
                  single-line
                  dense
                  v-model="type.subs"
                  item-text="name"
                  multiple
                  hide-details
                  :items="subscriptions"
                  placeholder="Abonnemangsformer"
                  return-object
                  @change="update"
                >
                  <template v-slot:selection="data">{{
                    data.index === 0 ? "Abonnemangsformer" : ""
                  }}</template>
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          icon
                          :color="haveType ? 'primary' : 'grey'"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="toggleSubs"
                        >
                          <v-icon class="mb-2">mdi-book-plus-multiple</v-icon>
                        </v-btn>
                      </template>
                      <span>Lägg till alla abonnemangsformer</span>
                    </v-tooltip>
                  </template>
                </v-select>
              </v-sheet>
            </v-sheet>
            <v-item-group
              multiple
              v-model="type.subs"
              return-object
              :value="type.subs"
              dense
            >
              <draggable
                class="drag-cursor"
                v-bind="dragOptions"
                @start="drag = false"
                @end="drag = false"
                :group="{ name: 'subscriptions' }"
                :list="type.subs"
              >
                <v-card v-for="(n, i) in type.subs" :key="n.name">
                  <v-row no-gutters>
                    <v-list-item
                      dense
                      :dark="sub && sub.name === n.name"
                      selected
                      :class="sub && sub.name === n.name ? 'primary' : ''"
                    >
                      <v-list-item-avatar>
                        <v-icon>
                          mdi-drag-vertical
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content @click="setSub(n)">
                        <v-list-item-title v-text="n.name"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          small
                          :class="
                            sub && sub.name === n.name
                              ? 'primary_light black--text'
                              : 'primary white--text'
                          "
                          >{{ i + 1 }}</v-chip
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </v-row>
                </v-card>
              </draggable>
            </v-item-group>
          </v-col>
          <v-col xs="12" md="3">
            <!-- Discounts -->
            <v-sheet rounded="t-xl" color="transparent">
              <v-sheet
                class="pa-3 text-right"
                rounded="t-xl"
                color="transparent"
              >
                <v-select
                  :disabled="!haveSub"
                  outlined
                  single-line
                  dense
                  v-model="sub.discounts"
                  item-text="name"
                  multiple
                  hide-details
                  :items="discounts"
                  placeholder="Mandat"
                  return-object
                  @change="update"
                >
                  <template v-slot:selection="data">{{
                    data.index === 0 ? "Mandat" : ""
                  }}</template>
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="haveSub ? 'primary' : 'grey'"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="toggleDiscounts"
                        >
                          <v-icon class="mb-2">mdi-book-plus-multiple</v-icon>
                        </v-btn>
                      </template>
                      <span>Lägg till alla mandat</span>
                    </v-tooltip>
                  </template>
                </v-select>
              </v-sheet>
            </v-sheet>
            <v-item-group
              multiple
              v-model="sub.discounts"
              return-object
              :value="sub.discounts"
            >
              <draggable
                class="drag-cursor"
                v-bind="dragOptions"
                @start="drag = false"
                @end="drag = false"
                :group="{ name: 'discounts' }"
                :list="sub.discounts"
              >
                <v-card v-for="(n, i) in sub.discounts" :key="n.i + n.name">
                  <v-row no-gutters>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon>
                          mdi-drag-vertical
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="n.name"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip small>{{ i + 1 }}</v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-row>
                </v-card>
              </draggable>
            </v-item-group>
          </v-col>
          <v-col xs="12" md="3">
            <!-- Surcharges -->
            <v-sheet rounded="t-xl" color="transparent">
              <v-sheet
                class="pa-3 text-right"
                rounded="t-xl"
                color="transparent"
              >
                <v-select
                  :disabled="!haveSub"
                  outlined
                  single-line
                  dense
                  v-model="sub.surcharges"
                  item-text="name"
                  multiple
                  hide-details
                  :items="surcharges"
                  placeholder="Pristillägg"
                  return-object
                  @change="update"
                >
                  <template v-slot:selection="data">{{
                    data.index === 0 ? "Pristillägg" : ""
                  }}</template>
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="haveSub ? 'primary' : 'grey'"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="toggleSurcharges"
                        >
                          <v-icon class="mb-2">mdi-book-plus-multiple</v-icon>
                        </v-btn>
                      </template>
                      <span>Lägg till alla pristillägg</span>
                    </v-tooltip>
                  </template>
                </v-select>
              </v-sheet>
            </v-sheet>
            <v-item-group
              multiple
              v-model="sub.surcharges"
              return-object
              :value="sub.surcharges"
            >
              <draggable
                class="drag-cursor"
                v-bind="dragOptions"
                @start="drag = false"
                @end="drag = false"
                :group="{ name: 'surcharges' }"
                :list="sub.surcharges"
              >
                <v-card v-for="(n, i) in sub.surcharges" :key="n.id + n.name">
                  <v-row no-gutters>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon>
                          mdi-drag-vertical
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="n.name"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip small>{{ i + 1 }}</v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-row>
                </v-card>
              </draggable>
            </v-item-group>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import store from "@/store";
//import sizeof from 'firestore-size';

export default {
  components: {
    draggable
  },

  mounted() {
    store.dispatch("app/refreshStaticData");
  },

  computed: {
    ...mapGetters("app", [
      "subscriptions",
      "surcharges",
      "discounts",
      "agreementUsers",
      "agreementPeriods",
      "agreements",
      "types",
      "subscriptions"
    ]),

    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false
      };
    },

    haveType() {
      return Object.keys(this.type).length > 0;
    },

    haveSub() {
      return Object.keys(this.sub).length > 0;
    }
  },

  data() {
    return {
      agreement: null,
      historyDawer: false,
      filterTypes: false,
      edited: false,
      loading: false,
      tab: null,
      selectedTypes: [],
      type: "",
      sub: "",
      history: {
        subs: []
      },
      surchargesToSave: []
    };
  },

  methods: {
    addAll() {
      let arr = [];

      this.types.forEach(type => {
        let a = { name: type.name, subs: [] };
        this.subscriptions.forEach((sub, i) => {
          a.subs[i] = { name: sub.name, surcharges: [], discounts: [] };
          this.surcharges.forEach(sur => {
            a.subs[i].surcharges.push(sur.name);
          });
          this.discounts.forEach(disc => {
            a.subs[i].discounts.push(disc.name);
          });
        });
        arr.push(a);
      });
      // let size = this.roughSizeOfObject({ records: arr });
      // console.log(size);
      // console.log(this.formatByteSize(sizeof(arr)));
      return arr;
    },

    update() {
      this.$forceUpdate();
    },

    convertArrayRecordsToObjects(arr) {
      let records = [];
      arr.forEach(element => {
        records.push({ name: element });
      });

      return records;
    },

    fetchLinkedData() {
      store
        .dispatch("firestore/getLlinkedData", this.agreement)
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            let arr = [];
            querySnapshot.forEach(doc => {
              //console.log(this.formatByteSize(sizeof(doc.data())));
              this.agreement.users = doc.data().agreement.users;
              this.agreement.periods = doc.data().agreement.periods;
              doc.data().records.forEach(type => {
                type.subs.forEach(sub => {
                  if (sub.discounts && sub.discounts.length) {
                    let discounts = this.convertArrayRecordsToObjects(
                      sub.discounts
                    );
                    sub.discounts = discounts;
                  }
                  if (sub.surcharges && sub.surcharges.length) {
                    let surcharges = this.convertArrayRecordsToObjects(
                      sub.surcharges
                    );
                    sub.surcharges = surcharges;
                  }
                });
                arr.push(type);
              });
            });
            this.selectedTypes = arr;
            this.type = "";
            this.sub = "";
          } else {
            this.selectedTypes = [];
            this.type = "";
            this.sub = "";
          }
        });
    },

    closeDialog() {
      this.$parent.$parent.$parent.dialog = false;
    },

    filterSub(sub) {
      if (sub.surcharges && sub.surcharges.length) {
        let surcharges = sub.surcharges;
        sub.surcharges = [];

        surcharges.forEach(sur => {
          sub.surcharges.push(sur.name);
        });
      }

      if (sub.discounts && sub.discounts.length) {
        let discounts = sub.discounts;
        sub.discounts = [];
        discounts.forEach(disc => {
          sub.discounts.push(disc.name);
        });
      }

      let subscription = {
        name: sub.name,
        discounts: sub.discounts ? sub.discounts : [],
        surcharges: sub.surcharges ? sub.surcharges : []
      };

      return subscription;
    },

    filterType(type) {
      return {
        name: type.name,
        subs: type.subs ? type.subs : []
      };
    },

    filterData() {
      let arr = this.deepCopyObject(this.selectedTypes);
      arr.forEach((type, i) => {
        arr[i] = this.filterType(type);
        if (type.subs && type.subs.length) {
          type.subs.forEach((sub, i) => {
            type.subs[i] = this.filterSub(sub);
          });
        }
      });
      return arr;
    },

    saveWithAllAdded() {
      let allRecords = this.addAll();

      store
        .dispatch("firestore/saveData", {
          collection: "linked",
          id: this.agreement.id,
          record: {
            name: this.agreement.name,
            agreement: {
              name: this.agreement.name,
              users: this.agreement.users ? this.agreement.users : [],
              periods: this.agreement.periods ? this.agreement.periods : []
            },
            records: allRecords
          }
        })
        .then(() => {
          store.dispatch("user/addMessage", {
            body: `Länkad data för ${this.agreement.name} är nu sparat/uppdaterat`,
            type: "success",
            messageType: "message"
          });
        })
        .catch(err => console.log(err));
    },

    saveData() {
      store
        .dispatch("firestore/saveData", {
          collection: "linked",
          id: this.agreement.id,
          record: {
            name: this.agreement.name,
            agreement: {
              name: this.agreement.name,
              users: this.agreement.users ? this.agreement.users : [],
              periods: this.agreement.periods ? this.agreement.periods : []
            },
            records: this.filterData()
          }
        })
        .then(() => {
          store.dispatch("user/addMessage", {
            body: `Länkad data för ${this.agreement.name} är nu sparat/uppdaterat`,
            type: "success",
            messageType: "message"
          });
        })
        .catch(err => console.log(err));
    },

    // roughSizeOfObject(obj) {
    //   var bytes = 0;

    //   function sizeOf(obj) {
    //     if (obj !== null && obj !== undefined) {
    //       switch (typeof obj) {
    //         case 'number':
    //           bytes += 8;
    //           break;
    //         case 'string':
    //           bytes += obj.length * 2;
    //           break;
    //         case 'boolean':
    //           bytes += 4;
    //           break;
    //         case 'object':
    //           var objClass = Object.prototype.toString.call(obj).slice(8, -1);
    //           if (objClass === 'Object' || objClass === 'Array') {
    //             for (var key in obj) {
    //               if (!obj.hasOwnProperty(key)) continue;
    //               sizeOf(obj[key]);
    //             }
    //           } else bytes += obj.toString().length * 2;
    //           break;
    //       }
    //     }
    //     return bytes;
    //   }

    //   return this.formatByteSize(sizeOf(obj));
    // },

    // formatByteSize(bytes) {
    //   if (bytes < 1024) return bytes + ' bytes';
    //   else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KiB';
    //   else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MiB';
    //   else return (bytes / 1073741824).toFixed(3) + ' GiB';
    // },

    setType(type) {
      this.type = type;
      if (!this.type.subs) this.type.subs = [];

      this.history.subs = this.deepCopyObject(this.type.subs);
      this.sub = {};
      // this.selectedSurcharges = [];
      // this.selectedDiscounts = [];
    },

    setSub(sub) {
      this.sub = sub;
      this.sub.discounts = sub.discounts ? sub.discounts : [];
      this.sub.surcharges = sub.surcharges ? sub.surcharges : [];
    },

    toggleTypes() {
      if (
        (this.selectedTypes && this.selectedTypes.length) === this.types.length
      ) {
        this.selectedTypes = [];
      } else {
        this.types.forEach(type => {
          let arr = this.selectedTypes.filter(j => j.name === type.name);
          if (arr.length < 1)
            this.selectedTypes.push({
              name: type.name,
              subs: type.subs ? type.subs : []
            });
        });
      }
    },

    toggleSubs() {
      if (
        (this.type.subs && this.type.subs.length) === this.subscriptions.length
      ) {
        this.type.subs = [];
      } else {
        this.subscriptions.forEach(sub => {
          let arr = this.type.subs.filter(j => j.name === sub.name);
          if (arr.length < 1)
            this.type.subs.push({
              name: sub.name,
              surcharges: sub.surcharges ? sub.surcharges : [],
              discounts: sub.discounts ? sub.discounts : []
            });
        });
      }

      this.$forceUpdate();
    },

    toggleDiscounts() {
      if (
        (this.sub.discounts && this.sub.discounts.length) ===
        this.discounts.length
      ) {
        this.sub.discounts = [];
      } else {
        this.discounts.forEach(discount => {
          if (this.sub.discounts && this.sub.discounts.length) {
            let arr = this.sub.discounts.filter(j => j.name === discount.name);
            if (arr.length < 1) this.sub.discounts.push(discount);
          } else {
            this.sub.discounts = [];
            this.sub.discounts.push(discount);
          }
        });
      }
      this.$forceUpdate();
    },

    toggleSurcharges() {
      if (
        (this.sub.surcharges && this.sub.surcharges.length) ===
        this.surcharges.length
      ) {
        this.sub.surcharges = [];
      } else {
        this.surcharges.forEach(sur => {
          if (this.sub.surcharges && this.sub.surcharges.length) {
            let arr = this.sub.surcharges.filter(j => j.name === sur.name);
            if (arr.length < 1) this.sub.surcharges.push(sur);
          } else {
            this.sub.surcharges = [];
            this.sub.surcharges.push(sur);
          }
        });
      }
      this.$forceUpdate();
    }
  }
};
</script>
