import moment from 'moment';
import store from '@/store';

moment.locale('sv');

export default {
  methods: {
    checkPermission(target, user) {
      let foundRole;

      if (Array.isArray(target)) {
        target.forEach((role) => {
          if (user.role === role) foundRole = role;
        });

        return user.role === foundRole;
      }

      return user.role === target;
    },

    deepCopyObject(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    formatDate(date, format) {
      return moment(date).format(format);
    },

    addMessage(title = '', type = 'success') {
      store.dispatch('user/addMessage', {
        body: title,
        type: type,
        messageType: 'message',
      });
    },

    trimOrder(order) {
      Object.keys(order).forEach((key) => {
        if (key === 'agreement') {
          Object.keys(order[key]).forEach((el) => {
            order[key][el] = order[key][el].name;
          });
        }
      });
      order.sales.forEach((sale) => {
        Object.keys(sale).forEach((key) => {
          if (
            typeof sale[key] === 'object' &&
            key !== 'products' &&
            key !== 'transfer'
          ) {
            sale[key] = sale[key].name;
          }
        });
      });

      return order;
    },

    uuidv4() {
      return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16),
      );
    },

    formatBytes(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return 'n/a';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
      if (i === 0) return `${bytes} ${sizes[i]})`;
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },

    getCookieByName(name) {
      return document.cookie
        .split('; ')
        .find((row) => row.startsWith(name))
        .split('=')[1];
    },

    getStatuses() {
      return [
        'OBEHANDLAD',
        'REGISTRERAD',
        'PÅBÖRJAD',
        'HÄVD',
        'FRAMTIDA',
        'BORTTAGEN',
      ];
    },

    getStatusColor(status) {
      /*
      Statuses
      ------------------------
      OBEHANDLAD // Ska väljas per automatiskt när en säljare lägger in en order.
      REGISTRERAD // Säljare har sparat ordern
      PÅBÖRJAD // Ordern är reggad och klar
      HÄVD // Registrering ska ske vid senare datum
      FRAMTIDA // Komplettering behövs, meddelande till säljaren?
      BORTTAGEN // Ordern blir cancelerad av olika anledningar
      ------------------------
      */
      if (status == 'OBEHANDLAD') return 'grey lighten-4';
      else if (status == 'REGISTRERAD') return 'green lighten-4';
      else if (status == 'HÄVD') return 'red lighten-3';
      else if (status == 'PÅBÖRJAD') return 'orange lighten-4';
      else if (status == 'FRAMTIDA') return 'indigo lighten-4';
      else if (status == 'BORTTAGEN') return 'brown lighten-3';
      else return 'grey';
    },

    transelateMessage(err) {
      let messages = {
        // "The user account has been disabled by an administrator."
        'auth/user-not-found':
          'Det finns ingen användare med angiven e-post, eller så är användaren inaktiverad',
        'auth/wrong-password': 'Fel e-post eller lösenord',
        'auth/too-many-requests':
          'För många inloggningsförsök, prova igen om en stund',
        'auth/user-disabled': 'Användaren har blivit avstängt av administratör',
        'auth/email-already-in-use':
          'En användare är redan registrerad med denna e-post',
      };

      return messages[err.code];
    },

    updateOrderOnLocalStorage(order) {
      localStorage.setItem('order', JSON.stringify(order));
    },

    isDev() {
      return process.env.NODE_ENV === 'development';
    },

    addParam(param, clean = false) {
      if (clean) {
        this.$router.replace({
          name: this.$router.currentRoute.name,
          query: param,
        });
      } else {
        this.$router.replace({
          name: this.$router.currentRoute.name,
          query: { ...this.$route.query, ...param },
        });
      }
    },
  },
};
