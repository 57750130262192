<template>
  <v-app id="app" v-cloak v-if="authenticated">
    <div class="note">
      <dss-notification-list
        v-show="messages.length > 0"
      ></dss-notification-list>
    </div>
    <v-app-bar fixed app clipped-left flat color="primary">
      <!-- <v-btn icon @click="overlay = !overlay">
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->
      <v-toolbar-title class="white--text">Optify</v-toolbar-title>
      <v-col md="1"></v-col>
      <v-menu offset-y v-model="menu" rounded="b-xl" :close-on-click="false">
        <template v-slot:activator="{}">
          <v-text-field
            hide-details
            class="mx-4"
            solo-inverted
            dark
            label="Sök"
            v-model="search"
            @click="menu = true"
            :loading="loading"
            @blur="closeMenu"
          ></v-text-field>
        </template>
        <v-card v-if="searchItems.length" max-height="300">
          <v-list>
            <v-list-item>{{ searchItems.length }} sökresultat</v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-for="(item, index) in searchItems"
              :key="index"
              :to="`/orders/${item.id}`"
            >
              <v-list-item-avatar>
                <v-avatar color="primary" size="24">
                  <span class="white--text">{{ index + 1 }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-title>ID #{{ item.id }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.customer.orgNr
              }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{
                item.customer.companyName
              }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card>

        <v-list v-else>
          <v-list-item>
            <v-list-item-content>
              <v-alert text color="primary" icon="mdi-cloud-alert">
                Inga sökresultat
              </v-alert>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <v-autocomplete
        :loading="loading"
        :items="searchItems"
        :search-input.sync="search"
        class="mx-4"
        flat
        hide-no-data
        hide-details
        label="Sök"
        solo-inverted
        dark
      >
        <template v-slot:append-outer="data">
          <v-chip>
            {{ data }}
          </v-chip>
        </template>
      </v-autocomplete> -->
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" dark>
            <v-icon>mdi-history</v-icon>
          </v-btn>
          <!-- <v-btn icon v-bind="attrs" v-on="on" v-else class="mr-10">
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn> -->
        </template>
        <v-card width="400">
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="overline"
                  >Orderhistorik</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="clearHistory"
                      ><v-icon>mdi-playlist-remove</v-icon></v-btn
                    >
                  </template>
                  <span>Rensa listan</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list two-line v-if="history.length">
            <v-list-item
              v-for="item in history"
              :key="item.id"
              :to="`/orders/${item.id}`"
            >
              <v-list-item-avatar> #{{ item.id }} </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  formatOrgNr(item.customer.orgNr)
                }}</v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.customer.companyName"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item>
              <v-list-item-content>
                <v-alert text type="warning" icon="mdi-cloud-alert">
                  Inga tidigare ordrar öppnade
                </v-alert>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :color="newItems > 0 ? 'error' : 'transparent'"
            offset-x="20"
            offset-y="20"
            :content="newItems"
            class="mr-10"
          >
            <v-btn icon v-bind="attrs" v-on="on" dark>
              <v-icon>mdi-bell-outline</v-icon>
            </v-btn>
          </v-badge>
          <!-- <v-btn icon v-bind="attrs" v-on="on" v-else class="mr-10">
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn> -->
        </template>
        <v-card width="400">
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="overline"
                  >Notifikationer</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list two-line v-if="notifications.length">
            <v-list-item v-for="item in notifications" :key="item.title">
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>

                <v-list-item-subtitle>{{ item.message }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="!item.read">
                <v-chip small color="primary">Nytt</v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item>
              <v-list-item-content>
                <v-alert text type="warning" icon="mdi-cloud-alert">
                  Inga notiser hittade
                </v-alert>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <dss-navbar></dss-navbar>
    <v-container fluid>
      <v-main>
        <transition name="fade" mode="out-in" :duration="200">
          <router-view />
        </transition>
      </v-main>
    </v-container>
  </v-app>
  <v-app v-else>
    <transition name="fade" mode="out-in">
      <keep-alive>
        <router-view />
      </keep-alive>
    </transition>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { db } from "./firebase/init";
import api from "./clasess/api";

export default {
  computed: {
    ...mapGetters("app", ["authenticated"]),
    ...mapGetters("user", ["messages"]),
    ...mapGetters("order", ["history"]),

    searchItems() {
      if (this.orders.length) return this.orders;
      return [];
    },

    newItems() {
      if (this.notifications.length > 0) {
        let arr = this.notifications.filter(item => !item.read);
        return arr.length;
      } else return this.notifications.length;
    },

    image() {
      return { src: require("@/assets/favicon.png") };
    }
  },

  watch: {
    authenticated() {
      if (this.authenticated) this.subscribeToNotifications();
    },

    search(val) {
      val && val !== this.select && this.querySelections(val);
    }
  },

  data: () => ({
    menu: false,
    orders: [],
    overlay: false,
    selected: [2],
    select: "",
    loading: false,
    loadingPage: true,
    search: "",
    notifications: [],
    items: [
      {
        action: "15 min",
        headline: "Brunch this weekend?",
        subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        title: "Ali Connors"
      },
      {
        action: "2 hr",
        headline: "Summer BBQ",
        subtitle: `Wish I could come, but I'm out of town this weekend.`,
        title: "me, Scrott, Jennifer"
      },
      {
        action: "6 hr",
        headline: "Oui oui",
        subtitle: "Do you have Paris recommendations? Have you ever been?",
        title: "Sandra Adams"
      },
      {
        action: "12 hr",
        headline: "Birthday gift",
        subtitle:
          "Have any ideas about what we should get Heidi for her birthday?",
        title: "Trevor Hansen"
      },
      {
        action: "18hr",
        headline: "Recipe to try",
        subtitle:
          "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
        title: "Britta Holt"
      }
    ]
  }),

  methods: {
    closeMenu() {
      setTimeout(() => {
        this.menu = false;
      }, 100);
    },

    clearHistory() {
      store.dispatch("order/clearHistory");
    },

    async querySelections() {
      console.log("Searching orders...");
      this.loading = true;
      // Simulated ajax query
      let { orders } = await api.get("orders/search", { search: this.search });
      this.orders = orders;
      this.loading = false;
    },

    async searchOrders() {
      this.loading = true;
      console.log("Searching orders...");
      let { orders } = await api.get("orders/search", { search: this.search });
      console.log(orders);
      this.loading = false;
      this.menu = true;
      this.orders = orders;
    },

    subscribeToNotifications() {
      let user = JSON.parse(localStorage.getItem("user"));

      db.collection("notifications")
        .doc(user.uid)
        .onSnapshot(doc => {
          if (doc.exists)
            this.notifications = doc.data().notifications.reverse();
        });
    }
  },

  mounted() {}
};
</script>

<style>
[v-cloak] {
  display: none;
}
#app {
  font-family: "Quicksand", sans-serif;
  background-color: #fafafa !important;
}
.note {
  position: absolute;
  width: 250px;
  left: 86%;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
